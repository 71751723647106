import React, { useRef, useEffect } from 'react';
import cx from 'classnames';

import Toggle from '../../scripts/modules/Toggle';
import { useStatic, composeRefs, useUniqueId } from '../../utils/hooks';

import { Button, Icon } from '..';

import DropdownStatic from './Dropdown.static';

/*

  TODO:
    - deprecate renderDropdown
    - investigate why there is no `onChange`
    - cleanup/simplify

*/

type TriggerProps = {
  'data-toggle'?: string;
  'aria-expanded'?: string;
  'aria-controls'?: string;
  'data-dropdown-placement'?: import('popper.js').Placement;
  id: string;
};

type Dropdown = {
  /** Dropdown alignment */
  align?: 'left' | 'right';
  /** Props passed to trigger (`<Button />`) */
  buttonProps?: Button;
  /** Props passed to <Icon /> in trigger element */
  iconProps?: Omit<Icon, 'name'>;
  /** Name passed to <Icon /> in trigger element */
  iconName?: string;
  /** Name passed to <Icon /> in trigger element, when dropdown is open */
  iconNameOpen?: string;
  /** Interactive dropdown does not close on click inside */
  interactive?: boolean;
  /** Popper.js options https://popper.js.org/tooltip-documentation.html */
  popperOptions?: import('popper.js').PopperOptions;
  /** Custom dropdown trigger renderer. Passes props as function parameter. Passed element must be forwarding ref similar to this, otherwise dropdown will not attach to correct element.
   *
   * ```jsx
   * <Dropdown
   *   renderTrigger={
   *     (triggerProps, ref) => (
   *       <div
   *         ref={ref}
   *         className="do-not-create-trigger-from-div"
   *         {...otherTriggerProps}
   *       >
   *         This is a bad idea
   *       </div>
   *     )
   *   }
   * >
   * ```
   */
  renderTrigger?: (
    props: TriggerProps,
    ref: React.RefObject<HTMLElement>
  ) => React.ReactNode;
  /** Custom dropdown renderer. Passes props as function parameter. Passed element must be forwarding ref similar to this, otherwise the JS module will fail to init.
   *
   * ```jsx
   * <Dropdown
   *   renderDropdown={(dropdownProps, ref) => (
   *     <div
   *       ref={ref}
   *       className="do-not-create-trigger-from-div"
   *       {...dropdownProps}
   *     >
   *       This is a bad idea
   *     </div>
   *   )}
   * >
   * ```
   *
   */
  renderDropdown?: (
    props: Dropdown,
    ref: React.RefObject<HTMLElement>
  ) => JSX.Element; // TODO
  /** Custom dropdown id (automatically generated by default) */
  id?: string;
  /** Sets dropdown open state */
  isOpen?: boolean;
  /** On dropdown close callback function */
  onClose?: () => void;
  /** On dropdown open callback function */
  onOpen?: () => void;
} & JSX.IntrinsicElements['div'];

const Dropdown = ({
  align,
  children,
  className,
  buttonProps = {},
  iconProps,
  iconName = 'chevron-down',
  iconNameOpen = 'chevron-up',
  interactive,
  popperOptions = {},
  renderTrigger,
  renderDropdown,
  id,
  isOpen,
  onClose = () => {},
  onOpen = () => {},
  ...other
}: Dropdown) => {
  const [toggleButtonRef1] = useStatic(Toggle);
  const toggleButtonRef2 = useRef<HTMLButtonElement>(null);
  const toggleButtonRef = composeRefs<HTMLButtonElement>(toggleButtonRef1, toggleButtonRef2);

  const [dropdownRef, dropdown] = useStatic(DropdownStatic, {
    anchorElement: toggleButtonRef2.current,
    popperOptions,
    onOpen,
    onClose,
  });

  useEffect(() => {
    if (isOpen) {
      dropdown.current?.open();
    }
  }, [isOpen, dropdown]);

  useEffect(() => {
    if (isOpen) {
      dropdown.current?.open();
    } else {
      dropdown.current?.close();
    }
  }, [isOpen, dropdown]);

  const classes = cx({
    [`dropdown`]: true,
    [`dropdown--${align}`]: align,
    [`${className}`]: className,
  });

  const currentId = useUniqueId(id, 'dropdown');

  const dataToggle = [
    {
      target: `#${currentId}`,
      attribute: 'class',
      value: 'is-active',
    },
    {
      target: 'self',
      attribute: 'aria-expanded',
    },
  ];

  const { children: buttonChildren, ...otherButtonProps } = buttonProps;

  const triggerToRender = (renderTrigger &&
    renderTrigger(
      {
        'data-toggle': JSON.stringify(dataToggle),
        'aria-expanded': 'false',
        'aria-controls': currentId,
        id: `${currentId}-trigger`,
        ...(popperOptions.placement
          ? { 'data-dropdown-placement': popperOptions.placement }
          : {}),
      },
      toggleButtonRef2
    )) || (
    <Button
      elemRef={toggleButtonRef}
      id={`${currentId}-trigger`}
      className="btn-dropdown"
      data-toggle={JSON.stringify([
        ...dataToggle,
        {
          target: 'self',
          attribute: 'icon',
          value: `/sprite.svg#${iconNameOpen}`,
        },
      ])}
      aria-expanded="false"
      aria-haspopup="true"
      aria-controls={currentId}
      equal={!buttonChildren}
      {...(popperOptions.placement
        ? { 'data-dropdown-placement': popperOptions.placement }
        : {})}
      {...otherButtonProps}
    >
      {buttonChildren}
      <Icon
        size="medium"
        name={iconName as Icon['name']}
        className={cx({ 'icon--right': buttonChildren })}
        {...iconProps}
      />
    </Button>
  );

  const dropdownToRender = renderDropdown?.(
    {
      id,
      // https://github.com/microsoft/TypeScript/issues/28960
      // @ts-ignore
      'data-dropdown-interactive': interactive,
      'data-dropdown': '',
    },
    dropdownRef
  ) ?? (
    <div
      id={currentId}
      role="menu"
      data-dropdown
      className={classes}
      ref={dropdownRef}
      data-dropdown-interactive={interactive}
      aria-labelledby={`${currentId}-trigger`}
      {...(isOpen ? { 'data-dropdown-open-on-init': true } : {})}
      {...other}
    >
      {children}
    </div>
  );

  return (
    <>
      {triggerToRender}
      {dropdownToRender}
    </>
  );
};

export default Dropdown;
