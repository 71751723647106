import React from 'react';

import Input from '../Input/Input';

import TextareaStatic from './Textarea.static';
import { useStatic } from '../../../utils/hooks';

type Textarea = {
  autosize?: boolean;
} & Omit<Input, 'ref' | 'multiline' | 'onChange'> &
  Omit<JSX.IntrinsicElements['textarea'], 'ref'>;

const Textarea = ({ autosize = true, ...other }: Textarea) => {
  const [textareaRef] = useStatic(TextareaStatic);

  return (
    // @ts-ignore
    <Input
      elemRef={textareaRef}
      multiline
      {...(autosize && { 'data-textarea-autosize': '' })}
      {...other}
    />
  );
};

export default Textarea;
