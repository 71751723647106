import React from 'react';
import cx from 'classnames';

import NavigationItem from './NavigationItem';

/*

  TODO:
    - make `NavigationList` polymorphic via `as` or `tag` prop

*/

type NavigationList = {
  /** Navigation items */
  items: NavigationItem[];
  /** Html tag in which is component rendered. */
  tag?: string;
  /** Component visual style. */
  type?: 'inverted';
} & JSX.IntrinsicElements['ul'];

const NavigationList = ({
  children,
  className,
  items,
  type,
  tag = 'ul',
  ...other
}: NavigationList) => {
  const classes = cx({
    [`navigation__list`]: true,
    [`navigation__list--${type}`]: type,
    [`${className}`]: className,
  });

  const Tag = tag;

  return (
    // @ts-ignore
    <Tag className={classes} {...other}>
      {items.map((navigationItemProps, i) => (
        <NavigationItem
          key={navigationItemProps.id ?? i}
          {...navigationItemProps}
        />
      ))}
    </Tag>
  );
};

export default NavigationList;
