import React from 'react';

import { Bar, BarItem, Icon, Button } from '..';

import { pagination } from './utils';

/*

  TODO:
    – investigate why `Bar` does not have `display: flex`

*/

type Pagination = {
  /** The currently selected page */
  currentPage: number;
  /** The total number of pages available */
  totalPages: number;
  /** Callback for the change of the page */
  onChange?: (currentPage: number) => void;
  /** Whether to display page numbers */
  hidePageNumbers?: boolean;
  /** Previous label text */
  previousLabel?: string;
  /** Next label text */
  nextLabel?: string;
};

const Pagination = ({
  currentPage = 1,
  totalPages = 1,
  onChange,
  hidePageNumbers = false,
  previousLabel = 'Predchádzajúce',
  nextLabel = `Nasledujúce`,
}: Pagination) => {
  if (totalPages <= 1) {
    return null;
  }

  if (currentPage < 1 || currentPage > totalPages) {
    console.warn(
      `"Pagination: currentPage (${currentPage}) is out of bounds [${1}, ${totalPages}]`
    );
    return null;
  }

  const pages = pagination(currentPage, totalPages);

  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < totalPages;

  const Filler = () => (
    <Button disabled size="xs" type="link-secondary">
      …
    </Button>
  );

  return (
    <Bar align="middle" space="xxsmall">
      <BarItem>
        <Button
          disabled={!hasPrevious}
          type="link-secondary"
          className="pagination__previous"
          onClick={hasPrevious ? () => onChange?.(currentPage - 1) : undefined}
        >
          <Icon name="arrow-left" className="icon--left" />
          <span className="pagination__previousLabel show-m">
            &nbsp;{previousLabel}
          </span>
        </Button>
      </BarItem>
      <BarItem isFilling>
        {hidePageNumbers ? null : (
          <Bar
            space="xxsmall"
            direction="horizontal"
            className="align-items-center"
          >
            {pages.map((page, i) => (
              <BarItem className="d-flex align-items-center" key={i}>
                {page === '...' ? (
                  <Filler />
                ) : (
                  <Button
                    size="xs"
                    type={page === currentPage ? undefined : 'link-secondary'}
                    onClick={() => onChange?.(page)}
                  >
                    {page}
                  </Button>
                )}
              </BarItem>
            ))}
          </Bar>
        )}
      </BarItem>
      <BarItem>
        <Button
          disabled={!hasNext}
          type="link-secondary"
          className="pagination__previous"
          onClick={hasNext ? () => onChange?.(currentPage + 1) : undefined}
        >
          <span className="pagination__nextLabel show-m">
            {nextLabel}&nbsp;
          </span>
          <Icon name="arrow-right" className="icon--right" />
        </Button>
      </BarItem>
    </Bar>
  );
};

export default Pagination;
