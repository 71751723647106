import React from 'react';
import cx from 'classnames';

type Quote = JSX.IntrinsicElements['blockquote'];

const Quote = ({ children, className, ...other }: Quote) => {
  const classes = cx({ [`quote`]: true, [`${className}`]: className });

  return (
    <blockquote className={classes} {...other}>
      {children}
    </blockquote>
  );
};

export default Quote;
