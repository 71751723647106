import React from 'react';
import {
  node,
  bool,
  string,
  array,
  arrayOf,
  object,
  shape,
  oneOf,
  oneOfType,
  func,
} from 'prop-types';
import cx from 'classnames';

import Label from '../Label';
import Message from '../Message';
import useId from '../../../utils/useId';

import SelectStatic from './Select.static';

const CLASS_ROOT = 'select';

const CHOICES_EVENTS = [
  'addItem',
  'removeItem',
  'highlightItem',
  'unhighlightItem',
  'choice',
  'change',
  'search',
  'showDropdown',
  'hideDropdown',
];

export default class Select extends React.Component {
  static displayName = 'Select';

  static propTypes = {
    /** Dropdown alignment. */
    align: oneOf(['right']),
    /** Choices options. (https://github.com/jshjohnson/Choices#configuration-options) */
    choicesOptions: object,
    /** If false, select element will be left as `<select />` native element without styles or advanced functionalities. Usefull if you want to initialize select manually. */
    enhanceSelect: bool,
    /** Error state. If value is string, it's treated as error message. */
    error: oneOfType([bool, node]),
    /** Help text. */
    help: string,
    /** Html id attribute. */
    id: string.isRequired,
    /** Select can be cleared to default value. */
    isClearable: bool,
    /** Disabled state. */
    isDisabled: bool,
    /** Required state */
    isRequired: bool,
    /** Label text. */
    label: string,
    /** Html name attribute. */
    name: string,
    /** Value change callback function. */
    onChange: func,
    /** Initialization callback function. */
    onInit: func,
    /** Select option can be specified via children or as options array */
    options: arrayOf(
      oneOfType([shape({ text: string, value: string }), string])
    ),
    /** Placeholder text */
    placeholder: string,
    /** Custom error renderer. Passes select props as function parameter. */
    renderError: func,
    /** Custom help renderer. Passes select props as function parameter. */
    renderHelp: func,
    /** Custom label renderer. Passes select props as function parameter. */
    renderLabel: func,
    /** Custom warning renderer. Passes props as function parameter. */
    renderWarning: func,
    /** Choices type. */
    selectionType: oneOf(['text', 'one', 'multiple']),
    /** Size of element. */
    size: oneOf(['small', 'large']),
    /** Select type */
    type: oneOf(['inline']),
    /** Select value. */
    value: oneOfType([string, array]),
    /** Warning state. If value is string, it's treated as warning message. */
    warning: oneOfType([node, bool]),
  };

  static defaultProps = {
    enhanceSelect: true,
    onChange: (newValue, e) => {}, // eslint-disable-line
    onInit: (instance, el) => {}, // eslint-disable-line
    selectionType: 'one',
  };

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    const { choicesOptions, value, selectionType: type } = this.props;

    this.select = new SelectStatic(this.selectRef.current, choicesOptions);

    if (value) {
      if (type !== 'text') {
        if (this.select.instance.getValue(true) !== value) {
          this.select.instance.setChoiceByValue(value);
        }
      } else {
        this.select.instance.setValue(value);
      }
    }

    CHOICES_EVENTS.forEach(eventName =>
      this.selectRef.current.addEventListener(eventName, this.handleEvent)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.size !== this.props.size ||
      prevProps.warning !== this.props.warning ||
      prevProps.renderWarning !== this.props.renderWarning ||
      prevProps.error !== this.props.error ||
      prevProps.renderError !== this.props.renderError ||
      prevProps.type !== this.props.type
    ) {
      this.select.setClasses(this.selectRef.current.classList);
    }

    if (prevProps.options !== this.props.options) {
      this.select.instance.setChoices(
        this.props.options,
        'value',
        'label',
        true
      );
    }

    if (prevProps.value !== this.props.value) {
      this.select.instance.setChoiceByValue(this.props.value);
    }

    if (this.props.isDisabled) {
      this.select.instance.disable();
    } else {
      this.select.instance.enable();
    }
  }

  componentWillUnmount() {
    CHOICES_EVENTS.forEach(eventName =>
      this.selectRef.current.removeEventListener(eventName, this.handleEvent)
    );

    this.select.destroy();
  }

  handleEvent = e => {
    const eventName = e.type.charAt(0).toUpperCase() + e.type.slice(1);
    const eventHandler = this.props[`on${eventName}`];

    if (eventHandler) {
      eventHandler(e.detail, e);
    }
  };

  render() {
    const {
      className,
      children,
      enhanceSelect,
      error,
      renderError,
      warning,
      renderWarning,
      label,
      renderLabel,
      help,
      renderHelp,
      options,
      placeholder,
      size,
      selectionType,
      id,
      isDisabled,
      type,
      isClearable,
      onInit,
      onChange,
      value,
      align,
      isRequired,
      ...other
    } = this.props;

    const classes = cx(
      CLASS_ROOT,
      {
        [`${CLASS_ROOT}--${size}`]: size,
        'is-warning': warning || renderWarning,
        'is-error': error || renderError,
        [`${CLASS_ROOT}--inline`]: type === 'inline',
      },
      'is-hidden',
      className
    );

    // set name from id if name is not specified
    let { name } = other;
    name = name && id;

    const formControlClasses = cx(
      'form-control',
      'form-control--select',
      {
        'form-control--inline': type === 'inline',
      },
      {
        'is-warning': warning || renderWarning,
      },
      {
        'is-error': error || renderError,
      }
    );

    const selectLabel =
      (renderLabel && renderLabel(this.props)) ||
      (label && (
        <Label htmlFor={id} isDisabled={isDisabled} isRequired={isRequired}>
          {label}
        </Label>
      ));

    const selectOptions =
      options &&
      options.map(option => {
        const { value: optionValue, text, ...otherOptionProps } =
          typeof option === 'string' ? { value: option, text: option } : option;
        return (
          <option value={optionValue} key={text} {...otherOptionProps}>
            {text}
          </option>
        );
      });

    const helpMessage =
      (renderHelp && renderHelp(this.props)) ||
      (help && <Message type="help">{help}</Message>);

    const warningMessage =
      (renderWarning && renderWarning(this.props)) ||
      (warning && typeof warning === 'string' && (
        <Message type="warning">{warning}</Message>
      ));

    const errorMessage =
      (renderError && renderError(this.props)) ||
      (error && typeof error === 'string' && (
        <Message type="error">{error}</Message>
      ));

    const placeholderId = useId('placeholder-option');

    return (
      <div className={formControlClasses}>
        {selectLabel}
        {helpMessage}
        {warningMessage}
        {errorMessage}
        {type === 'inline' && ' '}
        {/* This div needs to be here because React is showing error: `Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.` on `error` prop change */}
        <div>
          <select
            multiple={selectionType === 'select-multiple'}
            id={id}
            name={name}
            className={classes}
            disabled={isDisabled}
            ref={this.selectRef}
            {...(enhanceSelect ? { 'data-select': '' } : {})}
            data-select-clearable={isClearable}
            {...align && { 'data-select-align': align }}
            {...other}
            defaultValue={placeholder}
          >
            {selectOptions}
            {children}
            {placeholder && (
              <option id={placeholderId} disabled placeholder="">
                {placeholder}
              </option>
            )}
          </select>
        </div>
      </div>
    );
  }
}
