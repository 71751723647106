export default class HeaderMobile {
  element: HTMLElement;
  headerMobileTopbar: HTMLElement | null;
  searchBarTrigger: HTMLElement | null;
  headerMobileNav: HTMLElement | null;
  dropdownAction: HTMLElement | null;
  headerHamburger: HTMLElement | null;
  toggleMenuDropdown: boolean;
  bodyScrollPos: number;

  constructor(element: HTMLElement) {
    this.element = element;

    this.headerMobileTopbar = this.element.querySelector(
      '.header-mobile__topbar'
    );
    this.searchBarTrigger = this.element.querySelector(
      '.header-mobile__search-icon'
    );
    this.headerMobileNav = this.element.querySelector('.header-mobile__nav');
    this.dropdownAction = this.element.querySelector(
      '.header-mobile__dropdown-action'
    );
    this.headerHamburger = this.element.querySelector(
      '.header-mobile__hamburger'
    );
    this.toggleMenuDropdown = false;
    this.bodyScrollPos = 0;

    this.toggleSearchBarVisibility = this.toggleSearchBarVisibility.bind(this);
    this.calculateNavHeight = this.calculateNavHeight.bind(this);
    this.toggleBodyScroll = this.toggleBodyScroll.bind(this);
    this.enableBodyScroll = this.enableBodyScroll.bind(this);
    this.disableBodyScroll = this.disableBodyScroll.bind(this);

    this.init();

    // @ts-ignore
    this.element.DDL_HeaderMobile = this;
  }

  init() {
    if (this.element) {
      if (this.headerHamburger) {
        this.headerHamburger.addEventListener('click', this.toggleBodyScroll);
      }

      if (this.headerMobileNav) {
        this.calculateNavHeight();
        window.addEventListener('resize', this.calculateNavHeight);
        window.addEventListener('orientationchange', this.calculateNavHeight);
      }
    }

    if (this.searchBarTrigger) {
      this.searchBarTrigger.addEventListener(
        'click',
        this.toggleSearchBarVisibility
      );
    }
  }
  static getInstance(el: HTMLElement) {
    // @ts-ignore
    return el && el.DDL_HeaderMobile ? el.DDL_HeaderMobile : null;
  }

  toggleSearchBarVisibility() {
    // eslint-disable-next-line
    Array.from(this.headerMobileTopbar?.children ?? []).map(item => {
      if (item.contains(this.searchBarTrigger)) {
        item.classList.toggle('bar__item--fill');
        item.classList.toggle('no-mrg-right');
      } else {
        item.classList.toggle('hide');
      }
    });
  }

  toggleBodyScroll() {
    if (!this.toggleMenuDropdown) {
      this.disableBodyScroll();
    } else {
      this.enableBodyScroll();
    }
  }

  disableBodyScroll = () => {
    this.bodyScrollPos = document.body.scrollTop;
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    document.documentElement.style.overflowY = 'scroll';
    this.toggleMenuDropdown = !this.toggleMenuDropdown;
  };

  enableBodyScroll = () => {
    document.body.style.overflow = 'auto';
    document.body.style.height = 'unset';
    document.documentElement.style.overflowY = 'unset';
    document.body.scrollTop = this.bodyScrollPos;
    this.toggleMenuDropdown = !this.toggleMenuDropdown;
  };

  calculateNavHeight() {
    const menuHeight = this.headerMobileTopbar?.parentElement?.offsetHeight ?? 0;
    const dropdownActionHeight = this.dropdownAction
      ? this.dropdownAction.offsetHeight
      : 0;
    const documentHeight =
      window.innerHeight - menuHeight - dropdownActionHeight;

    this.headerMobileNav!.style.height = `${documentHeight + 1}px`;
  }

  destroy() {
    if (this.element) {
      if (this.headerMobileNav) {
        window.removeEventListener('resize', this.calculateNavHeight);
        window.removeEventListener(
          'orientationchange',
          this.calculateNavHeight
        );
      }

      if (this.headerHamburger) {
        this.headerHamburger.removeEventListener(
          'click',
          this.toggleBodyScroll
        );
      }
    }

    if (this.searchBarTrigger) {
      this.searchBarTrigger.removeEventListener(
        'click',
        this.toggleSearchBarVisibility
      );
    }
  }

  update() {
    this.destroy();
    this.init();
  }
}
