import React, { forwardRef, useContext } from 'react';

import Icon from './Icon';

import './styles/style.scss';

const IconSpritePathContext = React.createContext({
  spritePath: '/sprite.svg',
});

export const IconSpritePathProvider = IconSpritePathContext.Provider;
export const IconSpritePathConsumer = IconSpritePathContext.Consumer;

type IconWithContext = Omit<Icon, 'spritePath' | 'ref'>;

const IconWithContext = forwardRef<SVGSVGElement, IconWithContext>(
  (props, ref) => {
    const { spritePath } = useContext(IconSpritePathContext);
    return <Icon {...props} spritePath={spritePath} ref={ref} />;
  }
);

export default IconWithContext;
