import React, { useContext } from 'react';
import cx from 'classnames';

import { StrictResponsiveMap } from '../../utils';
import { Grid, GridCol } from '..';

import ArticleContext from './ArticleContext';

type ArticleItem = {
  /** Specify how wide article item is. */
  width?: 'full' | 'wide';
} & JSX.IntrinsicElements['div'];

const getColSpace = (aside: React.ReactNode, width: ArticleItem['width']) => {
  const size = {
    m: 12,
    l: aside ? 9 : 8,
  } as StrictResponsiveMap<number>;

  if (width === 'full') {
    size.l = 12;
  }

  if (width === 'wide') {
    size.l = 10;
  }

  return size;
};

const getColOffset = (width: ArticleItem['width']) => {
  const offset = {} as StrictResponsiveMap<number>;

  if (width !== 'full') {
    offset.l = 1;
  }

  return offset;
};

const ArticleItem = ({ children, className, width, ...other }: ArticleItem) => {
  const classes = cx({
    [`article__item`]: true,
    [`article__item--${width}`]: width,
    [`${className}`]: className,
  });

  const { aside } = useContext(ArticleContext);

  return (
    <div className={classes} {...other}>
      <Grid>
        <GridCol size={getColSpace(aside, width)} offset={getColOffset(width)}>
          {children}
        </GridCol>
      </Grid>
    </div>
  );
};

export default ArticleItem;
