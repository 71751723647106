import React from 'react';
import { node } from 'prop-types';
import cx from 'classnames';

import Toggle from '../../scripts/modules/Toggle';

import HeaderMobileStatic from './HeaderMobile.static';

import { Button, Icon, Bar, BarItem } from '..';

const CLASS_ROOT = 'header-mobile';

export default class MobileHeader extends React.Component {
  static displayName = 'HeaderMobile';

  static propTypes = {
    /** Action area section. */
    actionArea: node,
    /** Logo area section. */
    logoArea: node,
    /** Menu area section. */
    menuArea: node,
    /** Search area section. */
    searchArea: node,
    /** Title area section. */
    titleArea: node,
    /** User area section. */
    userArea: node,
  };

  constructor(props) {
    super(props);
    this.hamburgerRef = React.createRef();
    this.headerRef = React.createRef();
  }

  componentDidMount() {
    if (this.hamburgerRef.current) {
      this.hamburgerInstance = new Toggle(this.hamburgerRef.current);
    }

    this.headerMobile = new HeaderMobileStatic(this.headerRef.current);
  }

  componentWillUnmount() {
    this.headerMobile.destroy();
  }

  render() {
    const {
      children,
      className,
      logoArea,
      menuArea,
      searchArea,
      titleArea,
      userArea,
      actionArea,
      ...other
    } = this.props;
    const classes = cx(CLASS_ROOT, className);

    const hamburger = (
      <BarItem>
        <Button
          type="link-secondary"
          elemRef={this.hamburgerRef}
          data-toggle={JSON.stringify([
            {
              target: `.${CLASS_ROOT}__wrapper`,
              attribute: 'class',
              value: 'is-active',
            },
            {
              target: `.${CLASS_ROOT}__wrapper`,
              attribute: 'aria-hidden',
            },
            {
              target: `.${CLASS_ROOT}__menu`,
              attribute: 'class',
              value: 'is-active',
            },
            {
              target: '#header-mobile',
              attribute: 'class',
              value: 'is-active',
            },
          ])}
          size="xs"
          className={`${CLASS_ROOT}__hamburger no-pad`}
        >
          <Icon id="icon" size="medium" name="menu-right" />
        </Button>
      </BarItem>
    );

    const hasMenu = menuArea || actionArea;

    return (
      <div
        ref={this.headerRef}
        data-header-mobile
        className={classes}
        {...other}
      >
        <Bar className="no-mrg-bottom" direction="vertical">
          <BarItem className="no-mrg-bottom">
            <Bar space="small" className={`${CLASS_ROOT}__topbar`}>
              {logoArea && <BarItem>{logoArea}</BarItem>}
              <BarItem isFilling>
                {typeof titleArea === 'string' ? (
                  <span className="text-font-primary text-color-grey">
                    {titleArea}
                  </span>
                ) : (
                  titleArea
                )}
              </BarItem>
              {searchArea && <BarItem>{searchArea}</BarItem>}
              {userArea && <BarItem>{userArea}</BarItem>}
              {hasMenu && hamburger}
            </Bar>
          </BarItem>
          {hasMenu && (
            <BarItem
              className={`${CLASS_ROOT}__wrapper`}
              aria-hidden="true"
              tag="nav"
            >
              <Bar direction="vertical" tag="ul" className="list--unstyled">
                {menuArea && (
                  <BarItem
                    className="no-mrg-bottom header-mobile__nav"
                    tag="li"
                  >
                    {menuArea}
                  </BarItem>
                )}
                {actionArea && (
                  <BarItem className={`${CLASS_ROOT}__dropdown-action`}>
                    {actionArea}
                  </BarItem>
                )}
              </Bar>
            </BarItem>
          )}
        </Bar>
      </div>
    );
  }
}
