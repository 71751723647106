import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import cx from 'classnames';

import { convertSizeToWord } from '../../../utils';
import { useStatic, useUniqueId, composeRefs } from '../../../utils/hooks';
import Label from '../Label';
import Message from '../Message';

import Addons from './Addons/';
import InputStatic from './Input.static';

/*

    TODO:
      * remove `useImperativeHandle` – breaking change
      * remove `elemRef` and use normal ref-forwarding – breaking change
      * inspect why tag can be 'select'

*/

type Input = {
  /** Sets proper styles for addons rendered inside component */
  addonsInside?: boolean;
  /** Forwarded DOM element ref */
  elemRef?: React.Ref<HTMLInputElement>;
  /** Error state – if value is string, it's treated as error message */
  error?: React.ReactNode | boolean;
  /** Help text */
  help?: React.ReactNode;
  /** HTML type attribute */
  inputType?: string;
  /** Active state */
  isActive?: boolean;
  /** Disabled state */
  isDisabled?: boolean;
  /** Redonly state */
  isReadonly?: boolean;
  /** Required state */
  isRequired?: boolean;
  /** Success state */
  isSuccess?: boolean;
  /** Label text */
  label?: React.ReactNode;
  /** Addon on left side of input */
  leftAddons?: React.ReactNode;
  /** Multiline input */
  multiline?: boolean;
  /** HTML name attribute */
  name?: string;
  /** Placeholder text */
  placeholder?: string;
  /** Custom error renderer – passes props as function parameter */
  renderError?: (props: Input) => JSX.Element;
  /** Custom help renderer – passes props as function parameter */
  renderHelp?: (props: Input) => JSX.Element;
  /** Custom label renderer – passes props as function parameter */
  renderLabel?: (props: Input) => JSX.Element;
  /** Custom warning renderer – asses props as function parameter */
  renderWarning?: (props: Input) => JSX.Element;
  /** If input has addon inside, input will wrap it underneath */
  responsive?: boolean;
  /** Addon on right side of input */
  rightAddons?: React.ReactNode;
  /** Size of element */
  size?: 's' | 'l';
  /** Use this HTML tag */
  tag?: 'input' | 'textarea' | 'select';
  /** Changes styles of input, primary colors */
  type?: 'white' | 'transparent';
  /** Warning state – if vaule is string, it's treated as warning message */
  warning?: React.ReactNode | boolean;
} & Omit<JSX.IntrinsicElements['input'], 'type'>;

const DefaultLabel = ({
  id,
  label,
  isDisabled,
  isReadonly,
  isRequired,
}: Input) => (
  <Label
    htmlFor={id}
    isDisabled={isDisabled}
    isReadonly={isReadonly}
    isRequired={isRequired}
  >
    {label}
  </Label>
);
const DefaultHelp = ({ help, id }: Input) => (
  <Message type="help" id={`${id}__help`}>
    {help}
  </Message>
);
const DefaultWarning = ({ warning }: Input) => (
  <Message type="warning">{warning}</Message>
);
const DefaultError = ({ error }: Input) => (
  <Message type="error">{error}</Message>
);

const Input = forwardRef((providedProps: Input, ref: any) => {
  const {
    addonsInside,
    elemRef,
    type,
    responsive,
    className,
    error,
    help,
    id: providedId,
    name: providedName,
    isActive,
    isDisabled,
    isReadonly,
    isSuccess,
    isRequired,
    label,
    leftAddons,
    multiline,
    placeholder,
    renderError,
    renderHelp,
    renderLabel,
    renderWarning,
    rightAddons,
    size,
    inputType,
    tag,
    warning,
    ...other
  } = providedProps;

  const id = useUniqueId(providedId);
  const name = providedName ?? id;

  const props = {
    ...providedProps,
    id,
    name,
  };

  const [staticRef] = useStatic(InputStatic);

  // Datepicker (and possibly 3rd party code) is accessing
  // `inputRef` property on the `Input` ref.
  // Keeping this for backward compatibility.
  const exposedRef = useRef();
  useImperativeHandle(ref, () => ({
    inputRef: exposedRef,
  }));

  const inputRef = composeRefs(staticRef, elemRef, exposedRef);

  let Element: Input['tag'] = multiline ? 'textarea' : 'input';

  if (tag) {
    Element = tag;
  }

  const classes = cx({
    [`input`]: true,
    [`is-active`]: isActive,
    [`is-success`]: isSuccess,
    [`is-error`]: error,
    [`is-warning`]: warning,
    [`input--${convertSizeToWord(size)}`]: size,
    [`input--${type}`]: type,
    [`${className}`]: className,
  });

  const formControlClasses = cx({
    [`form-control`]: true,
    [`form-control--${Element.toLowerCase()}`]: true,
    [`is-error`]: error || renderError,
    [`is-warning`]: warning,
  });

  const Label = renderLabel ?? DefaultLabel;
  const Help = renderHelp ?? DefaultHelp;
  const Warning = renderWarning ?? DefaultWarning;
  const Error = renderError ?? DefaultError;

  const inputElement = (
    <Element
      // @ts-ignore
      ref={inputRef}
      className={classes}
      id={id}
      name={name}
      type={inputType}
      disabled={isDisabled}
      readOnly={isReadonly}
      placeholder={placeholder}
      aria-required={isRequired}
      {...(help && { 'aria-describedby': `${id}__help` })}
      {...other}
    />
  );

  const inputWrapper = (
    <div className="form-control__input-wrapper">
      {inputElement}
      {(isSuccess || warning || error) && <span className="input__icon" />}
    </div>
  );

  return (
    <div className={formControlClasses}>
      {label && <Label {...props} />}
      {help && <Help {...props} />}
      {warning && <Warning {...props} />}
      {error && <Error {...props} />}
      <Addons
        left={leftAddons}
        right={rightAddons}
        size={size}
        addonsInside={addonsInside}
        responsive={responsive}
        type={type}
      >
        {inputWrapper}
      </Addons>
    </div>
  );
});

export default Input;
