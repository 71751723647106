import React from 'react';
import cx from 'classnames';

import { genResponsiveClasses } from '../../utils';
import { ResponsiveMap } from '../../utils';

/*

  TODO:
    - make `GridCol` polymorphic for `tag` or `as` prop

*/

type Size = number | 'shrink' | 'fill' | 'auto';
type Offset = number | 'center';
type GridCol = {
  /** Attempt to make child same height as column height */
  equalizeHeight?: boolean;
  /** HTML tag */
  tag?: string;
  /** Column offset. Number, 'center', or responsive map */
  offset?: ResponsiveMap<Offset>;
  /** Column size. Number, 'shrink/fill/auto', or responsive map */
  size?: ResponsiveMap<Size>;
} & JSX.IntrinsicElements['div'];

const GridCol = ({
  className,
  equalizeHeight,
  tag = 'div',
  size,
  offset,
  ...other
}: GridCol) => {
  const classes = cx({
    [`grid__col`]: true,
    ...genResponsiveClasses('grid__col', size),
    ...genResponsiveClasses('grid__col', offset, 'offset'),
    [`grid__col--equalized`]: equalizeHeight,
    [`${className}`]: className,
  });

  const Tag = tag;

  //@ts-ignore
  return <Tag className={classes} {...other} />;
};

export default GridCol;
