import { ResponsiveMap, StrictResponsiveMap, ResponsiveMapValue } from '.';
import { Breakpoint } from './tokens/breakpoints';

type ClassMap = { [className: string]: boolean };

/**
 * Prepend `prefix` to the `className`
 *
 * Empty `className` is not prefixed.
 *
 * @example prefixClass("A") === "-A"
 * @example prefixClass("A", "p-") === "p-A"
 * @example prefixClass("", "p-") === ""
 */
const prefixClass = (className?: string, prefix = '-') =>
  className ? `${prefix}${className}` : '';

/**
 * Prefixes `breakpoint`
 *
 * Breakpoint `xs` is special case.
 *
 * @example prefixBreakpoint('xs') === '-'
 * @example prefixBreakpoint('s') === '--s'
 * @example prefixBreakpoint('xl') === '--xl'
 * */
const prefixBreakpoint = (breakpoint: Breakpoint) =>
  breakpoint === 'xs' ? '-' : `--${breakpoint}`;

/**
 * Prefixes value
 *
 * @example prefixValue(false) === ''
 * @example prefixValue(true) === ''
 * @example prefixValue('') === ''
 * @example prefixValue(16) === '-16'
 * @example prefixValue('s') === '-s'
 */
const prefixValue = (value: ResponsiveMapValue) =>
  prefixClass(typeof value === 'boolean' ? '' : `${value}`);

/**
 * Generate responsive classes based on media object.
 *
 * @param baseClass Base classname to use for other derivates.
 * @param mediabject Media object with values for each breakpoint.
 * @param sufix Sufix that is appended at the end of the generated classname.
 *
 * @example getClassModsFromObject('A', { xs: true }) === {'A-': true}
 * @example getClassModsFromObject('A', { xs: true }, 'b') === {'A--b': true}
 * @example getClassModsFromObject('A', { xs: false }, 'b') === {'A--b': false}
 * @example getClassModsFromObject('A', { xs: 'b' }) === {'A--b': true}
 * @example getClassModsFromObject('A', { l: 'b' }) === {'A--l-b': true}
 * @example getClassModsFromObject('A', { l: 'b' }, 'c') === {'A--l-b-c': true}
 * @example getClassModsFromObject('A', { xs: 'h', s: 'v' }) === {'A--h': true, 'A--s-v': true}
 */
export const getClassModsFromObject = (
  baseClass: string,
  mediabject: StrictResponsiveMap<ResponsiveMapValue>,
  sufix?: string
): ClassMap =>
  Object.fromEntries(
    (Object.entries(mediabject) as [
      Breakpoint,
      ResponsiveMapValue
    ][]).map(([breakpoint, value]) => [
      [
        baseClass,
        prefixBreakpoint(breakpoint),
        prefixValue(value),
        prefixClass(sufix),
      ].join(''),
      value !== false,
    ])
  );

/**
 * Generate classname map for `baseClass`
 */
export function genResponsiveClasses(
  baseClass: string,
  mod?: ResponsiveMap<ResponsiveMapValue>,
  sufix?: string
) {
  if (typeof mod === 'undefined') {
    return {};
  }

  if (typeof mod === 'boolean') {
    return {
      [`${baseClass}${prefixClass(sufix, '--')}`]: mod !== false,
    };
  }

  if (typeof mod === 'object') {
    return getClassModsFromObject(baseClass, mod, sufix);
  }

  return {
    [`${baseClass}--${mod}${prefixClass(sufix)}`]: true,
  };
}
