type Size = 'xs' | 's' | 'm' | 'l' | 'xl';

export function convertSizeToWord(size?: Size) {
  switch (size) {
    case 'xs':
      return 'xsmall';
    case 's':
      return 'small';
    case 'm':
      return 'medium';
    case 'l':
      return 'large';
    case 'xl':
      return 'xlarge';
    default:
      return null;
  }
}

export function isObject(val: any) {
  return val != null && typeof val === 'object' && Array.isArray(val) === false;
}
