import React from 'react';
import { bool, string, func, node, oneOf } from 'prop-types';
import cx from 'classnames';

import { Dropdown, HeaderUser } from '..';

const CLASS_ROOT = 'header__user';
class HeaderUserMenu extends React.Component {
  static displayName = 'HeaderUserMenu';

  static propTypes = {
    /** Avatar source link. */
    avatarSrc: string,
    /** User dropdown items. Usually consist of `<DropdownItems />`.  */
    dropdownItems: node.isRequired,
    /** Indicate notification for user */
    hasNotification: bool,
    /** Indicate notification in menu  */
    hasNotificationInMenu: bool,
    /** User name initials. */
    initials: string,
    /** User name. */
    name: string,
    /** Custom render function for Dropdown trigger. */
    renderTriggerContent: func,
    /** Size of Disc with name initials. */
    size: oneOf(['small']),
  };

  state = {
    // isOpen prop is validated in Dropdown
    // eslint-disable-next-line react/prop-types
    isMenuNotificationVisible: !this.props.isOpen,
  };

  handleDropdownOpen = (...args) => {
    this.setState({ isMenuNotificationVisible: false });

    // onOpen prop is validated in Dropdown
    // eslint-disable-next-line react/prop-types
    if (this.props.onOpen) {
      this.props.onOpen(...args);
    }
  };

  handleDropdownClose = (...args) => {
    this.setState({ isMenuNotificationVisible: true });

    // onClose prop is validated in Dropdown
    // eslint-disable-next-line react/prop-types
    if (this.props.onClose) {
      this.props.onClose(...args);
    }
  };

  render() {
    const {
      children,
      className,
      name,
      renderTriggerContent,
      dropdownItems,
      initials,
      avatarSrc,
      size,
      hasNotification,
      hasNotificationInMenu,
      ...other
    } = this.props;

    const classes = cx(CLASS_ROOT, className);

    const triggerContentToRender = (renderTriggerContent &&
      renderTriggerContent(this.props)) || (
      <HeaderUser
        name={name}
        initials={initials}
        avatarSrc={avatarSrc}
        hasNotification={hasNotification}
      />
    );

    const buttonProps = {
      type: 'link',
      size: 'xs',
      children: triggerContentToRender,
      className: cx(
        `${CLASS_ROOT}-menu`,
        {
          [`${CLASS_ROOT}-menu--notification`]:
            this.state.isMenuNotificationVisible && hasNotificationInMenu,
        },
        'no-pad-horizontal'
      ),
    };

    return (
      <Dropdown
        className={classes}
        iconProps={{ size: 'small' }}
        popperOptions={{ placement: 'bottom-end' }}
        buttonProps={buttonProps}
        style={
          // eslint-disable-line
          {
            zIndex: '201',
            minWidth: '200px',
          }
        }
        {...other}
        onOpen={this.handleDropdownOpen}
        onClose={this.handleDropdownClose}
      >
        {dropdownItems}
      </Dropdown>
    );
  }
}

export default HeaderUserMenu;
