import { isObject } from '../helpers';

export const spaces = {
  xxxsmall: 4,
  xxsmall: 8,
  xsmall: 12,
  small: 16,
  default: 24,
  large: 32,
  xlarge: 48,
  xxlarge: 64,
  xxxlarge: 104,
} as const;

export function space(size: keyof typeof spaces = 'default', spaceMap = spaces) {
  if (!isObject(spaceMap)) {
    throw new Error(`spaceMap ${JSON.stringify(spaceMap)} is not an object`);
  }

  return spaceMap[size];
}
