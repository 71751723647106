import React from 'react';

import Button from './Button';
import ButtonLayouts from './ButtonLayouts';

import './styles/style.scss';

export default Button;

export const ButtonLayout = (props: Omit<ButtonLayouts, 'type'>) => (
  <ButtonLayouts type="layout" {...props} />
);

export const ButtonGroup = (props: Omit<ButtonLayouts, 'type'>) => (
  <ButtonLayouts type="group" {...props} />
);

export const ButtonPrepoistit = (props: Omit<Button, 'type'>) => (
  <Button type="prepoistit" {...props} />
);

export const ButtonSecondary = (props: Omit<Button, 'type'>) => (
  <Button type="secondary" {...props} />
);

export const ButtonSecondaryInverted = (props: Omit<Button, 'type'>) => (
  <Button type="secondary-inverted" {...props} />
);

export const ButtonLink = (props: Omit<Button, 'type'>) => (
  <Button type="link" {...props} />
);
