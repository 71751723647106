import React, { useCallback } from 'react';
import cx from 'classnames';

import AccordionContext from './AccordionContext';
import AccordionStatic from './Accordion.static';
import { useLazyCallback } from '../../utils/hooks';

type Accordion = {
  /** Accordion mode: `free` = default accordion, `single` = only one accordion item will be active at the same time */
  mode?: 'free' | 'single';
  /** On accordion change callback function. */
  onChange?: (
    {
      index,
      typeOfChange,
      element,
    }: { index: number; typeOfChange: 'open' | 'close'; element: HTMLElement },
    e: Event
  ) => void;
  /** Accordion visual style */
  type?: 'inverted';
} & Omit<JSX.IntrinsicElements['ul'], 'onChange'>;

const Accordion = ({
  className,
  children,
  type,
  onChange = () => {},
  mode = 'free',
  ...other
}: Accordion) => {
  const handleChange = useLazyCallback(onChange);

  const accordionRef = useCallback(
    (node) => {
      if (node) {
        const accordion = new AccordionStatic(node, {
          onChange: handleChange,
        });

        return () => accordion.destroy();
      }
    },
    [handleChange]
  );

  const classes = cx({
    [`accordion`]: true,
    [`accordion--${type}`]: type,
    [`${className}`]: className,
  });

  return (
    <AccordionContext.Provider value={{ type }}>
      <ul
        data-accordion
        data-accordion-mode={mode}
        className={classes}
        ref={accordionRef}
        {...other}
      >
        {children}
      </ul>
    </AccordionContext.Provider>
  );
};

export default Accordion;
