import React, { useContext } from 'react';
import cx from 'classnames';

import { useStatic } from '../../utils/hooks';
import ToggleStatic from '../../scripts/modules/Toggle';
import { Bar, BarItem, Icon, Button } from '..';

import AccordionContext from './AccordionContext';

type AccordionHeader = {
  /** Type of button with arrow must match availble `<Button />` types */
  buttonType?: Button['type'];
  /** Unique ID */
  id?: string;
  /** Wheter the AccordionItem is expanded */
  isActive?: boolean;
  /** Custom rendering for the arrow */
  renderArrow?: (props: AccordionHeader) => JSX.Element;
  /** Custom rendering for the title */
  renderTitle?: (props: AccordionHeader) => JSX.Element;
  /** Title of the header */
  title?: string;
} & JSX.IntrinsicElements['button'];

const DefaultArrow = ({ buttonType }: AccordionHeader) => (
  <Button tag="span" type={buttonType} size="xs" equal shape="round">
    <Icon className="accordion__icon" name="chevron-down" size="small" />
  </Button>
);

const DefaultTitle = ({ className, title }: AccordionHeader) => (
  <span className={className}>{title}</span>
);

const AccordionHeader = ({
  className,
  title,
  renderTitle,
  buttonType = 'secondary',
  renderArrow,
  id,
  isActive,
  ...other
}: AccordionHeader) => {
  const [accordionHeaderRef] = useStatic(ToggleStatic);

  const { type } = useContext(AccordionContext);

  const classes = cx('no-mrg-bottom', className);
  const titleClasses = cx({
    [`accordion__title`]: true,
    [`text-left`]: true,
    [`text-inverted`]: type === 'inverted',
  });

  const Title = renderTitle ?? DefaultTitle;
  const Arrow = renderArrow ?? DefaultArrow;

  return (
    <h3 className={classes}>
      <button
        ref={accordionHeaderRef}
        id={id}
        className="accordion__header"
        type="button"
        aria-expanded={!!isActive}
        aria-controls={`${id}-section`}
        {...generateToggleData(id)}
        {...other}
      >
        <Bar className="no-mrg-bottom">
          <BarItem isFilling>
            <Title
              {...{
                id,
                title,
                buttonType,
                isActive,
                className: titleClasses,
              }}
            />
          </BarItem>
          <BarItem className="d-flex">
            <Arrow {...{ id, title, buttonType, isActive }} />
          </BarItem>
        </Bar>
      </button>
    </h3>
  );
};

const generateToggleData = (id?: string) => ({
  'data-toggle': JSON.stringify([
    { target: 'self', attribute: 'aria-expanded' },
    {
      target: `#${id}-section`,
      attribute: 'class',
      value: 'is-active',
    },
    {
      target: `#${id}-section`,
      attribute: 'aria-hidden',
    },
  ]),
});

export default AccordionHeader;
