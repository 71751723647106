import React from 'react';
import cx from 'classnames';

type List = {
  /** List with outer border */
  bordered?: boolean;
  /** List without any borders(on <ul/> and <li/> elements) */
  clean?: boolean;
  /** List with smaller spacing between items */
  condensed?: boolean;
} & JSX.IntrinsicElements['ul'];

const List = ({
  className,
  bordered,
  clean,
  condensed,
  children,
  ...other
}: List) => {
  const classes = cx({
    [`list`]: true,
    [`list--bordered`]: bordered,
    [`list--clean`]: clean,
    [`list--condensed`]: condensed,
    [`${className}`]: className,
  });

  return (
    <ul className={classes} {...other}>
      {children}
    </ul>
  );
};

export default List;
