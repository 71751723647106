import React, { forwardRef } from 'react';
import cx from 'classnames';

/*

  TODO:
    - make `BarItem` polymorphic for `tag` or `as` prop

*/

type BarItem = {
  /** Bar item should fill empty space */
  isFilling?: boolean;
  /** Bar item could shrink and wrap content if there is not enough empty space */
  canShrink?: boolean;
  /** Type of BarItem */
  type?: 'separator';
  /** HTML tag */
  tag?: string;
} & JSX.IntrinsicElements['div'];

const BarItem = forwardRef<HTMLDivElement, BarItem>(
  ({ className, isFilling, canShrink, tag = 'div', type, ...other }, ref) => {
    const classes = cx({
      [`bar__item`]: true,
      [`bar__item--fill`]: isFilling,
      [`bar__item--shrink`]: canShrink,
      [`bar__item--${type}`]: type,
      [`${className}`]: className,
    });

    const Tag = tag;

    // @ts-ignore
    return <Tag ref={ref} className={classes} {...other} />;
  }
);

export default BarItem;
