import React from 'react';
import cx from 'classnames';

import { Bar, BarItem, Button, Icon } from '..';

/*

  TODO:
    - research who uses this
      - `dovera-pages` uses old API
      - API was changed for `ep-pla` (`dovera-pages` was not updated to new API)
      - it is not used in `ep-pla` anymore (just dead imports)

*/

type SubPageHeader = {
  /** Button component props. */
  actionProps?: {
    /** Back button text */
    backText: string;
    /** Href prop from Button. See Button for more information. */
    href: string | React.ReactNode;
  };
};

const SubPageHeader = ({
  actionProps = {
    backText: 'Späť',
    href: '#',
  },
}: SubPageHeader) => {
  const classes = cx(`sub-page-header`, 'mb-large', 'no-mrg-top');

  return (
    <Bar className={classes}>
      <BarItem className="no-mrg-top">
        <Button type="link-secondary" href={actionProps.href}>
          <Icon className="icon--left" name="arrow-left" size="medium" />{' '}
          {actionProps.backText}
        </Button>
      </BarItem>
    </Bar>
  );
};

export default SubPageHeader;
