import React from 'react';

type Tag = {
  /** Callback for close action */
  onClose?: () => void;
  /** Custom CSS style */
  style?: JSX.IntrinsicElements['span']['style'];
};

const Tag: React.FC<Tag> = ({ onClose, style, children }) => {
  const closeButton = onClose ? (
    <span className="Tag__closeButton" onClick={onClose}>
      &times;
    </span>
  ) : null;

  return (
    <span className="Tag" style={style}>
      {children}
      {closeButton}
    </span>
  );
};

export default Tag;
