import React from 'react';
import cx from 'classnames';

type ListItem = {
  /** Clickable list item */
  clickable?: boolean;
  /** Disabled state */
  isDisabled?: boolean;
  /** Selected state */
  isSelected?: boolean;
} & JSX.IntrinsicElements['li'];

const ListItem = ({
  className,
  children,
  clickable,
  isSelected,
  isDisabled,
  ...other
}: ListItem) => {
  const classes = cx({
    [`list__item`]: true,
    [`list__item--clickable`]: clickable,
    [`is-selected`]: isSelected,
    [`is-disabled`]: isDisabled,
    [`${className}`]: className,
  });

  return (
    <li className={classes} {...other}>
      {children}
    </li>
  );
};

export default ListItem;
