// we don't use export {...} from '...'; syntax
// because we need to import scss before and after
// components import

import '../styles/before-components.scss';

import Accordion, { AccordionItem } from './Accordion';
import Article, { ArticleItem } from './Article';
import Tag from './Tag';
import Bar, { BarVertical, BarItem, BarBreak, BarSeparator } from './Bar';
import {
  BoxHeader,
  BoxFeature,
  BoxAction,
  BoxSupport,
  BoxFeedback,
} from './Box';
import Blockquote from './Blockquote';
import Button, {
  ButtonLayout,
  ButtonGroup,
  ButtonPrepoistit,
  ButtonSecondary,
  ButtonSecondaryInverted,
  ButtonLink,
} from './Button';
import Card, { CardSection, CardImage, CardVideo } from './Card';
import Carousel from './Carousel';
import Code from './Code';
import CodeInline from './CodeInline';
import CodePreview from './CodePreview';
import CookieConsent from './CookieConsent';
import Datepicker from './Forms/Datepicker';
import Disc from './Disc';
import Dropdown, { DropdownItem } from './Dropdown';
import {
  Autocomplete,
  Input,
  Textarea,
  Addon,
  Message,
  ErrorMessage,
  HelpMessage,
  Checkbox,
  Radio,
  CheckboxGroup,
  RadioGroup,
  Select,
  Label,
} from './Forms';
import Grid, { Container, GridCol } from './Grid';
import Icon, { IconSpritePathProvider, IconSpritePathConsumer } from './Icon';
import Image, { ImageAvatar } from './Image';
import Link from './Link';
import List, { ListItem, ListItemDescription } from './List';
import Loader from './Loader';
import Modal from './Modal';
import Plate from './Plate';
import Rectangle from './Rectangle';
import Navigation, { NavigationList } from './Navigation';
import PageSection, { PageSectionImage } from './PageSection';
import Step from './Step';
import SubPageHeader from './SubPageHeader';
import Quote from './Quote';
import Table from './Table';
import { Tabs, TabPanel } from './Tabs';
import Tooltip from './Tooltip';
import Header, {
  HeaderLogo,
  HeaderMenu,
  HeaderSearch,
  HeaderUserMenu,
  HeaderUser,
  HeaderMobileSearch,
  HeaderMobileMenu,
} from './Header';
import Footer from './Footer';
import Embed from './Embed';
import Skeleton from './Skeleton';
import Pagination from './Pagination';

import '../styles/after-components.scss';

import './Icon/icons';

// @ts-ignore
window.DDL = Object.assign(window.DDL || {}, {
  Article,
  ArticleItem,
  Accordion,
  AccordionItem,
  Tag,
  Bar,
  BarVertical,
  BarItem,
  BarBreak,
  BarSeparator,
  BoxHeader,
  BoxFeature,
  BoxAction,
  BoxSupport,
  BoxFeedback,
  Blockquote,
  Button,
  ButtonLayout,
  ButtonGroup,
  ButtonPrepoistit,
  ButtonSecondary,
  ButtonSecondaryInverted,
  ButtonLink,
  Card,
  CardSection,
  CardImage,
  CardVideo,
  Carousel,
  CookieConsent,
  Code,
  CodeInline,
  CodePreview,
  Datepicker,
  Disc,
  Dropdown,
  DropdownItem,
  Autocomplete,
  Input,
  Textarea,
  Addon,
  Message,
  ErrorMessage,
  HelpMessage,
  Checkbox,
  Radio,
  CheckboxGroup,
  RadioGroup,
  Select,
  Label,
  Grid,
  Container,
  GridCol,
  Icon,
  IconSpritePathProvider,
  IconSpritePathConsumer,
  Image,
  ImageAvatar,
  Link,
  List,
  ListItem,
  ListItemDescription,
  Loader,
  Modal,
  Plate,
  Rectangle,
  Navigation,
  NavigationList,
  PageSection,
  PageSectionImage,
  Step,
  SubPageHeader,
  Quote,
  Table,
  Tabs,
  TabPanel,
  Tooltip,
  Header,
  HeaderLogo,
  HeaderMenu,
  HeaderSearch,
  HeaderUserMenu,
  HeaderUser,
  HeaderMobileMenu,
  HeaderMobileSearch,
  Footer,
  Embed,
  Skeleton,
  Pagination,
});

export {
  Article,
  ArticleItem,
  Accordion,
  AccordionItem,
  Tag,
  Bar,
  BarVertical,
  BarItem,
  BarBreak,
  BarSeparator,
  BoxHeader,
  BoxFeature,
  BoxAction,
  BoxSupport,
  BoxFeedback,
  Blockquote,
  Button,
  ButtonLayout,
  ButtonGroup,
  ButtonPrepoistit,
  ButtonSecondary,
  ButtonSecondaryInverted,
  ButtonLink,
  Card,
  CardSection,
  CardImage,
  CardVideo,
  Carousel,
  Code,
  CodeInline,
  CodePreview,
  CookieConsent,
  Datepicker,
  Disc,
  Dropdown,
  DropdownItem,
  Autocomplete,
  Input,
  Textarea,
  Addon,
  Message,
  ErrorMessage,
  HelpMessage,
  Checkbox,
  Radio,
  CheckboxGroup,
  RadioGroup,
  Select,
  Label,
  Grid,
  Container,
  GridCol,
  Icon,
  IconSpritePathProvider,
  IconSpritePathConsumer,
  Image,
  ImageAvatar,
  Link,
  List,
  ListItem,
  ListItemDescription,
  Loader,
  Modal,
  Plate,
  Rectangle,
  Navigation,
  NavigationList,
  PageSection,
  PageSectionImage,
  Step,
  SubPageHeader,
  Quote,
  Table,
  Tabs,
  TabPanel,
  Tooltip,
  Header,
  HeaderLogo,
  HeaderMenu,
  HeaderSearch,
  HeaderUserMenu,
  HeaderUser,
  HeaderMobileMenu,
  HeaderMobileSearch,
  Footer,
  Embed,
  Skeleton,
  Pagination,
};

export * from '../utils/tokens/';
