import React, { Children } from 'react';
import cx from 'classnames';

import { useUniqueId } from '../../../../utils/hooks';

import Label from '../../Label';
import Message from '../../Message';
import RadioCheck from '../RadioCheck';

/*

  TODO:
    - replace cloning with context

*/

type RadioCheckGroup = {
  /** HTML id attribute. */
  id?: string;
  /** Label text. */
  label?: string;
  /** Warning state. If value is string, it's treated as warning message. */
  warning?: React.ReactNode | boolean;
  /** Error state. If value is string, it's treated as error message. */
  error?: boolean | React.ReactNode;
  /** Help text. */
  help?: string;
  /** Disabled state */
  isDisabled?: boolean;
  /** Required state */
  isRequired?: boolean;
  /** Custom error renderer. Passes props as function parameter. */
  renderError?: (props: RadioCheckGroup) => JSX.Element;
  /** Custom help renderer. Passes props as function parameter. */
  renderHelp?: (props: RadioCheckGroup) => JSX.Element;
  /** Custom label renderer. Passes props as function parameter. */
  renderLabel?: (props: RadioCheckGroup) => JSX.Element;
  /** Custom error renderer. Passes props as function parameter. */
  renderWarning?: (props: RadioCheckGroup) => JSX.Element;
  /** Role for component. `group` represents checkbox group and `radiogroup` radio inputs group. */
  role: 'group' | 'radiogroup';
  /** Children can be only of type `RadioCheck` */
  children: React.ReactElement<RadioCheck>[];
} & Omit<JSX.IntrinsicElements['div'], 'children'>;

const DefaultGroupLabel = ({
  id,
  label,
  isDisabled,
  isRequired,
}: RadioCheckGroup) => (
  <Label id={`${id}__label`} isDisabled={isDisabled} isRequired={isRequired}>
    {label}
  </Label>
);
const DefaultHelp = ({ help, id }: RadioCheckGroup) => (
  <Message type="help" id={`${id}__help`}>
    {help}
  </Message>
);
const DefaultWarning = ({ warning }: RadioCheckGroup) => (
  <Message type="warning">{warning}</Message>
);
const DefaultError = ({ error }: RadioCheckGroup) => (
  <Message type="error">{error}</Message>
);

const RadioCheckGroup = (providedProps: RadioCheckGroup) => {
  const {
    children,
    className,
    error,
    renderError,
    help,
    id: providedId,
    renderHelp,
    label,
    renderLabel,
    warning,
    renderWarning,
    isDisabled,
    isRequired,
    role = 'group',
    ...other
  } = providedProps;

  const id = useUniqueId(providedId, 'radiocheck_group');
  const props = { ...providedProps, id };

  const GroupLabel = renderLabel ?? DefaultGroupLabel;
  const Help = renderHelp ?? DefaultHelp;
  const Error = renderError ?? DefaultError;
  const Warning = renderError ?? DefaultWarning;

  const classes = cx('radiocheck-group', className);

  const formControlClasses = cx({
    [`form-control`]: true,
    [`form-control--group`]: true,
    [`is-error`]: error,
    [`is-warning`]: warning,
  });

  return (
    <div
      role={role}
      {...(label && { 'aria-labelledby': `${id}__label` })}
      {...(help && { 'aria-describedby': `${id}__help` })}
      className={formControlClasses}
    >
      <div className={classes} {...other}>
        {label && <GroupLabel {...props} />}
        {help && <Help {...props} />}
        {warning && <Warning {...props} />}
        {error && <Error {...props} />}
        {Children.map(children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(child, {
                isDisabled: child.props.isDisabled ?? isDisabled,
              })
            : null
        )}
      </div>
    </div>
  );
};

export default RadioCheckGroup;
