import React from 'react';
import cx from 'classnames';

import { colors } from '../../utils/tokens/color';

/*

  TODO:
    - set `color` by name
    - set `size` by name
    - write better docs

*/

type Loader = {
  /** Whether the Loader is active */
  active?: boolean;
  /** CSS classname */
  className?: string;
  /** Color of the loader */
  color?: string;
  /** Size of the loader in pixels */
  size?: number;
};

const Loader: React.FC<Loader> = ({
  size = 35,
  active = true,
  color = colors.secondary[500],
  className,
  children,
}) => {
  return (
    <div
      className={cx({
        [`loader`]: true,
        [`loader--active`]: active,
        [`${className}`]: className,
      })}
    >
      <div
        className="loader_ring"
        style={{ width: `${size}px`, height: `${size}px`, borderColor: color }}
      />
      <div className="loader_label">{children}</div>
    </div>
  );
};

export default Loader;
