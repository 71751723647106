import { space } from './space';
import { color } from './color';

export const contentSpacing = space('large');
export const textColor = color('black');
export const bgColor = color('white');

export const selectionBgColor = color('primary');
export const selectionTextColor = color('white');

export const borderColor = color('grey', 200);
export const border = `1px solid ${borderColor}`;
export const borderRadius = '5';

export const zIndex = {
  default: 100,
  topbar: 200,
  overlay: 500,
};
