import React from 'react';
import cx from 'classnames';

import { useUniqueId } from "../../utils/hooks";

import { Grid, GridCol } from '..';

type Size =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge';

type Image = {
  /** Alternative text */
  alt?: string;
  /** Image description under component. */
  description?: string;
  /** Image height */
  height?: Size;
  /** Image id (automatically generated by default)  */
  id?: string;
  /** Circle shaped image */
  isCircle?: boolean;
  /** Image will adapt to size of container */
  isFluid?: boolean;
  /** Rounded corneres */
  isRounded?: boolean;
  /** Src attribute */
  src: string;
  /** Predefined types of image */
  type?: 'avatar';
  /** Image width */
  width?: Size;
  /** Image HTML height attribute */
  heightAttr?: number | string;
  /** Image HTML width attribute */
  widthAttr?: number | string;
} & JSX.IntrinsicElements['img'];

const Image = ({
  className,
  isFluid,
  isRounded,
  isCircle,
  width,
  widthAttr,
  height,
  heightAttr,
  src,
  id,
  alt,
  type,
  description,
  ...other
}: Image) => {
  const classes = cx({
    [`img`]: true,
    [`img--fluid`]: isFluid,
    [`img--rounded`]: isRounded,
    [`img--circle`]: isCircle,
    [`img--${width}-w`]: width && typeof width === 'string',
    [`img--${height}-h`]: height && typeof height === 'string',
    [`img--${type}`]: type,
    [`mb-small`]: description,
    [`${className}`]: className,
  });

  const currentId = useUniqueId(id);

  const image = (
    <img
      className={classes}
      src={src}
      alt={alt}
      {...(description && { 'aria-describedby': `${currentId}-description` })}
      width={widthAttr}
      height={heightAttr}
      {...other}
    />
  );

  return description ? (
    <figure className="img-description">
      {image}
      <Grid className="no-mrg">
        <GridCol size={{ m: 10 }} offset={{ m: 1 }}>
          <figcaption
            id={`${currentId}-description`}
            className="img-description__content"
          >
            {description}
          </figcaption>
        </GridCol>
      </Grid>
    </figure>
  ) : (
    image
  );
};

export default Image;
