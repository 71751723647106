import React from 'react';
import cx from 'classnames';

/*

  TODO:
    - research if `renderIcon` is used somewhere

*/

type Size = 'small' | 'medium' | 'large';
type IconName =
  | '16-chevron-down'
  | '16-chevron-left'
  | '16-chevron-right'
  | '16-chevron-up'
  | '16-close'
  | '16-info'
  | '16-open'
  | '32-award'
  | '32-doctor'
  | '32-platitel'
  | '32-poistenec'
  | 'add-user'
  | 'arrow-left'
  | 'arrow-right'
  | 'calendar'
  | 'check-box'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'close'
  | 'color-baby'
  | 'color-call'
  | 'color-chat'
  | 'color-family'
  | 'color-healthy'
  | 'color-mail'
  | 'color-sick'
  | 'doctor'
  | 'download'
  | 'drop-down'
  | 'drop-up'
  | 'erase'
  | 'file'
  | 'info'
  | 'linkedin'
  | 'log-out'
  | 'menu-right'
  | 'more-horizontal'
  | 'more-vertical'
  | 'open'
  | 'paper-clip'
  | 'search'
  | 'social-apple'
  | 'social-facebook'
  | 'social-googleplay'
  | 'social-linkedin'
  | 'social-twitter'
  | 'social-youtube'
  | 'star'
  | 'thumbs-down'
  | 'thumbs-up';

type Icon = {
  /** Alternative text for `<title />` tag. Default is the icon name. */
  alt?: string;
  /** Icon name to show */
  name: IconName;
  /** Custom icon renderer. */
  renderIcon?: ({
    alt,
    name,
    size,
  }: Pick<Icon, 'alt' | 'name' | 'size'>) => JSX.Element;
  /** Icon size */
  size?: Size;
  /** SVG sprite URL */
  spritePath: string;
} & JSX.IntrinsicElements['svg'];

const Icon = ({
  className,
  name,
  renderIcon,
  size,
  spritePath = '/sprite.svg',
  alt,
  ...other
}: Icon) => {
  const classes = cx({
    [`icon`]: true,
    [`icon--${size}`]: size,
    [`icon--${name}`]: name,
    [`${className}`]: className,
  });

  if (renderIcon) {
    return renderIcon({ alt, name, size });
  }

  return (
    <svg
      className={classes}
      role={alt ? 'img' : 'presentation'}
      {...(!alt && { 'aria-hidden': 'true' })}
      {...other}
    >
      <title>{alt || name}</title>
      <use xlinkHref={`${spritePath}#${name}`} />
    </svg>
  );
};

export default Icon;
