import React from 'react';

import './style.scss';

type CodeInline = {};

const CodeInline: React.FC<CodeInline> = ({ children }) => (
  <code className="inline-code">{children}</code>
);

export default CodeInline;
