// ---------------------------------------------------
// Header
// module for handling Header

export default class Header {
  constructor(element) {
    this.menu = element;
    this.header = element.closest('.header-desktop');

    this.overflowTrigger = this.header.querySelector(
      '[data-header-dropdown-trigger]'
    );

    this.overflowMenu = document.getElementById(
      this.overflowTrigger.getAttribute('aria-controls')
    );

    this.menuItems = Array.from(
      this.menu.querySelectorAll('.header-menu__item')
    );
    this.searchBar = this.header.querySelector('.header-desktop__search');
    this.searchBarTrigger = this.header.querySelector(
      '[data-header-search-button]'
    );

    this.breakout = [];

    this.getPriorityWidth = this.getPriorityWidth.bind(this);
    Header.getItemBreakPoint = Header.getItemBreakPoint.bind(this);
    Header.putItemToOverflowMenu = Header.putItemToOverflowMenu.bind(this);
    this.putItemToMenu = this.putItemToMenu.bind(this);
    this.addToOverflow = this.addToOverflow.bind(this);
    this.removeFromOverflow = this.removeFromOverflow.bind(this);
    this.toggleOverflowMenuTrigger = this.toggleOverflowMenuTrigger.bind(this);
    this.sortMenuItems = this.sortMenuItems.bind(this);
    this.addListeners = this.addListeners.bind(this);
    this.overflowTriggerVisibility = this.overflowTriggerVisibility.bind(this);
    this.toggleSearchBarVisibility = this.toggleSearchBarVisibility.bind(this);

    this.init();

    return this;
  }

  init() {
    this.sortMenuItems();
    this.addListeners();
    this.showMenu();
  }

  toggleSearchBarVisibility() {
    this.menu.parentElement.classList.toggle('bar__item--fill');
    this.searchBar.parentElement.classList.toggle('bar__item--fill');
    this.sortMenuItems();
  }

  getPriorityWidth() {
    return this.menu.offsetWidth + 1 - this.overflowTrigger.offsetWidth;
  }

  static getItemBreakPoint(item) {
    return item.offsetLeft + item.offsetWidth;
  }

  static putItemToOverflowMenu(breakPoint, menuWidth) {
    return breakPoint > menuWidth;
  }

  putItemToMenu(index, menuWidth) {
    if (this.breakout[index] < menuWidth) {
      return true;
    }
    return false;
  }

  addToOverflow(item, itemBreakPoint) {
    this.overflowMenu.insertBefore(item, this.overflowMenu.firstChild);
    this.breakout.unshift(itemBreakPoint);
  }

  removeFromOverflow(breaksOut) {
    breaksOut.forEach(item => {
      // eslint-disable-line
      this.breakout.shift();
      this.menu.insertBefore(item, this.menu.lastChild);
    });
  }

  toggleOverflowMenuTrigger(value) {
    if (value.toString() !== this.overflowTrigger.getAttribute('aria-hidden')) {
      this.overflowTrigger.setAttribute('aria-hidden', value);
      this.sortMenuItems();
    }
  }

  showMenu() {
    this.menu.setAttribute('aria-hidden', 'false');
  }

  sortMenuItems() {
    const menuWidth = this.getPriorityWidth();

    let menuIndex = this.menuItems.length;

    // eslint-disable-next-line no-cond-assign
    while ((menuIndex -= 1) >= 0) {
      const item = this.menuItems[menuIndex];
      const itemBreakPoint = Header.getItemBreakPoint(item);

      if (Header.putItemToOverflowMenu(itemBreakPoint, menuWidth)) {
        item.setAttribute('data-original-classes', item.children[0].className);

        item.children[0].className = '';
        item.children[0].classList.add('dropdown__item');

        this.addToOverflow(item, itemBreakPoint);
      }
    }

    let overflowIndex = this.overflowMenu.children.length;
    const breaksOut = [];

    // eslint-disable-next-line no-cond-assign
    while ((overflowIndex -= 1) >= 0) {
      if (this.putItemToMenu(overflowIndex, menuWidth)) {
        this.overflowMenu.children[overflowIndex].classList.remove(
          'dropdown__item'
        );

        this.overflowMenu.children[
          overflowIndex
        ].children[0].className = this.overflowMenu.children[
          overflowIndex
        ].getAttribute('data-original-classes');

        this.overflowMenu.children[overflowIndex].removeAttribute(
          'data-original-classes'
        );

        breaksOut.unshift(this.overflowMenu.children[overflowIndex]);
      }
    }

    this.removeFromOverflow(breaksOut);
    this.toggleOverflowMenuTrigger(this.breakout.length === 0);
  }

  overflowTriggerVisibility() {
    this.overflowMenu.setAttribute(
      'aria-hidden',
      this.overflowMenu.getAttribute('aria-hidden') === 'true'
        ? 'false'
        : 'true'
    );
  }

  addListeners() {
    window.addEventListener('resize', this.sortMenuItems);

    this.overflowTrigger.addEventListener(
      'click',
      this.overflowTriggerVisibility
    );

    if (this.searchBarTrigger) {
      this.searchBarTrigger.addEventListener(
        'click',
        this.toggleSearchBarVisibility
      );
    }
  }

  destroy() {
    window.removeEventListener('resize', this.sortMenuItems);

    this.overflowTrigger.removeEventListener(
      'click',
      this.overflowTriggerVisibility
    );

    if (this.searchBarTrigger) {
      this.searchBarTrigger.removeEventListener(
        'click',
        this.toggleSearchBarVisibility
      );
    }
  }

  update() {
    this.destroy();
    this.init();
  }
}
