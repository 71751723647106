import { breakpoints } from '../../utils/tokens/breakpoints';

/*

  TODO:
    - ask Beni about 767 vs. 768 breakpoint

*/

const defaultConfig = {
  mobileResolution: breakpoints.m - 1,
};
export default class CardSection {
  element: HTMLElement;
  config: typeof defaultConfig;
  constructor(element: HTMLElement, config = {}) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.init();

    // @ts-ignore
    this.element.DDL_Card = this;
  }

  init() {
    if (
      this.element.classList.contains('card__section--video') &&
      window.innerWidth >= this.config.mobileResolution
    ) {
      this.handleVideoVisibility();
    }
  }

  static getInstance(el: HTMLDivElement) {
    // @ts-ignore
    return el && el.DDL_Card ? el.DDL_Card : null;
  }

  handleVideoVisibility = () => {
    const video = this.element.querySelector('video');
    const source = this.element.querySelector('source');
    const videoSrc = source?.getAttribute('data-video-source');
    source?.setAttribute('src', videoSrc ?? '');
    video?.load();
  };

  update() {
    this.init();
  }
}
