import React from 'react';
import cx from 'classnames';

import { Bar, BarItem, ImageAvatar } from '..';

type AvatarSize = 'small' | 'medium' | 'large';
type Plate = {
  /** Avatar image src attribute */
  avatarSrc?: string;
  /** Plate description text */
  description?: string;
  /** Smaller more densed version of Plate */
  isCondensed?: boolean;
  /** Vertical version of Plate. Plate is horizontal by default. */
  isVertical?: boolean;
  /** Custom avatar renderer. Passes props as function parameter. */
  renderAvatar?: (props: {
    avatarSize: ImageAvatar['size'];
    isCondensed?: boolean;
    isVertical?: boolean;
    title?: string;
  }) => JSX.Element;
  /** Custom description renderer. Passes props as function parameter. */
  renderDescription?: (props: {
    className?: string;
    description?: string;
    isCondensed?: boolean;
    isVertical?: boolean;
  }) => JSX.Element;
  /** Custom title renderer. Passes props as function parameter. */
  renderTitle?: (props: {
    className?: string;
    title?: string;
    isCondensed?: boolean;
    isVertical?: boolean;
  }) => React.ReactNode;
  /** Plate title text */
  title?: string;
} & JSX.IntrinsicElements['div'];

const Plate = ({
  children,
  className,
  title,
  renderTitle,
  avatarSrc,
  renderAvatar,
  description,
  isCondensed,
  isVertical,
  renderDescription,
  ...other
}: Plate) => {
  const classes = cx({
    [`plate`]: true,
    [`plate--is-vertical`]: isVertical,
    [`${className}`]: className,
  });

  const descriptionClasses = cx({
    [`plate__description`]: true,
    [`mb-small p--center`]: isVertical,
  });

  const titleClasses = cx({
    [`plate__title`]: true,
    [`p--center`]: isVertical,
  });

  let avatarSize: AvatarSize;
  if (isCondensed) {
    avatarSize = 'small';
  } else if (isVertical) {
    avatarSize = 'large';
  } else {
    avatarSize = 'medium';
  }

  const avatar =
    (renderAvatar &&
      renderAvatar({
        avatarSize,
        isCondensed,
        isVertical,
        title,
      })) ||
    (avatarSrc && (
      <ImageAvatar
        src={avatarSrc}
        size={avatarSize as ImageAvatar['size']}
        alt={title ?? ''}
      />
    ));

  const titleToRender =
    (renderTitle &&
      renderTitle({
        className: titleClasses,
        title,
        isCondensed,
        isVertical,
      })) ||
    (title && <p className={titleClasses}>{title}</p>);

  const descriptionToRender =
    (renderDescription &&
      renderDescription({
        className: descriptionClasses,
        description,
        isCondensed,
        isVertical,
      })) ||
    (description && <p className={descriptionClasses}>{description}</p>);

  return (
    <div className={classes} {...other}>
      <Bar
        className="no-mrg-bottom"
        space={isCondensed ? 'xxsmall' : undefined}
        direction={isVertical ? 'vertical' : undefined}
      >
        {avatar && (
          <BarItem
            className={cx(
              'plate__avatar',
              isVertical ? 'mb-small' : 'no-mrg-bottom'
            )}
          >
            {avatar}
          </BarItem>
        )}
        {(titleToRender || descriptionToRender) && (
          <BarItem canShrink isFilling>
            {titleToRender}
            {descriptionToRender}
            {children}
          </BarItem>
        )}
      </Bar>
    </div>
  );
};

export default Plate;
