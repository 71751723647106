import Swiper from 'swiper';

import { breakpoints } from '../../utils/tokens';

const defaultConfig = {
  swiperOptions: {
    speed: 400,
    spaceBetween: 32,
    simulateTouch: true,
    grabCursor: true,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 16,
      },
      [breakpoints.s]: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      [breakpoints.m]: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
      [breakpoints.l]: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
  },
};

export default class Carousel {
  instance: Swiper | null = null
  element: HTMLElement
  count: number
  config: typeof defaultConfig

  constructor(element: HTMLElement, config: Partial<typeof defaultConfig>) {
    this.element = element;
    this.count = this.element.getElementsByClassName(
      'swiper-wrapper'
    )[0].children.length;
    this.config = { ...defaultConfig, ...config };
    this.config.swiperOptions = {
      ...defaultConfig.swiperOptions,
      ...(config.swiperOptions || {}),
    };

    this.init();

    // @ts-ignore
    this.element.DDL_Carousel = this;

    return this;
  }

  init() {
    let slidesCount = {};
    if (this.count < 4) {
      slidesCount = { slidesPerView: 3 };
    } else {
      slidesCount = { slidesPerView: 4 };
    }

    this.config.swiperOptions = {
      ...this.config.swiperOptions,
      ...slidesCount,
    };
    this.instance = new Swiper(this.element, this.config.swiperOptions);
  }

  static getInstance(el: HTMLElement) {
    // @ts-ignore
    return el && el.DDL_Carousel ? el.DDL_Carousel : null;
  }

  destroy() {
    this.instance?.destroy();
  }

  update() {
    this.destroy();
    this.init();
  }
}
