import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import GithubTheme from 'prism-react-renderer/themes/github';

import './style.scss';

type Code = {
  /** Language used for syntax highlighting */
  language: 'javascript' | 'jsx' | 'typescript' | 'tsx' | 'bash' | 'scss' | 'diff' | 'json';
  /** Code content */
  children: string
};

const Code: React.FC<Code> = ({ children, language }) => (
  <Highlight
    {...defaultProps}
    code={children}
    language={language}
    theme={GithubTheme}
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre className={className} style={style}>
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span key={key} {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
);

export default Code;
