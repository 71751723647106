import React from 'react';
import cx from 'classnames';

type Blockquote = {} & JSX.IntrinsicElements['p'];

const Blockquote: React.FC<Blockquote> = ({
  children,
  className,
  ...other
}) => {
  const classes = cx({
    [`blockquote`]: true,
    [`h5`]: true,
    [`${className}`]: className,
  });

  return (
    <p className={classes} {...other}>
      {children}
    </p>
  );
};

export default Blockquote;
