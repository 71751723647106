import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
} from 'react';
import cx from 'classnames';

import { useStatic, useUniqueId } from '../../utils/hooks';

import ModalStatic from './Modal.static';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';

type Modal = {
  /**  Imperative handle */
  $ref?: ModalImperativeHandle;
  /** CSS classes */
  className?: string;
  /** Disable closing modal on overlay click */
  closeOnOverlayClick?: boolean;
  /** Footer content */
  footer?: React.ReactNode;
  /** Footer background */
  footerBackground?: 'secondary';
  /** Header content */
  header: React.ReactNode;
  /** Html id attribute */
  id?: string;
  /** On hide callback function */
  onHide?: () => void;
  /** On show callback function */
  onShow?: () => void;
  /**  Content */
  children: React.ReactNode;
  /** Whether modal is visible */
  isVisible?: boolean;
};

type ModalImperativeHandle = {
  show: () => void;
  hide: () => void;
  modal: React.RefObject<ModalStatic>;
};

const Modal = forwardRef<ModalImperativeHandle, Modal>(
  (
    {
      className,
      children,
      id: providedId,
      closeOnOverlayClick = true,
      header,
      footer,
      footerBackground,
      onShow = () => {},
      onHide = () => {},
      isVisible = false,
    },
    ref
  ) => {
    const [modalRef, modal] = useStatic(ModalStatic, { onShow, onHide });

    const show = useCallback(() => {
      modal.current?.show();
    }, [modal]);

    const hide = useCallback(() => {
      modal.current?.hide();
    }, [modal]);

    useEffect(() => {
      if (isVisible) {
        show();
      } else {
        hide();
      }
    }, [isVisible, hide, show]);

    useImperativeHandle(ref, () => ({ modal, show, hide }));

    const id = useUniqueId(providedId);
    const headerId = `${id}-title`;

    return (
      <div
        id={id}
        data-modal
        className="dialog"
        ref={modalRef}
        aria-modal="true"
        aria-label={id}
        aria-labelledby={headerId}
        aria-hidden="true"
      >
        <div
          className="modal__overlay"
          tabIndex={-1}
          {...(closeOnOverlayClick && { 'data-a11y-dialog-hide': '' })}
        />
        <div className={cx('modal', className)} role="dialog">
          <ModalHeader id={headerId}>{header}</ModalHeader>
          <ModalContent className={!!footer ? '' : 'padding-bottom'}>
            {children}
          </ModalContent>
          {footer && (
            <ModalFooter className={footerBackground}>{footer}</ModalFooter>
          )}
        </div>
      </div>
    );
  }
);

export default Modal;
