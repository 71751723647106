import React from 'react';
import cx from 'classnames';

/*

  TODO:
    - make `PageSection` polymorphic via `as` or `tag` prop

*/

type Background = 'white' | 'grey' | 'blue';
type Space = 'small' | 'none';

type PageSection = {
  /** Background color. */
  bg?: Background;
  /** Right half background. */
  bgRight?: Background;
  /** Top half background. */
  bgTop?: Background;
  /** Content of the page section. */
  children?: React.ReactNode;
  /** Classnames. */
  className?: string;
  /** Shows illustration. */
  hasIllustration?: boolean;
  /** Padding inside component. */
  space?: Space;
  /** Padding bottom. */
  spaceBottom?: Space;
  /** Padding top. */
  spaceTop?: Space;
  /** HTML tag. */
  tag?: keyof JSX.IntrinsicElements;
} & JSX.IntrinsicElements['section'];

const PageSection = ({
  children,
  className = '',
  bg,
  bgTop,
  bgRight,
  space,
  spaceTop,
  spaceBottom,
  tag = 'section',
  hasIllustration = false,
  ...other
}: PageSection) => {
  const classes = cx({
    [`page-section`]: true,
    [`page-section--${bg}`]: bg,
    [`page-section--top-${bgTop}`]: bgTop,
    [`page-section--right-${bgRight}`]: bgRight,
    [`page-section--pb-${spaceBottom}`]: spaceBottom,
    [`page-section--pt-${spaceTop}`]: spaceTop,
    [`page-section--pb-${space} page-section--pt-${space}`]: space,
    [`page-section--has-illustration`]: hasIllustration,
    [`${className}`]: className,
  });

  const Tag = tag;

  return (
    // @ts-ignore
    <Tag className={classes} {...other}>
      {children}
    </Tag>
  );
};

export default PageSection;
