import React from 'react';

import cx from 'classnames';

import { BarItem, Button, Dropdown, DropdownItem, Icon } from '..';

type HeaderButton = {
  href: Button['href'];
  title: Button['children'];
  iconProps?: Icon;
} & Button;

const HeaderButton = ({
  href,
  iconProps,
  title,
  isActive,
  ...other
}: HeaderButton) => (
  <Button href={href} {...other}>
    {iconProps && <Icon {...iconProps} />}
    {title}
  </Button>
);

type HeaderDropdown = {
  buttonProps: Button;
  children: DropdownItem[];
};

const HeaderDropdown = ({ children, buttonProps }: HeaderDropdown) => (
  <Dropdown
    buttonProps={buttonProps}
    style={{ zIndex: 201 }}
    iconProps={{ size: 'small' }}
  >
    {children.map(({ title, href, isActive, ...childOther }) => {
      return (
        <DropdownItem
          key={title}
          href={href}
          isActive={isActive}
          {...childOther}
        >
          {title}
        </DropdownItem>
      );
    })}
  </Dropdown>
);

type HeaderItem = {
  /** Object which defines params of header item. All other params will drop to `...rest` parameter and apply on the component. */
  item: {
    /** Item title. */
    title: string;
    /** Item link. Passing `node` can be handy for example with `react-router` `<Link />` component. */
    href: React.ReactNode;
    /** When true, apply different styles to item. */
    isMain?: boolean;
    /** Sets active state / style on item. */
    isActive?: boolean;
    /** Icon props object, which shows next to the item title. */
    iconProps?: Icon;
  };
} & Omit<BarItem, 'ref'>;

const HeaderItem = ({ children, className, item, ...other }: HeaderItem) => {
  const classes = cx({
    [`header-menu__item`]: true,
    [`is-main`]: item.isMain,
    [`${className}`]: className,
  });

  const buttonProps = {
    type: item.isActive ? 'link' : 'link-secondary',
    size: 's',
    className: 'no-pad-horizontal no-mrg-bottom',
    children: item.title,
  } as const;

  const headerItem = children ? (
    <HeaderDropdown {...item} buttonProps={buttonProps}>
      {children as DropdownItem[]}
    </HeaderDropdown>
  ) : (
    <HeaderButton {...item} {...buttonProps} />
  );

  return (
    <BarItem className={classes} {...other}>
      {headerItem}
    </BarItem>
  );
};

export default HeaderItem;
