import React, { isValidElement, useEffect, useCallback, useState } from 'react';
import cx from 'classnames';

import { useLazyCallback, useStatic } from '../../utils/hooks';

import PageSection from '../PageSection/PageSection';

import TabsStatic from './Tabs.static';
import Tab from './Tab';
import TabPanel from './TabPanel';

import './styles/style.scss';

/*

  TODO:
    - extract state out

*/

type Tabs = {
  /** Active Tab index */
  activeTabIndex?: number;
  /** Additional classes for tabs header. */
  classesTabNav?: string;
  /** Additional classes for tabs content. */
  classesTabPanelWrapper?: string;
  /** Ability to turn off appending #id of active tab to url */
  disableURLChange?: boolean;
  /** If true, all tabs have equal width; their parent has full width */
  equalTabWidth?: boolean;
  /** Sets gradient color to matches background of the parent element. */
  gradientColor?: PageSection['bg'];
  /** On tab change callback function. */
  onChange?: (
    selectedTab: { index: number; tab: any; panel: any },
    e: any
  ) => void;
  /** Tab list can be wrapped in other components/elements.
   *
   * Key has to be passed to top most element of wrapper due internal strucure of `<Tabs />` component.
   * @example
   * ({ tabList, key }) => <div key={key}>{tabList}</div>.
   */
  tabListWrapper?: (args: {
    tabList: JSX.Element;
    key?: string;
  }) => JSX.Element;
  /** Tab panel can be wrapped in other components/elements.
   *
   * Key has to be passed to top most element of wrapper due internal strucure of `<Tabs />` component.
   *
   * @example
   * ({ tabPanel, key }) => <div key={key}>{tabPanel}</div>
   */
  tabPanelsWrapper?: (args: {
    tabPanels: JSX.Element;
    key?: string;
  }) => JSX.Element;
  /** Visual type */
  type?: 'link' | 'benefits';
} & JSX.IntrinsicElements['div'];

const Tabs = ({
  activeTabIndex = 0,
  disableURLChange,
  className,
  classesTabNav,
  classesTabPanelWrapper,
  equalTabWidth,
  tabListWrapper = ({ tabList }) => tabList,
  tabPanelsWrapper = ({ tabPanels }) => tabPanels,
  gradientColor,
  type = 'link',
  children,
  onChange = () => {},
  ...other
}: Tabs) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex);
  const onChangeCallback = useLazyCallback(onChange);

  const handleChange = useCallback(
    (
      active: { index: number; tab: HTMLElement; panel: HTMLElement },
      e: Event
    ) => {
      setActiveTab(active.index);
      onChangeCallback(active, e);
    },
    [onChangeCallback]
  );

  useEffect(() => {
    setActiveTab(activeTabIndex);
  }, [activeTabIndex]);

  const [tabListRef] = useStatic(TabsStatic, {
    onChange: handleChange,
    disableURLChange,
  });

  const tabs = React.Children.map(children, (tabPanel, i) => {
    if (isValidElement<TabPanel>(tabPanel)) {
      const TabComponent = tabPanel.props.renderTab ?? Tab;
      return (
        <TabComponent
          key={tabPanel.props.id}
          controls={tabPanel.props.id}
          isDisabled={tabPanel.props.isDisabled}
          type={type}
          isActive={i === activeTab}
        >
          {tabPanel.props.tab}
        </TabComponent>
      );
    }
    return null;
  });

  const panels = React.Children.map(children, (tabPanel, i) =>
    isValidElement<TabPanel>(tabPanel)
      ? React.cloneElement(tabPanel, {
          key: `tabpanel-${tabPanel.props.id}`,
          isActive: i === activeTab,
        })
      : null
  );

  const tabNavClasses = cx({
    [`tab-list`]: true,
    [`tab-list--equal`]: equalTabWidth,
    [`tab-list--${gradientColor} hide-before`]: gradientColor,
    [`${classesTabNav}`]: classesTabNav,
  });

  const tabList = (
    <div
      key="tabnav"
      className={tabNavClasses}
      data-tabs=""
      ref={tabListRef}
      {...(disableURLChange && { 'data-tabs-disable-url-change': '' })}
      {...other}
    >
      <ul className="tab-list__list" role="tablist">
        {tabs}
      </ul>
    </div>
  );

  const tabPanels = (
    <div key="tabpanels" className={cx('tab-panels', classesTabPanelWrapper)}>
      {panels}
    </div>
  );

  return (
    <>
      {tabListWrapper({ tabList })}
      {tabPanelsWrapper({ tabPanels })}
    </>
  );
};

export default Tabs;
