import React from 'react';
import cx from 'classnames';

type Addon = JSX.IntrinsicElements["div"];

const Addon = ({ className, ...other }: Addon) => {
  const classes = cx('input-addons__item', className);

  return <div className={classes} {...other} />;
};

export default Addon;
