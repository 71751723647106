import { isObject } from '../helpers';

/*

  TODO:
    - explore if we could use interpolation for color weight instead
      of predefined discrete color weights

*/

export const colors = {
  primary: {
    600: '#408020',
    500: '#50a028',
    400: '#73b353',
    100: '#ddedd2',
  },
  secondary: {
    600: '#194684',
    500: '#1f57a5',
    400: '#4c79b7',
    100: '#d2dded',
  },
  prepoistit: {
    600: '#cc5c28',
    500: '#ff7332',
    400: '#ff8f5b',
    100: '#ffe3d6',
  },
  success: {
    500: '#4caf50',
    100: '#dbefdc',
  },
  warning: {
    500: '#ff9800',
    100: '#ffeacc',
  },
  error: {
    500: '#e91c0d',
    100: '#fbd2cf',
  },
  grey: {
    500: '#6d7175',
    300: '#a7a9ac',
    200: '#c5c6c8',
    100: '#e1e2e3',
    50: '#f7f7f7',
  },
  white: '#fff',
  transparentWhite: {
    800: 'rgba(255, 255, 255, 0.8)',
    700: 'rgba(255, 255, 255, 0.7)',
    500: 'rgba(255, 255, 255, 0.5)',
    400: 'rgba(255, 255, 255, 0.4)',
    200: 'rgba(255, 255, 255, 0.2)',
    100: 'rgba(255, 255, 255, 0.1)',
  },
  black: '#1f252a',
  pureBlack: '#000',
  semiBlack: 'rgba(0, 0, 0, 0.5)',
} as const;

type ColorName = keyof typeof colors;

export function color(
  colorName: ColorName,
  weight = 500,
  colorPalette = colors
): string {
  if (!isObject(colorPalette)) {
    throw new Error(
      `colorPalette ${JSON.stringify(colorPalette)} is not an object`
    );
  }

  if (typeof colorPalette[colorName] === 'string') {
    return colorPalette[colorName] as string;
  }

  // @ts-ignore
  if (!colorPalette[colorName][weight]) {
    throw new Error(
      `color ${colorName} of weight ${weight} not exists in colorPalette ${JSON.stringify(
        colorPalette
      )}`
    );
  }

  // @ts-ignore
  return colorPalette[colorName][weight];
}
