import { useEffect, useCallback, useRef, useMemo } from 'react';
import composeRefs from '@seznam/compose-react-refs';

import generateId from './useId';

/**
 * Stores `callback` in a ref. It is lazily called when necessary.
 *
 * Also known as `useEventCallback`.
 */
const useLazyCallback = (callback: (...args: any[]) => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const lazyCallback = useCallback(
    (...args) => callbackRef.current(...args),
    []
  );

  return lazyCallback;
};

/**
 * Returns unique string for the `id` attribute.
 *
 * @param id Provided id
 * @param name Optional prefix
 */
const useUniqueId = (id?: string, name?: string) =>
  useMemo(() => id ?? generateId(name), [id, name]);

interface StaticClass {
  update?(config?: {}): void;
  destroy?(): void;
}

interface StaticClassConstructable<T extends StaticClass> {
  new (ref: HTMLElement, config: {}): T;
}

/**
 * Hook that is managing "static" class in a reactive way
 *
 * @param StaticClass Class that encapsulates DOM mutating behaviour
 * @param config Object holding message passed to static class
 */

const useStatic = <T extends StaticClass>(
  StaticClass: StaticClassConstructable<T>,
  config: {} = {}
) => {
  const instance = useRef<T | null>(null);
  const configRef = useRef(config);

  useEffect(() => {
    configRef.current = config ?? {};
  }, [config]);

  const ref = useCallback(
    (node: HTMLElement | null) => {
      // use latest config available
      const config = configRef.current;
      if (node) {
        if (instance.current === null) {
          instance.current = new StaticClass(node, config) as T;
        } else {
          instance.current.update?.(config);
        }
      }
    },
    [StaticClass]
  );

  useEffect(
    () => () => {
      if (instance.current) {
        instance.current.destroy?.();
        instance.current = null;
      }
    },
    []
  );

  return [ref, instance] as const;
};

export { composeRefs, useLazyCallback, useUniqueId, useStatic };
