import React from 'react';
import { arrayOf, shape, string, bool, func } from 'prop-types';
import cx from 'classnames';

import HeaderMenuStatic from './HeaderMenu.static';
import HeaderItem from './HeaderItem';

import { Button, Bar, BarItem, Dropdown, Icon } from '..';

const CLASS_ROOT = 'header-menu';

export default class HeaderMenu extends React.Component {
  static displayName = 'HeaderMenu';

  static propTypes = {
    /** Object which defines params of each link/item in menu. All other params will drop to `...rest` parameter and apply on the component. */
    items: arrayOf(
      shape({
        /** Item title. */
        title: string.isRequired,
        /** Item link. */
        href: string,
        /** When true, apply different styles to item. */
        isMain: bool,
        /** Sets active state / style on item. */
        isActive: bool,
        /** Icon props object, which shows next to the item title. */
        iconProps: shape({
          /** Icon name. */
          name: string,
          /** Icon size. */
          size: string,
        }),
        /** Object which defines params of each sub - link/item in parent item. All other params will drop to `...rest` parameter and apply on the component. */
        items: arrayOf(
          shape({
            /** Sub-item title. */
            title: string,
            /** Sub-item link. */
            href: string,
            /** Sets active state / style on sub-item. */
            isActive: bool,
          })
        ),
      })
    ).isRequired,
    /** Callback function which returns instance of HeaderMenu module */
    onMount: func,
  };

  static defaultProps = {
    onMount: instance => {}, // eslint-disable-line no-unused-vars
  };

  constructor(props) {
    super(props);
    this.renderItems = this.renderItems.bind(this);

    this.headerMenuRef = React.createRef();
  }

  componentDidMount() {
    if (this.headerMenuRef) {
      this.headerInstance = new HeaderMenuStatic(this.headerMenuRef.current);
    }

    this.props.onMount(this.headerInstance);
  }

  componentWillUnmount() {
    if (this.headerMenuRef) {
      this.headerInstance.destroy();
    }
  }

  renderItems() {
    const { items } = this.props;

    return items.map((item, itemIndex) => (
      <HeaderItem key={itemIndex /* eslint-disable-line */} item={item}>
        {item.items}
      </HeaderItem>
    ));
  }

  render() {
    const { children, className, items, onMount, ...other } = this.props;
    const classes = cx(CLASS_ROOT, 'no-mrg-bottom', className);

    const menuItems = this.renderItems();

    const moreItems = (
      <BarItem>
        <Dropdown
          data-header-dropdown
          style={{ zIndex: '201' } /* exlint-disable-line */}
          renderTrigger={(triggerProps, ref) => (
            <Button
              type="link-secondary"
              size="s"
              elemRef={ref}
              data-header-dropdown-trigger
              {...triggerProps}
            >
              <Icon name="more-horizontal" size="medium" />
            </Button>
          )}
        />
      </BarItem>
    );

    return (
      <Bar
        ref={this.headerMenuRef}
        aria-hidden="true"
        className={classes}
        data-header-menu=""
        {...other}
      >
        {menuItems}
        {moreItems}
      </Bar>
    );
  }
}
