import React from 'react';
import cx from 'classnames';

type Message = {
  /** Type of message */
  type?: 'error' | 'help' | 'warning';
} & JSX.IntrinsicElements['p'];

const Message = ({ className, children, type, ...other }: Message) => {
  const classes = cx({
    [`form-message`]: true,
    [`form-message--${type}`]: type,
    [`is-error`]: type === 'error',
    [`is-warning`]: type === 'warning',
    [`${className}`]: className,
  });

  return (
    <p className={classes} {...other}>
      {children}
    </p>
  );
};

export default Message;
