import React from 'react';
import cx from 'classnames';

import { useStatic, useUniqueId } from '../../utils/hooks';

import TooltipStatic from './Tooltip.static';

type Tooltip = {
  /** Classname  */
  className?: string;
  /** Tooltip dialog content */
  dialog?: string | object;
  /** Class names applied to dialog */
  dialogClassName?: string;
  /** Tooltip ID */
  id?: string;
  /** Use a div to wrap the tooltip trigger. Default is span. */
  isBlockElement?: boolean;
  /** Trigger */
  children: React.ReactNode;
};

const Tooltip = (props: Tooltip) => {
  const [tooltipRef] = useStatic(TooltipStatic);

  const wrapTrigger = (content: React.ReactNode, id: string) => {
    const { className, isBlockElement } = props;

    const Tag = isBlockElement ? 'div' : 'span';

    return (
      <Tag
        key="tooltipTrigger"
        tabIndex={0}
        className={className}
        aria-describedby={id}
        aria-owns={id} // helps NVDA speak the description text
        id={`${id}-trigger`}
      >
        {content}
      </Tag>
    );
  };

  const getTrigger = (id: string) => {
    const { children, dialog, isBlockElement, ...other } = props;

    if (React.Children.count(children) === 1) {
      if (typeof children === 'string') {
        return wrapTrigger(children, id);
      }

      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          tabIndex: 0,
          'aria-describedby': id,
          'aria-owns': id, // helps NVDA speak the description text
          ...other,
        });
      }

      return null;
    }

    return wrapTrigger(children, id);
  };

  const { dialog, dialogClassName, id, isBlockElement } = props;
  const dialogClasses = cx('tooltip', dialogClassName);

  const currentId = useUniqueId(id);

  const Tag = isBlockElement ? 'div' : 'span';

  return (
    <Tag>
      {getTrigger(currentId)}
      <span
        key="tooltipDialog"
        id={currentId}
        className={dialogClasses}
        role="tooltip"
        aria-hidden="true"
        ref={tooltipRef}
        data-tooltip
      >
        <span className="tooltip__inner">
          <span className="tooltip__arrow" />
          <span className="tooltip__content">{dialog}</span>
        </span>
      </span>
    </Tag>
  );
};

export default Tooltip;
