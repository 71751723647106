import React from 'react';

import Card from './Card';
import CardSection from './CardSection';

import './styles/style.scss';

export default Card;

export { CardSection };
export const CardImage = (props: Omit<Card, 'ref'>) => (
  <CardSection type="image" isFilling {...props} />
);
export const CardVideo = (props: Omit<Card, 'ref'>) => (
  <CardSection type="video" isFilling {...props} />
);
