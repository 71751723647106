import React from 'react';
import cx from 'classnames';

type Card = {
  /** Specifies if content can overflow the card boundaries */
  hasOverflow?: boolean;
  /** Card component href. Passing `node` can be handy for example with `react-router` `<Link />` component */
  href?: string | React.ReactNode;
  /** Adds border to whole card */
  isBordered?: boolean;
} & JSX.IntrinsicElements['div'];

const Card = ({
  children,
  className,
  isBordered,
  hasOverflow = true,
  href,
  ...other
}: Card) => {
  const classes = cx({
    [`card`]: true,
    [`card--disable-overflow`]: hasOverflow === false,
    [`card--bordered`]: isBordered,
    [`card--clickable`]: href,
    [`${className}`]: className,
  });

  let returnLink;
  if (href) {
    if (typeof href === 'string') {
      returnLink = <a href={href} />; // eslint-disable-line jsx-a11y/anchor-has-content
    } else {
      returnLink = href;
    }
  }

  return (
    <div className={classes} {...other}>
      {returnLink} {children}
    </div>
  );
};

export default Card;
