export const fontSize = 16;
export const lineHeight = 1.5;
export const fontFamily = `'DoveraSans', sans-serif`;
export const fontFamilySecondary = `'Amalia', serif`;
export const fontWeight = {
  normal: 400,
  semiBold: 500,
  bold: 700,
} as const;

export const getRem = (size: number, base = fontSize) => {
  if (isNaN(size)) {
    throw new Error(`Size ${size} is not a number`);
  }

  if (isNaN(base)) {
    throw new Error(`Base ${base} is not a number`);
  }

  return `${size / base}rem`;
};
