import React from 'react';
import cx from 'classnames';

import BoxGrid from './BoxGrid';
import BoxBar from './BoxBar';
import BoxFeedback from './BoxFeedback';

/*

  TODO:
    - create proper Box* components and make Box wrapper more opaque

*/

type Box = {
  /** Box background color */
  bg?: 'white';
  hasBorder?: boolean;
  isSwitched?: boolean;
} & (
  | ({ type: 'feedback' } & BoxFeedback)
  | ({ type: 'header' } & BoxGrid)
  | ({ type: 'feature' } & BoxGrid)
  | ({ type: 'action' } & BoxBar)
  | ({ type: 'support' } & BoxBar)
);

const Box = ({ bg, className, hasBorder, isSwitched, type, ...other }: Box) => {
  const classes = cx({
    [`box-${type}`]: true,
    [`box`]: true,
    [`box--${bg}`]: bg,
    [`box--border`]: hasBorder,
    [`box--switched`]: isSwitched,
    [`${className}`]: className,
  });

  if (type === 'feedback') {
    return <BoxFeedback className={classes} />;
  }

  if (type === 'header') {
    return <BoxGrid className={classes} {...other} />;
  }

  if (type === 'feature') {
    const { actionProps, ...otherFeatureBox } = other as BoxGrid;
    return (
      <BoxGrid
        className={classes}
        isSwitched={isSwitched}
        isVerticallyCentered
        renderTitle={({ title }) => <h3 className="h4 mb-small">{title}</h3>}
        actionProps={{ type: 'secondary', ...actionProps }}
        {...otherFeatureBox}
      />
    );
  }

  if (type === 'action') {
    const { actionProps, ...otherActionBox } = other as BoxBar;
    return (
      <BoxBar
        className={classes}
        layout="vertical"
        actionProps={{ type: 'prepoistit', ...actionProps }}
        {...otherActionBox}
      />
    );
  }

  if (type === 'support') {
    const { children, ...otherSupportBox } = other;
    return (
      <BoxBar className={classes} canWrap {...otherSupportBox}>
        <p>{children}</p>
      </BoxBar>
    );
  }

  // fallback
  return null;
};

export default Box;
