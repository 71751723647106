import Popper, { PopperOptions } from 'popper.js';

import { keyCodes } from '../../utils';

interface TooltipElement extends HTMLElement {
  DDL_Tooltip?: Tooltip;
}

const defaultConfig = {
  rootContainerSelector: '#root-tooltips',
  popperOptions: {
    placement: 'top',
    removeOnDestroy: true,
    modifiers: {
      arrow: {
        element: '.tooltip__arrow',
      },
      // this fixes overflowing tooltip outside of viewport
      setWidth: {
        enabled: true,
        order: 840,
        fn(data) {
          data.styles.maxWidth = '97%';
          return data;
        },
      },
    },
  } as PopperOptions,
};

type Config = {
  rootContainerSelector?: string;
  popperOptions?: PopperOptions;
};

export default class Tooltip {
  element: TooltipElement;
  config: Config;
  trigger: TooltipElement | null = null;
  rootContainer: HTMLElement | null = null;
  instance: Popper | null = null;

  constructor(element: TooltipElement, config: Config = {}) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };
    this.config.popperOptions = {
      ...defaultConfig.popperOptions,
      ...(config.popperOptions ?? {}),
    };

    this.init();
  }

  init() {
    this.element.DDL_Tooltip = this;

    const triggerId = this.element.getAttribute('id');

    if (triggerId === null) {
      console.warn(`Tooltip element does not have an 'id' attribute.`);
      return;
    }

    const tooltipId = triggerId.replace('-trigger', '');

    this.trigger = document.querySelector(`[aria-describedby=${tooltipId}]`);

    if (!this.trigger) {
      console.warn(
        `\`tooltip trigger\` element is not defined and the tooltip cannot be attached. Please provide an element with [aria-describedby=${triggerId}]`
      );
      return;
    }

    // Storing DDL_Tooltip is deprecated and will be removed in next major version
    this.trigger.DDL_Tooltip = this;

    this.rootContainer = document.querySelector(
      this.config.rootContainerSelector!
    );

    if (this.rootContainer) {
      this.rootContainer.appendChild(this.element);
    } else {
      console.warn(
        `\`rootContainer\` element is not defined. Tooltips will be placed inside content which can affect positioning. Please provide \`rootContainer\` element (should be placed outside of the main content, usually at the end of <body /> tag)`
      );
    }

    this.instance = new Popper(
      this.trigger,
      this.element,
      this.config.popperOptions
    );

    this.trigger.addEventListener('mouseenter', this.handleVisibility);
    this.trigger.addEventListener('mouseleave', this.handleVisibility);
    this.trigger.addEventListener('focus', this.handleVisibility);
    this.trigger.addEventListener('blur', this.handleVisibility);
  }

  static getInstance(el: TooltipElement) {
    return el && el.DDL_Tooltip ? el.DDL_Tooltip : null;
  }

  update() {
    this.instance?.update();
  }

  destroy() {
    this.trigger?.removeEventListener('mouseenter', this.handleVisibility);
    this.trigger?.removeEventListener('mouseleave', this.handleVisibility);
    this.trigger?.removeEventListener('focus', this.handleVisibility);
    this.trigger?.removeEventListener('blur', this.handleVisibility);

    this.instance?.destroy();
  }

  handleVisibility = (e: Event) => {
    if (e.type === 'mouseenter' || e.type === 'focus') {
      this.show();
    }

    if (e.type === 'blur') {
      this.hide();
    }

    if (e.type === 'mouseleave') {
      const shouldClose = this.shouldClose(e);

      if (shouldClose) {
        this.hide();
      }
    }
  };

  static getRelatedTarget(e: Event): HTMLElement {
    // @ts-ignore
    return e.relatedreference || e.toElement || e.relatedTarget;
  }

  shouldClose(e: Event) {
    const mouseLeftTo = Tooltip.getRelatedTarget(e);

    const callback = (e2: Event) => {
      const mouseLeftTo2 = Tooltip.getRelatedTarget(e2);

      this.element.removeEventListener('mouseleave', callback);

      if (!this.trigger?.contains(mouseLeftTo2)) {
        this.hide();
      }
    };

    if (this.element.contains(mouseLeftTo)) {
      this.element.addEventListener('mouseleave', callback);
      return false;
    }

    return true;
  }

  onKeydown = (e: KeyboardEvent) => {
    if (e.which === keyCodes.ESC) {
      this.hide();
    }
  };

  show() {
    this.element.setAttribute('aria-hidden', 'false');
    document.addEventListener('keydown', this.onKeydown);
    this.instance?.scheduleUpdate();
  }

  hide() {
    this.element.setAttribute('aria-hidden', 'true');
    document.removeEventListener('keydown', this.onKeydown);
  }
}
