import { keyCodes } from './constants';

type Config = { direction: 'horizontal' | 'vertical' };

export class RovingTabindex {
  isActive = false;
  config: Config;
  elems: any[];

  constructor(
    elems: any[],
    config = {
      direction: 'horizontal',
    }
  ) {
    this.elems = elems;
    this.config = config as Config;
  }

  init() {
    document.addEventListener('keydown', this.onKeydown);
    this.isActive = true;
  }

  destroy() {
    document.removeEventListener('keydown', this.onKeydown);
    this.isActive = false;
  }

  update(elems: any[]) {
    this.elems = elems;
  }

  onKeydown = (e: KeyboardEvent) => {
    const activeElemIndex = this.elems.indexOf(document.activeElement);
    const { direction } = this.config;
    const { ARROWUP, ARROWDOWN, ARROWLEFT, ARROWRIGHT, TAB } = keyCodes;
    const key = e.which;

    if ((key === ARROWUP || key === ARROWDOWN) && direction === 'horizontal') {
      return;
    }

    if ((key === ARROWLEFT || key === ARROWRIGHT) && direction === 'vertical') {
      return;
    }

    if (key === ARROWDOWN || key === ARROWRIGHT) {
      e.preventDefault();
      if (activeElemIndex >= 0 && activeElemIndex < this.elems.length - 1) {
        this.elems[activeElemIndex + 1].focus();
      } else {
        this.elems[0].focus();
      }
    }

    if (key === ARROWUP || key === ARROWLEFT) {
      e.preventDefault();
      if (activeElemIndex >= 1) {
        this.elems[activeElemIndex - 1].focus();
      } else {
        this.elems[this.elems.length - 1].focus();
      }
    }

    if (key === TAB) {
      this.destroy();
    }
  };
}
