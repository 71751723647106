import React, { Component } from 'react';
import { func, object } from 'prop-types';
import cx from 'classnames';

import Toggle from '../../scripts/modules/Toggle';

import { Bar, BarItem, Button, Icon, Input } from '..';

const CLASS_ROOT = 'header-mobile__search';

export default class HeaderMobileSearch extends Component {
  static displayName = 'HeaderMobileSearch';

  static propTypes = {
    /** Props to sets `<Input />` element's attributes. */
    inputProps: object,
    /** Submit callback function. */
    onSubmit: func,
    /** Custom input renderer. Passes `inputProps` as function parameter. */
    renderInput: func,
    /** Custom submit renderer. Passes `submitProps` as function parameter. */
    renderSubmit: func,
    /** Props to sets `<Button submit />` element's attributes. */
    submitProps: object,
  };

  static defautProps = {
    onSubmit: e => {}, // eslint-disable-line no-unused-vars
    inputProps: {
      placeholder: 'Vyhľadaj',
      'data-header-search-input-mobile': '',
      size: 's',
      name: 'search',
    },
    submitProps: {
      submit: true,
      equal: true,
      size: 's',
    },
  };

  constructor(props) {
    super(props);
    this.searchIcon = React.createRef();
  }

  componentDidMount() {
    this.searchIconInstance = new Toggle(this.searchIcon.current);
  }

  componentWillUnmount() {
    this.searchIconInstance.destroy();
  }

  render() {
    const {
      children,
      className,
      inputProps,
      renderInput = () => {},
      submitProps,
      renderSubmit = () => {},
      onSubmit = () => {},
      ...other
    } = this.props;
    const classes = cx(CLASS_ROOT, className);

    const localInputProps = {
      ...HeaderMobileSearch.defautProps.inputProps,
      ...inputProps,
    };

    const renderedInput = renderInput(localInputProps) || (
      <Input {...localInputProps} />
    );

    const localSubmitProps = {
      ...HeaderMobileSearch.defautProps.submitProps,
      ...{ children: <Icon size="medium" name="search" /> },
      ...submitProps,
    };

    const renderedSubmit = renderSubmit(localSubmitProps) || (
      <Button {...localSubmitProps} />
    );

    const searchIcon = (
      <BarItem className="align-self-right">
        <Button
          type="link-secondary"
          elemRef={this.searchIcon}
          size="xs"
          className="header-mobile__search-icon no-pad"
          data-toggle={JSON.stringify([
            {
              target: '.header-mobile__search-icon',
              attribute: 'icon',
              value: '/sprite.svg#close',
            },
            {
              target: '.header-mobile__field',
              attribute: 'class',
              value: 'hide',
            },
            {
              target: '.header-mobile__field',
              attribute: 'aria-hidden',
            },
            {
              target: '[data-header-search-input-mobile]',
              action: 'focus',
            },
          ])}
        >
          <Icon size="medium" name="search" />
        </Button>
      </BarItem>
    );

    const searchField = (
      <BarItem
        isFilling
        className="header-mobile__field hide"
        aria-hidden="true"
      >
        <form
          role="search"
          onSubmit={e => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <Bar className="no-mrg-bottom" space="small">
            <BarItem isFilling>{renderedInput}</BarItem>
            <BarItem>{renderedSubmit}</BarItem>
          </Bar>
        </form>
      </BarItem>
    );

    return (
      <Bar className={classes} space="small" {...other}>
        {searchField}
        {searchIcon}
      </Bar>
    );
  }
}
