import React from 'react';
import cx from 'classnames';

import PageSection from './PageSection';

import { Image } from '..';

import './styles/style.scss';

type PageSectionImage = Image;

export const PageSectionImage = ({ className, ...other }: PageSectionImage) => (
  <Image className={cx(className, 'page-section__illustration')} {...other} />
);

export default PageSection;
