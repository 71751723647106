import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'custom-event-polyfill';
import 'svgxuse';
import 'classlist.js';
import 'focus-visible';
import 'form-association-polyfill';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

/* eslint-disable */
if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest = function (s: string) {
    // @ts-ignore
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i,
      el = this;
    do {
      i = matches.length;
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement!));
    return el;
  };
}

/* eslint-disable */
if (!('replace' in document.createElement('_').classList)) {
  DOMTokenList.prototype.replace = function (token, replacement_token) {
    var tokens = this.toString().split(' '),
      index = tokens.indexOf(token + '');
    if (~index) {
      tokens = tokens.slice(index);
      this.remove.apply(this, tokens);
      this.add(replacement_token);
      this.add.apply(this, tokens.slice(1));
    }
  };
}
/* eslint-enable */
