import React, { useState, useMemo } from 'react';
import cx from 'classnames';

import { Code } from '..';

import { elementToHtml, elementToJsx } from './utils';

import './style.scss';

type CodePreview = {
  children: React.ReactElement;
};

const CodePreview = ({ children }: CodePreview) => {
  const jsxContent = useMemo(
    () => <Code language="jsx">{elementToJsx(children)}</Code>,
    [children]
  );

  const htmlContent = useMemo(
    () => <Code language="jsx">{elementToHtml(children)}</Code>,
    [children]
  );

  const [expandedCode, setExpandedCode] = useState(false);
  const [sourceType, setSourceType] = useState<'jsx' | 'html'>('jsx');

  const buttons = useMemo(
    () => (
      <>
        <button
          className={cx({
            'tab-list__tab tab tab--link': true,
            'is-active': sourceType === 'jsx',
          })}
          onClick={() => setSourceType('jsx')}
        >
          JSX
        </button>
        <button
          className={cx({
            'tab-list__tab tab tab--link': true,
            'is-active': sourceType === 'html',
          })}
          onClick={() => setSourceType('html')}
        >
          HTML
        </button>
      </>
    ),
    [sourceType]
  );

  return (
    <div className="Preview">
      <div className="Preview--content">{children}</div>
      <div className="Preview--toolbar">
        {expandedCode ? buttons : null}
        <div className="separator" />
        <button
          className="tab-list__tab tab tab--link"
          onClick={() => setExpandedCode(!expandedCode)}
        >
          {expandedCode ? 'Zabaliť' : 'Rozbaliť'}
        </button>
      </div>
      <div
        className={cx({
          'Preview--code': true,
          'Preview--code_expanded': expandedCode,
        })}
        onClick={() => setExpandedCode(true)}
      >
        {sourceType === 'jsx' ? jsxContent : htmlContent}
      </div>
    </div>
  );
};

export default CodePreview;
