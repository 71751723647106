import React from 'react';
import cx from 'classnames';

type Container = {
  /** Types of container (widths) */
  type?: 'narrow';
} & JSX.IntrinsicElements['div'];

const Container = ({ className, type, ...other }: Container) => {
  const classes = cx({
    [`container`]: true,
    [`container--${type}`]: type,
    [`${className}`]: className,
  });

  return <div className={classes} {...other} />;
};

export default Container;
