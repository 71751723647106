interface AddonElement extends HTMLElement {
  DDL_Addon?: Addon;
}

export default class Addon {
  element: AddonElement;

  constructor(element: AddonElement) {
    this.element = element;
    this.element.DDL_Addon = this;

    this.init();
  }

  init() {
    this.element.addEventListener('click', this.handleClick);
  }

  static getInstance(el: AddonElement) {
    return el && el.DDL_Addon ? el.DDL_Addon : null;
  }

  update() {
    this.destroy();
    this.init();
  }

  destroy() {
    this.element.removeEventListener('click', this.handleClick);
  }

  handleClick = (e: MouseEvent) => {
    if (e.target === null) {
      return;
    }

    const target = e.target as HTMLElement;
    const input = target.closest('.form-control')?.querySelector('input');
    const targetIsButton = target.tagName === 'BUTTON';
    const targetHasButton = target.querySelector('.btn');

    if (input && !targetIsButton && !targetHasButton) {
      input.focus();
    }
  };
}
