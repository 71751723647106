import React from 'react';

import './styles/style.scss';

import Box from './Box';
import BoxGrid from './BoxGrid';
import BoxBar from './BoxBar';
import BoxFeedbackOriginal from './BoxFeedback';

export const BoxHeader = (props: BoxGrid) => <Box type="header" {...props} />;

export const BoxFeature = (props: BoxGrid) => <Box type="feature" {...props} />;

export const BoxAction = (props: BoxBar) => <Box type="action" {...props} />;

export const BoxSupport = (props: BoxBar) => (
  <Box type="support" bg="white" hasBorder {...props} />
);

export const BoxFeedback = (props: BoxFeedbackOriginal) => (
  <Box type="feedback" {...props} />
);

export default Box;
