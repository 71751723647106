import React from 'react';
import cx from 'classnames';

// @ts-ignore
import logo from '../../assets/logo/logo.svg';
// @ts-ignore
import logoMobile from '../../assets/logo/logo-small.svg';

import HeaderMobile from './HeaderMobile';
import HeaderDesktop from './HeaderDesktop';
import HeaderLogo from './HeaderLogo';

type MobileProps = { isMobile?: boolean };

type Header = {
  /** Render function for actionArea section. */
  actionArea?: (props: MobileProps) => React.ReactNode;
  /** Render function for logoArea section. Dovera logo is displayed by default and `false` can be passed to do not show this area at all. */
  logoArea?: boolean | ((props: MobileProps) => React.ReactNode);
  /** Render function for menuArea section. */
  menuArea?: (props: MobileProps) => React.ReactNode;
  /** Render function for searchArea section. */
  searchArea?: (props: MobileProps) => React.ReactNode;
  /** Render function for title section. Currently displaying in mobile header */
  titleArea?: (props: MobileProps) => React.ReactNode;
  /** Render function for userArea section. */
  userArea?: (props: MobileProps) => React.ReactNode;
} & JSX.IntrinsicElements['header'];

const Header = ({
  className,
  logoArea,
  menuArea = () => null,
  searchArea = () => null,
  titleArea = () => null,
  userArea = () => null,
  actionArea = () => null,
  ...other
}: Header) => {
  let logoAreaToRender;
  let logoAreaToRenderMobile;

  if (typeof logoArea === 'undefined') {
    logoAreaToRender = <HeaderLogo src={logo} />;
    logoAreaToRenderMobile = <HeaderLogo src={logoMobile} />;
  }

  if (typeof logoArea === 'function') {
    logoAreaToRender = logoArea({ isMobile: false });
    logoAreaToRenderMobile = logoArea({ isMobile: true });
  }

  const menuAreaToRender = menuArea({ isMobile: false });
  const searchAreaToRender = searchArea({ isMobile: false });
  const userAreaToRender = userArea({ isMobile: false });
  const actionAreaToRender = actionArea({ isMobile: false });

  const menuAreaToRenderMobile = menuArea({ isMobile: true });
  const searchAreaToRenderMobile = searchArea({ isMobile: true });
  const titleAreaToRenderMobile = titleArea({ isMobile: true });
  const userAreaToRenderMobile = userArea({ isMobile: true });
  const actionAreaToRenderMobile = actionArea({ isMobile: true });

  return (
    <header className={cx('header', className)} {...other}>
      <HeaderDesktop
        className="show-m"
        logoArea={logoAreaToRender}
        menuArea={menuAreaToRender}
        searchArea={searchAreaToRender}
        userArea={userAreaToRender}
        actionArea={actionAreaToRender}
      />
      <HeaderMobile
        className="hide-m"
        logoArea={logoAreaToRenderMobile}
        menuArea={menuAreaToRenderMobile}
        searchArea={searchAreaToRenderMobile}
        titleArea={titleAreaToRenderMobile}
        userArea={userAreaToRenderMobile}
        actionArea={actionAreaToRenderMobile}
      />
    </header>
  );
};

export default Header;
