import React from 'react';
import cx from 'classnames';

import { Button, Grid, GridCol, Image } from '..';

type GridBox = {
  /** Props passed to action. Action is button by default. */
  actionProps?: Button;
  /** Props passed to `<Image />`. */
  imageProps?: Image;
  /** If true image will come first - before title and content. */
  isSwitched?: boolean;
  /** Specifies vertical centring of image and title with content. */
  isVerticallyCentered?: boolean;
  /** Custom action renderer. */
  renderAction?: ({
    actionProps,
    isSwitched,
    isVerticallyCentered,
  }: {
    actionProps?: Button;
    isSwitched?: boolean;
    isVerticallyCentered?: boolean;
  }) => React.ReactNode;
  /** Custom title renderer. */
  renderTitle?: ({
    title,
    isSwitched,
    isVerticallyCentered,
  }: {
    title: React.ReactNode;
    isSwitched?: boolean;
    isVerticallyCentered?: boolean;
  }) => React.ReactNode;
  /** Title text */
  title?: React.ReactNode;
} & Omit<JSX.IntrinsicElements['article'], 'title'>;

const GridBox = ({
  actionProps = {},
  isSwitched,
  isVerticallyCentered,
  children,
  className,
  imageProps,
  renderAction,
  renderTitle,
  title,
  ...other
}: GridBox) => {
  const titleToRender = (renderTitle &&
    renderTitle({ title, isSwitched, isVerticallyCentered })) || (
    <h1 className="h2 mb-small">{title}</h1>
  );

  const childrenToRender =
    children &&
    (typeof children === 'string' ? (
      <p className="mb-small">{children}</p>
    ) : (
      children
    ));

  const modifiedActionProps = { ...actionProps };

  if (actionProps) {
    modifiedActionProps.className = cx(
      actionProps.className,
      'align-self-center align-self-left--m'
    );
  }

  const actionToRender =
    (renderAction &&
      renderAction({
        actionProps: modifiedActionProps,
        isSwitched,
        isVerticallyCentered,
      })) ||
    (modifiedActionProps && modifiedActionProps.children && (
      <Button {...modifiedActionProps} />
    ));

  const switched = (
    <Grid>
      {imageProps && (
        <GridCol size={{ m: 5 }} className="box__img">
          <Image role="presentation" height="large" {...imageProps} />
        </GridCol>
      )}
      <GridCol
        size={{ m: imageProps ? 6 : 12 }}
        offset={{ m: 1 }}
        className={cx(
          'box__content',
          isVerticallyCentered && 'd-flex-column align-items-center'
        )}
      >
        {titleToRender}
        {childrenToRender}
        {actionToRender}
      </GridCol>
    </Grid>
  );

  return (
    <article className={className} {...other}>
      {isSwitched ? (
        switched
      ) : (
        <Grid>
          <GridCol
            size={{ m: imageProps ? 6 : 12 }}
            className={cx(
              'box__content',
              isVerticallyCentered && 'd-flex-column align-items-center'
            )}
          >
            {titleToRender}
            {childrenToRender}
            {actionToRender}
          </GridCol>
          {imageProps && (
            <GridCol size={{ m: 5 }} offset={{ m: 1 }} className="box__img">
              <Image role="presentation" height="large" {...imageProps} />
            </GridCol>
          )}
        </Grid>
      )}
    </article>
  );
};

export default GridBox;
