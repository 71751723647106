import React from 'react';
import cx from 'classnames';

type ListItemDescription = {
  /** Sets background for whole list item */
  background?: 'secondary';
} & JSX.IntrinsicElements['div'];

const ListItemDescription = ({
  className,
  children,
  background,
  ...other
}: ListItemDescription) => {
  const classes = cx({
    [`list__item-description`]: true,
    [`list__item-description--${background}`]: background,
    [`${className}`]: className,
  });

  return (
    <div className={classes} {...other}>
      {children}
    </div>
  );
};

export default ListItemDescription;
