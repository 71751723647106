import React from 'react';
import cx from 'classnames';

import { genResponsiveClasses } from '../../utils/';
import { ResponsiveMap } from '../../utils/';

/*

  TODO:
    - make `genResponsiveClasses` more transparent
    - rename `type` prop to `variant`

*/

type Direction = 'horizontal' | 'vertical';
type Type = 'group' | 'layout';
type ButtonLayouts = {
  /** Layout direction. Stack can take enum value or media object */
  direction?: ResponsiveMap<Direction>;
  /** Preserve width of all child elements */
  preserveWidth?: boolean;
  /** ButtonLayouts component is used for creating ButtonGroup and also ButtonLayout */
  type: Type;
} & JSX.IntrinsicElements['div'];

const ButtonLayouts = ({
  className,
  direction = 'vertical',
  preserveWidth,
  type,
  ...other
}: ButtonLayouts) => {
  let ButtonLayoutDirection = direction;
  if (typeof direction === 'object') {
    ButtonLayoutDirection = { ...{ xs: 'vertical' }, ...direction };
  }

  const classes = cx({
    [`btn-${type}`]: true,
    ...genResponsiveClasses(`btn-${type}`, ButtonLayoutDirection),
    [`btn-layout--preserve`]: preserveWidth,
    [`${className}`]: className,
  });

  return <div className={classes} {...other} />;
};

export default ButtonLayouts;
