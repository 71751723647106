import React from 'react';

import './styles/style.scss';

import RadioCheck from './RadioCheck';

export const Radio = (props: Omit<RadioCheck, 'type' | 'indeterminate'>) => (
  <RadioCheck type="radio" {...props} />
);

type Checkbox = Omit<RadioCheck, 'type'>;

export const Checkbox = (props: Checkbox) => (
  <RadioCheck type="checkbox" {...props} />
);
