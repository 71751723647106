import React from 'react';

import Image from '.';

type Size = 'small' | 'medium' | 'large';
type ImageAvatar = {
  size: Size;
} & Image;

function getAvatarSize(size: Size) {
  switch (size) {
    case 'small': {
      return 'xxsmall';
    }
    case 'medium': {
      return 'xsmall';
    }
    case 'large': {
      return 'small';
    }
    default: {
      return 'xsmall';
    }
  }
}

const ImageAvatar = ({ size, ...props }: ImageAvatar) => {
  const imageSize = getAvatarSize(size);

  return (
    <Image
      isCircle
      type="avatar"
      width={imageSize}
      height={imageSize}
      {...props}
    />
  );
};
export default ImageAvatar;
