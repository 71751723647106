import React from 'react';
import cx from 'classnames';

import { convertSizeToWord } from '../../utils';

/*

  TODO:
    - make `Button` polymorphic for `tag` or `as` prop
    - remove `ReactNode` from `href` prop
    - remove/refactor `type` prop:
      - type Variant = 'contained' | 'outlined' | 'plain';
        - 'contained' = solid fill color
        - 'outlined' = only border, transparent fill
        - 'plain' = no border, no fill (Link-like)
      - type Color = 'primary' | 'secondary' | 'prepoistit' | 'ep' | 'warning' | 'error';
        - should be imported
    - change Size type to long names, e.g. xs -> x-small
    - remove Shape?
    - remove `submit` prop (`type` prop should fallthrough)
    - refactor `elemRef` to proper ref-forwarding
    - remove props that override native props (`type`)
      - remove Merge<M,N> trick
*/

type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

type Size = 'xs' | 's' | 'l';
type Shape = 'sharp' | 'round';
type Type =
  | 'secondary'
  | 'secondary-inverted'
  | 'link'
  | 'link-secondary'
  | 'destructive'
  | 'prepoistit';

type Button = Merge<JSX.IntrinsicElements["button"], {
  /** Button with same width as height */
  equal?: boolean;
  /** Link with href attribute rendered visually as button. Passing `node` can be handy for example with `react-router` `<Link />` component */
  href?: string | React.ReactNode;
  /** Active state */
  isActive?: boolean;
  /** Disabled state */
  isDisabled?: boolean;
  /** Button size */
  size?: Size;
  /** Button shape */
  shape?: Shape;
  /** Button(input) with `type="submit"` */
  submit?: boolean;
  /** HTML tag in which is button rendered */
  tag?: string;
  /** Visual type of button */
  type?: Type;
  /** Forwarded DOM element ref */
  elemRef?: React.Ref<HTMLButtonElement>;
}>;

const Button = ({
  className,
  children,
  equal,
  href,
  isActive,
  isDisabled,
  size,
  shape,
  submit,
  tag = 'button',
  type,
  elemRef = undefined,
  ...other
}: Button) => {
  let Tag: string | React.ReactNode = tag;
  let buttonType: string | null = submit ? 'submit' : 'button';
  let buttonIsDisabled = isDisabled;

  if (href) {
    Tag = typeof href === 'string' ? 'a' : href;
    buttonType = null;
  }

  if (Tag === 'a') {
    buttonType = null;
  }

  if (isDisabled && Tag === 'a') {
    buttonIsDisabled = undefined;
  }

  const classes = cx({
    [`btn`]: true,
    [`btn--equal`]: equal,
    [`btn--${convertSizeToWord(size)}`]: size,
    [`btn--${shape}`]: shape,
    [`btn--${type}`]: type,
    [`is-active`]: isActive,
    [`is-disabled`]: Tag === 'a' && isDisabled,
    [`${className}`]: className,
  });

  const props = {
    disabled: buttonIsDisabled,
    href: typeof href === 'string' ? href : undefined,
    type: !href ? buttonType : null,
    className: classes,
    ref: elemRef,
    ...other,
  };

  return typeof Tag === 'string' ? (
    // @ts-ignore
    <Tag {...props}>{children}</Tag>
  ) : (
    // @ts-ignore
    React.cloneElement(Tag, props, children)
  );
};

export default Button;
