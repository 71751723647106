import { breakpoints } from '../../../utils/tokens';

const defaultConfig = {
  parentSelector: '.input-addons',
  addonSelector: '.input-addons__item',
};

export default class Input {
  element: HTMLElement;
  config: {
    parentSelector: string;
    addonSelector: string;
  };
  parentElement: HTMLElement | null = null;

  constructor(element: HTMLElement, config: object) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.init();

    // @ts-ignore
    this.element.DDL_Input = this;
  }

  init() {
    this.parentElement = this.element.closest(this.config.parentSelector);
    if (this.parentElement) {
      this.handleColorChangeByBreakpoint();
      window.addEventListener('resize', this.handleColorChangeByBreakpoint);
    }
  }

  static getInstance(el: HTMLElement) {
    // @ts-ignore
    return el && el.DDL_Input ? el.DDL_Input : null;
  }

  destroy() {
    window.removeEventListener('resize', this.handleColorChangeByBreakpoint);
  }

  update() {
    this.destroy();
    this.init();
  }

  handleColorChangeByBreakpoint = () => {
    const isBiggerThanBreakpoint = window.innerWidth > breakpoints.m;
    const addonElement: HTMLElement | null =
      this.parentElement?.querySelector(this.config.addonSelector) ?? null;
    let color;

    if (isBiggerThanBreakpoint) {
      color = '';

      if (this.getInputStateClassName()) {
        this.parentElement?.classList.add(this.getInputStateClassName());
      }
    } else {
      color = 'transparent';
    }

    if (addonElement) {
      addonElement.style.backgroundColor = color;
    }
  };

  getInputStateClassName = () => {
    const classes = this.element.classList;
    if (classes.contains('is-error')) {
      return 'is-error';
    }
    if (classes.contains('is-warning')) {
      return 'is-warning';
    }
    if (classes.contains('is-success')) {
      return 'is-success';
    }
    return '';
  };
}
