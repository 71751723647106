import React, { Children } from 'react';
import cx from 'classnames';

import { convertSizeToWord } from '../../../../utils/';
import { useStatic } from '../../../../utils/hooks';

import Addon from './Addon';
import AddonsStatic from './Addons.static';

type Addons = {
  /** Whether addons should be inside or outside */
  addonsInside?: boolean;
  /** Addons on the left/leading side */
  left?: React.ReactNode;
  /** If addons layout should break on smaller viewports. */
  responsive?: boolean;
  /** Addons on the right/trailing side */
  right?: React.ReactNode;
  /** Size of the addon – small or large */
  size?: 's' | 'l';
  /** */
  type?: string;
} & JSX.IntrinsicElements['div'];

const getAddons = (addons: React.ReactNode) => {
  return Children.map(addons, (element) => {
    const isValid = React.isValidElement(element);
    return isValid ? <Addon>{element}</Addon> : null;
  });
};

const Addons = (props: Addons) => {
  const [addonRef] = useStatic(AddonsStatic);

  const {
    addonsInside,
    responsive,
    children,
    className,
    left,
    right,
    size,
    type,
    ...other
  } = props;

  const classes = cx({
    [`input-addons`]: true,
    [`input-addons--inside`]: addonsInside,
    [`input-addons--responsive`]: responsive,
    [`input-addons--${convertSizeToWord(size)}`]: size,
    [`input-addons--${type}`]: type,
    [`${className}`]: className,
  });

  return (
    <div className={classes} {...other} ref={addonRef} data-addons>
      {left && getAddons(left)}
      {children}
      {right && getAddons(right)}
    </div>
  );
};

export default Addons;
