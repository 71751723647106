import { RovingTabindex } from '../../utils';
import { TRIGGER_EVT } from '../../scripts/modules/Toggle';

const defaultConfig = {
  itemSelector: '.accordion__header',
  onChange: ({ index, typeOfChange, element }, e) => {}, // eslint-disable-line no-unused-vars
};

export default class Accordion {
  constructor(element, config) {
    this.element = element;

    this.config = { ...defaultConfig, ...config };

    this.handleItemFocus = this.handleItemFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateItemsHeight = this.updateItemsHeight.bind(this);
    Accordion.getChildrenHeight = Accordion.getChildrenHeight.bind(this);

    this.transitionStartCallbackFn = this.transitionStartCallbackFn.bind(this);
    this.transitionEndCallbackFn = this.transitionEndCallbackFn.bind(this);

    this.transitionStartBodyCallbackFn = this.transitionStartBodyCallbackFn.bind(
      this
    );
    this.transitionEndBodyCallbackFn = this.transitionEndBodyCallbackFn.bind(
      this
    );

    this.currentMode = this.element.dataset.accordionMode;

    this.lastActiveIndex = null;

    this.init();

    return this;
  }

  init() {
    this.items = Array.from(
      this.element.querySelectorAll(this.config.itemSelector)
    );

    if (!this.rovingTabindex) {
      this.rovingTabindex = new RovingTabindex(this.items, {
        direction: 'vertical',
      });
    } else {
      this.rovingTabindex.update(this.items);
    }

    this.items.forEach(item => {
      item.addEventListener('focus', this.handleItemFocus);
      item.addEventListener(TRIGGER_EVT, this.handleChange);
    });

    this.updateItemsHeight();
    window.addEventListener('resize', this.updateItemsHeight);

    // create mutation observer of accordion itmes
    this.itemsObserver = new MutationObserver(mutationList => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          // update height of items on attribute (eg. class) mutation which happens on programatical activation/deactivation of item
          this.updateItemsHeight();
        }
      }
    });

    this.accordionItems = Array.from(this.items).map(item =>
      item.closest('.accordion__item')
    );

    this.accordionBodies = this.accordionItems.map(item =>
      item.querySelector('.accordion__body')
    );

    this.accordionBodies.forEach(item => {
      item.addEventListener(
        'transitionstart',
        this.transitionStartBodyCallbackFn
      );
      item.addEventListener('transitionend', this.transitionEndBodyCallbackFn);
    });

    this.accordionItems.forEach(item => {
      this.itemsObserver.observe(item, {
        attributes: true,
        attributeFilter: ['class'],
        childList: true,
        subtree: true,
      });
    });
  }

  handleItemFocus() {
    if (!this.rovingTabindex.isActive) {
      this.rovingTabindex.init();
    }
  }

  updateItemsHeight() {
    // eslint-disable-next-line array-callback-return
    Array.from(this.element.querySelectorAll('.accordion__body')).forEach(
      item => {
        if (item.classList.contains('is-active')) {
          item.style.maxHeight = `${Accordion.getChildrenHeight(item)}px`;
        } else {
          item.style.removeProperty('max-height');
        }
      }
    );
  }

  transitionStartBodyCallbackFn() {
    this.accordionItems.forEach(item => {
      const accordionBody = item.querySelector('.accordion__body');

      if (accordionBody.classList.contains('can-overflow')) {
        accordionBody.classList.remove('can-overflow');
      }
    });
  }

  transitionEndBodyCallbackFn() {
    this.accordionItems.forEach(item => {
      const accordionBody = item.querySelector('.accordion__body');

      if (
        !accordionBody.classList.contains('can-overflow') &&
        accordionBody.classList.contains('is-active')
      ) {
        accordionBody.classList.add('can-overflow');
      }
    });
  }

  transitionStartCallbackFn() {
    this.accordionItems.forEach(item => {
      const accordionBody = item.querySelector('.accordion__body');

      if (
        !accordionBody.classList.contains('is-active') &&
        accordionBody.classList.contains('can-overflow')
      ) {
        accordionBody.classList.remove('can-overflow');
      }
    });
  }

  transitionEndCallbackFn() {
    this.accordionItems.forEach(item => {
      const accordionBody = item.querySelector('.accordion__body');

      if (accordionBody.classList.contains('is-active')) {
        accordionBody.classList.add('can-overflow');
      }
    });
  }

  static getChildrenHeight(element) {
    return Array.from(element.children)
      .map(item => {
        const style = window.getComputedStyle(item);
        return (
          item.offsetHeight +
          parseFloat(style.marginTop) +
          parseFloat(style.marginBottom)
        );
      })
      .reduce((sum, currentValue) => sum + currentValue);
  }

  handleChange(e) {
    const clickedItem = e.currentTarget;
    let changeEvent = null;

    if (clickedItem) {
      const bodyElement = clickedItem
        .closest('.accordion__item')
        .querySelector('.accordion__body');
      const shouldOpen = bodyElement.classList.contains('is-active');

      if (shouldOpen) {
        changeEvent = 'open';
        bodyElement.style.maxHeight = `${Accordion.getChildrenHeight(
          bodyElement
        )}px`;
      } else {
        changeEvent = 'close';
        bodyElement.style.maxHeight = '0px';
      }
    }

    let clickedIndex = null;

    this.items.forEach((item, i) => {
      if (item === clickedItem) {
        clickedIndex = i;
      }
    });

    this.config.onChange(
      {
        index: clickedIndex,
        typeOfChange: changeEvent,
        element: clickedItem,
      },
      e
    );

    if (this.currentMode === 'single') {
      const previousItemIndex = Array.from(
        e.target.closest('.accordion').children
      ).indexOf(e.target.closest('.accordion__item'));

      const lastActiveElement = this.element.querySelectorAll(
        '.accordion__item'
      )[this.lastActiveIndex];

      let previousActiveItem = null;
      if (lastActiveElement) {
        previousActiveItem = lastActiveElement.querySelector(
          '.accordion__header'
        );
      }

      const previousActiveElement = e.target.closest('.accordion__item');

      if (lastActiveElement && previousActiveElement !== lastActiveElement) {
        lastActiveElement
          .querySelector('.accordion__body')
          .classList.remove('is-active');
        lastActiveElement.querySelector('.accordion__body').style.maxHeight =
          '0px';
      }

      if (lastActiveElement) {
        this.config.onChange(
          {
            index: this.lastActiveIndex,
            typeOfChange: 'close',
            element: previousActiveItem,
          },
          e
        );
      }

      this.lastActiveIndex = previousItemIndex;
    }
  }

  destroy() {
    this.rovingTabindex.destroy();

    this.items.forEach(item => {
      item.removeEventListener('focus', this.handleItemFocus);
      item.removeEventListener(TRIGGER_EVT, this.handleChange);
    });

    this.accordionBodies.forEach(item => {
      item.removeEventListener(
        'transitionstart',
        this.transitionStartBodyCallbackFn
      );
      item.removeEventListener(
        'transitionend',
        this.transitionEndBodyCallbackFn
      );
    });

    window.removeEventListener('resize', this.updateItemsHeight);

    this.itemsObserver.disconnect();
  }

  update() {
    this.destroy();
    this.init();
  }
}
