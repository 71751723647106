import React, { forwardRef } from 'react';
import cx from 'classnames';

import { composeRefs, useStatic } from '../../utils/hooks';

import CardSectionStatic from './CardSection.static';

/*

  TODO:
    - `CardSectionStatic` handles only logic related to `type="video"`,
      so it can be moved/renamed, and composition can be used instead
    - make background image via composition with `<Image />` instead
      of `background-image` CSS property

*/

type CardSection = {
  /** Background color */
  bg?: 'white' | 'secondary' | 'grey' | 'primary';
  /** Url becomes value of section's background-image CSS property */
  bgUrl?: string;
  /** CSS classes */
  className?: string;
  /** Background image is removed for sizes bigger than 768px */
  hasBgOnMobile?: boolean;
  /** Condensed card */
  isCondensed?: boolean;
  /** Content will fill available vertical space */
  isFilling?: boolean;
  /** Type of section */
  type?: 'content' | 'image' | 'video';
  /** Props to sets <source /> element's attributes */
  videoProps?: Video;
} & JSX.IntrinsicElements['div'];

const CardSection = forwardRef<HTMLDivElement, CardSection>(
  (
    {
      children,
      className,
      style,
      bg,
      isFilling,
      isCondensed,
      hasBgOnMobile,
      bgUrl,
      type = 'content',
      videoProps,
      ...other
    },
    outerRef
  ) => {
    const classes = cx({
      [`card__section`]: true,
      [`card__section--fill`]: isFilling,
      [`card__section--condensed`]: isCondensed,
      [`card__section--show-bg-responsive`]: hasBgOnMobile,
      [`card__section--${bg}`]: bg,
      [`card__section--bg-image`]: bgUrl,
      [`card__section--${type}`]: type && type !== 'content',
      [`${className}`]: className,
    });

    const [innerRef] = useStatic(CardSectionStatic, {});

    return (
      <div
        ref={composeRefs<HTMLDivElement>(outerRef, innerRef)}
        data-card-section
        className={classes}
        style={{
          ...style,
          ...(bgUrl && { backgroundImage: `url(${bgUrl})` }),
        }}
        {...other}
      >
        {type === 'video' && <Video {...videoProps} />}
        {children}
      </div>
    );
  }
);

type Video = {
  /** Video URL */
  src?: string;
  /** Video format type */
  type?: 'video/mp4';
};

const Video = ({ type, src }: Video) => (
  <video autoPlay muted loop>
    <track kind="captions" />
    <source data-video-source={src} src="" type={type} />
  </video>
);

export default CardSection;
