import './styles/style.scss';

import Label from './Label';

/*

  TODO:
    - reasearch if SmallLabel and LargeLabel are used somewhere
      - if not, remove them
      - they were passing `size` prop to Label, but there is not such prop

*/

export const SmallLabel = Label;
export const LargeLabel = Label;

export default Label;
