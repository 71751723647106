import React from 'react';

import { ResponsiveMap } from '../../utils';

import { Bar, BarItem, Button } from '..';

type BoxBar = {
  /** Props passed to action. Action is button by default. */
  actionProps?: Button;
  /** Bar item should fill empty space, shrink and wrap */
  canWrap?: boolean;
  /** Specifies layout type. */
  layout?: ResponsiveMap<'vertical' | 'horizontal'>;
  /** Custom action renderer. */
  renderAction?: ({ actionProps }: { actionProps?: Button }) => React.ReactNode;
  /** Custom title renderer. */
  renderTitle?: ({ title }: { title: React.ReactNode }) => React.ReactNode;
  /** Title text. */
  title?: React.ReactNode;
} & Omit<JSX.IntrinsicElements['article'], 'title'>;

const BoxBar = ({
  actionProps,
  children,
  className,
  layout = { xs: 'vertical', m: 'horizontal' },
  renderAction,
  renderTitle,
  title,
  canWrap,
  ...other
}: BoxBar) => {
  const titleToRender =
    (renderTitle && renderTitle({ title })) || (title && <h3>{title}</h3>);

  const childrenToRender =
    children &&
    (typeof children === 'string' ? (
      <p className="p--center">{children}</p>
    ) : (
      children
    ));

  const actionToRender =
    (renderAction && renderAction({ actionProps })) ||
    (actionProps && actionProps.children && <Button {...actionProps} />);

  return (
    <article className={className} {...other}>
      <Bar direction={layout}>
        <BarItem isFilling={canWrap} canShrink={canWrap}>
          {titleToRender}
          {childrenToRender}
        </BarItem>
        {actionToRender && <BarItem>{actionToRender}</BarItem>}
      </Bar>
    </article>
  );
};

export default BoxBar;
