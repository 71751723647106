import React, { useContext } from 'react';
import cx from 'classnames';

import { useUniqueId } from '../../utils/hooks';

import AccordionHeader from './AccordionHeader';
import AccordionContext from './AccordionContext';

/*

  TODO:
    - `buttonType` isn't necessary here and can be moved to AccordionHeader

*/

type AccordionItem = {
  /** Accordion item id (automatically generated by default)  */
  id?: string;
  /** Open state indication */
  isActive?: boolean;
  /** Custom arrow renderer. Passes props as function parameter */
  renderArrow?: AccordionHeader['renderArrow'];
  /** Custom title renderer. Passes props as function parameter */
  renderTitle?: AccordionHeader['renderTitle'];
  /** Title of accordion item */
  title?: string;
} & JSX.IntrinsicElements['section'];

const getButtonType = (type?: 'inverted') => {
  switch (type) {
    case 'inverted': {
      return 'secondary-inverted';
    }
    default: {
      return 'secondary';
    }
  }
};

const AccordionItem = ({
  id,
  isActive,
  children,
  className,
  renderTitle,
  renderArrow,
  title,
}: AccordionItem) => {
  const classes = cx('accordion__item', className);

  const currentId = useUniqueId(id, 'accordion-item');
  const { type } = useContext(AccordionContext);

  return (
    <li className={classes}>
      <AccordionHeader
        buttonType={getButtonType(type)}
        id={currentId}
        isActive={isActive}
        renderTitle={renderTitle}
        renderArrow={renderArrow}
        title={title}
      />
      <section
        id={`${currentId}-section`}
        aria-labelledby={currentId}
        className={cx('accordion__body', { 'is-active': isActive })}
        aria-hidden={!isActive}
      >
        {children}
      </section>
    </li>
  );
};

export default AccordionItem;
