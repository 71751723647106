import React from 'react';
import cx from 'classnames';

import ToggleStatic from '../../scripts/modules/Toggle';
import { useUniqueId, useStatic } from '../../utils/hooks';

import { Consumer } from './NavigationContext';

import { Link, Icon } from '..';

/*

  TODO:
    - make it recursive
      - it can be only 2 levels deep, which is good, but the code is repeated mess
    - cleanup

*/

type NavigationLeaf = {
  /** Unique id for navigation item */
  id?: string;
  /** Item title */
  title: string | React.ReactNode;
  /** Item link. Passing `node` can be handy for example with `react-router` `<Link />` component */
  href: Link["href"];
  /** Highlights the Navigation item */
  isActive?: boolean;
  /** Shows external icon next to the item's title */
  isExternal?: boolean;
};

type NavigationItem = {
  /** Item title */
  title: React.ReactNode;
  /** Item link. Passing `node` can be handy for example with `react-router` `<Link />` component */
  href?: Link["href"];
  /** Icon props inside Navigation item */
  iconProps?: Omit<Icon, 'ref'>;
  /** Unique id for navigation item */
  id?: string;
  /** Highlights item */
  isActive?: boolean;
  /** Closable items list */
  isClosable?: boolean;
  /** Opens items list */
  isOpen?: boolean;
  /** Navigation items */
  items?: NavigationLeaf[];
  /** HTML tag in which is component rendered */
  tag?: string;
} & Omit<JSX.IntrinsicElements['li'], 'title'>;

const NavigationItem = ({
  children,
  className,
  title,
  href,
  items,
  iconProps,
  tag = 'li',
  id,
  isActive,
  isOpen: isOpenProp,
  isClosable = true,
  ...other
}: NavigationItem) => {
  const [navigationItemRef] = useStatic(ToggleStatic);

  const isOpen = !isClosable ? true : !!isOpenProp;

  const classes = cx({
    [`navigation__item`]: true,
    [`is-active`]: items && isOpen,
    [`${className}`]: className,
  });

  const Tag = tag;
  const currentId = useUniqueId(id);

  const returnLinkIcon = ({ isExternal }: { isExternal?: boolean }) =>
    isExternal && (
      <>
        &nbps;
        <Icon name="open" />
      </>
    );

  const returnTitle = (onClick?: () => void) =>
    href ? (
      <Link
        className="navigation__item-title"
        href={href}
        isActive={isActive}
        onClick={onClick}
      >
        <span className="d-flex align-items-middle">
          {iconProps && <Icon size="medium" {...iconProps} />}
          {title}
        </span>
      </Link>
    ) : (
      <button
        ref={navigationItemRef}
        type="button"
        className={cx('navigation__item-title')}
        {...(items && isClosable && { 'aria-expanded': isOpen })}
        {...(!href && isClosable && generateToggleData(currentId, isOpen))}
      >
        <span className="d-flex align-items-middle">
          {iconProps && <Icon size="medium" {...iconProps} />}
          {title}
        </span>
        {items && items.length !== 0 && isClosable && (
          <Icon
            name={`chevron-${isOpen ? 'up' : 'down'}` as Icon['name']}
            size="small"
            className="navigation__item-arrow"
          />
        )}
      </button>
    );

  return (
    <Consumer>
      {({ onCloseClick }) => (
        // @ts-ignore
        <Tag id={currentId} className={classes} {...other}>
          {returnTitle(onCloseClick)}
          {items && items.length !== 0 && (
            <ul
              className="list--unstyled list--expanded text-small no-mrg-bottom navigation__item-list"
              {...(isClosable && { 'aria-hidden': isOpen })}
            >
              {items.map(
                (
                  {
                    id: itemId,
                    title: itemTitle,
                    href: itemHref,
                    isExternal,
                    isActive: isActiveItem,
                    ...itemOther
                  },
                  i
                ) => (
                  <li key={itemId ?? i}>
                    <Link
                      tabIndex={isOpen ? 0 : -1}
                      type="grey"
                      href={itemHref}
                      isActive={isActiveItem}
                      onClick={onCloseClick}
                      {...itemOther}
                    >
                      {itemTitle}
                      {returnLinkIcon({ isExternal })}
                    </Link>
                  </li>
                )
              )}
            </ul>
          )}
          {children}
        </Tag>
      )}
    </Consumer>
  );
};

const generateToggleData = (id: string, isOpen: boolean) => ({
  'data-toggle': JSON.stringify([
    { target: 'parent', attribute: 'class', value: 'is-active' },
    {
      target: `#${id} .navigation__item-list`,
      attribute: 'aria-hidden',
      value: 'false',
    },
    {
      target: `#${id} .navigation__item-title`,
      attribute: 'aria-expanded',
      value: 'true',
    },
    {
      target: `#${id} .navigation__item-list .link`,
      attribute: 'tabindex',
      value: isOpen ? -1 : 0,
    },
    {
      target: `#${id} .navigation__item-arrow`,
      attribute: 'icon',
      value: `/sprite.svg#chevron-${isOpen ? 'down' : 'up'}`,
    },
  ]),
});

export default NavigationItem;
