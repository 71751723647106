/* eslint-disable max-lines */

import React from 'react';
import cx from 'classnames';

import {
  Bar,
  BarItem,
  BarBreak,
  Button,
  ButtonLayout,
  Container,
  Grid,
  GridCol,
  Icon,
  Link,
  PageSection,
  Accordion,
  AccordionItem,
} from '..';

type Footer = {
  /** List of awards */
  awards?: string[];
  /** Awards label */
  awardsLabel?: React.ReactNode;
  /** CSS classes */
  className?: string;
  /** Facebook widget href */
  fbHref?: string;
  /** List of mobile applications. All other params will drop to `...rest` parameter and apply on the component. */
  mobileApps: {
    title: string;
    icon: string;
    href: Link['href'];
  }[];
  /** Mobile applications label */
  mobileAppsLabel?: React.ReactNode;
  /** Page secondary navigation. All other params will drop to `...rest` parameter and apply on the component. */
  navigation: {
    title: string;
    items: {
      title: string;
      href: Link['href'];
    }[];
  }[];
  /** Copyright info */
  siteInfoCopy?: string;
  /** Meta links. All other params will drop to `...rest` parameter and apply on the component. */
  siteInfoMeta: {
    title: string;
    href: Link['href'];
  }[];
  /** Social links. All other params will drop to `...rest` parameter and apply on the component. */
  siteInfoSocial: {
    title: string;
    icon: string;
    href: Link['href'];
  }[];
};

const Footer = ({
  awards = [
    'Hlavná cena ITAPA',
    '2x Strieborná cena ITAPA',
    'ITAPA eFocus',
    'IT projekt roka',
    'Facebook Awards',
  ],
  awardsLabel = 'Naše ocenenia',
  className,
  fbHref = 'https://www.facebook.com/DoveraZdravotnaPoistovna/',
  mobileApps = [
    {
      title: 'Google Play',
      icon: 'social-googleplay',
      href:
        'https://play.google.com/store/apps/details?id=com.cleverlance.dovera.mp',
    },
    {
      title: 'App Store',
      icon: 'social-apple',
      href: 'https://itunes.apple.com/cz/app/dovera/id700693301',
    },
  ],
  mobileAppsLabel = 'Stiahnite si Dôvera aplikáciu',
  navigation = [],
  siteInfoCopy = `© ${new Date().getFullYear()} Dôvera`,
  siteInfoMeta = [
    {
      title: 'Mapa stránky',
      href: 'https://www.dovera.sk/sitemap.xml',
    },
    {
      title: 'Ochrana súkromia',
      href:
        'https://www.dovera.sk/o-nas/uradna-tabula/a321/informacie-o-spracuvani-osobnych-udajov',
    },
  ],
  siteInfoSocial = [
    {
      title: 'Twitter',
      icon: 'social-twitter',
      href: 'https://twitter.com/doverazp',
    },
    {
      title: 'Youtube',
      icon: 'social-youtube',
      href: 'https://www.youtube.com/channel/UCiRFgLZLavxi62VJmsOYrLA',
    },
    {
      title: 'Facebook',
      icon: 'social-facebook',
      href: 'https://www.facebook.com/DoveraZdravotnaPoistovna/',
    },
    {
      title: 'LinkedIn',
      icon: 'social-linkedin',
      href: 'https://sk.linkedin.com/company/dovera',
    },
  ],
  ...other
}: Footer) => {
  const classes = cx('footer', className);

  return (
    <PageSection className={classes} bg="blue" tag="footer" {...other}>
      <Container className="text-small">
        <Grid>
          <GridCol size={{ m: 6 }} className="footer__apps">
            <MobileApps
              mobileApps={mobileApps}
              mobileAppsLabel={mobileAppsLabel}
            />
          </GridCol>
          <GridCol className="footer__navigation">
            <NavigationInGrid items={navigation} className="show-m" />
            <NavigationInAccordion items={navigation} className="hide-m" />
          </GridCol>
          <GridCol size={{ m: 6 }} className="footer__awards">
            <Awards awards={awards} awardsLabel={awardsLabel} />
          </GridCol>
        </Grid>
        <hr className="hr--inverted mb" />
        <SiteInfo
          fbHref={fbHref}
          siteInfoCopy={siteInfoCopy}
          siteInfoMeta={siteInfoMeta}
          siteInfoSocial={siteInfoSocial}
        />
      </Container>
    </PageSection>
  );
};

export default Footer;

const MobileApps = ({
  mobileApps,
  mobileAppsLabel,
}: Pick<Footer, 'mobileApps' | 'mobileAppsLabel'>) => (
  <>
    {typeof mobileAppsLabel === 'string' ? (
      <p className="text-small text-bold text-inverted text-transform-upper text-font-primary mb-small">
        {mobileAppsLabel}
      </p>
    ) : (
      mobileAppsLabel
    )}
    <ButtonLayout direction="horizontal">
      {mobileApps.map(({ title, icon, href, ...other }) => (
        <Button key={title} type="secondary-inverted" href={href} {...other}>
          <Icon
            name={icon as Icon['name']}
            size="medium"
            className="icon--left"
          />
          {title}
        </Button>
      ))}
    </ButtonLayout>
  </>
);

const Awards = ({
  awards,
  awardsLabel,
}: Pick<Footer, 'awards' | 'awardsLabel'>) => (
  <>
    {typeof awardsLabel === 'string' ? (
      <p className="text-small text-bold text-inverted text-transform-upper text-font-primary mb-small">
        {awardsLabel}
      </p>
    ) : (
      awardsLabel
    )}
    <Bar key="awards" space="small" className="align-items-top no-mrg">
      {awards?.map((award) => (
        <BarItem key={award!} className="text-inverted--700 text-center">
          <Icon
            name="32-award"
            size="large"
            className="text-color-white mb-xxsmall"
          />
          <br />
          {award}
        </BarItem>
      ))}
    </Bar>
  </>
);

const NavigationInGrid = ({
  className,
  items,
}: {
  className: string;
  items: Footer['navigation'];
}) => (
  <div className={className}>
    <Grid>
      {items.map(({ title, items: links }) => (
        <GridCol size={{ m: 3 }} key={title}>
          <p className="text-inverted text-transform-upper mb-xsmall text-small">
            {title}
          </p>
          <ul className="list--unstyled list--expanded text-small">
            {links.map(({ title: linkTitle, href, ...linksOther }) => (
              <li key={linkTitle}>
                <Link href={href} type="inverted" {...linksOther}>
                  {linkTitle}
                </Link>
              </li>
            ))}
          </ul>
        </GridCol>
      ))}
    </Grid>
  </div>
);

const NavigationInAccordion = ({
  className,
  items,
}: {
  className: string;
  items: Footer['navigation'];
}) => (
  <div className={className}>
    <Accordion type="inverted">
      {items.map(({ title, items: links }) => (
        <AccordionItem title={title} key={title}>
          <ul className="list--unstyled list--expanded list-inverted--700 ">
            {links.map(({ title: linkTitle, href, ...linksOther }) => (
              <li key={linkTitle}>
                <Link href={href} {...linksOther}>
                  {linkTitle}
                </Link>
              </li>
            ))}
          </ul>
        </AccordionItem>
      ))}
    </Accordion>
  </div>
);

const SiteInfo = ({
  siteInfoCopy,
  siteInfoMeta,
  siteInfoSocial,
  fbHref,
}: Pick<
  Footer,
  'siteInfoCopy' | 'siteInfoMeta' | 'siteInfoSocial' | 'fbHref'
>) => (
  <div className="footer__siteinfo">
    <Grid>
      <GridCol size={{ m: 4 }} className="footer__siteinfo-pages">
        <Bar space="xxsmall">
          {siteInfoMeta?.map(({ title, href, ...siteInfoMetaOther }) => (
            <BarItem canShrink key={title}>
              <Link href={href} type="inverted" {...siteInfoMetaOther}>
                {title}
              </Link>
            </BarItem>
          ))}
        </Bar>
      </GridCol>
      <GridCol
        size={{ m: 4 }}
        className="show-m text-center footer__siteinfo-copy"
      >
        <p className="text-inverted--700 text-small">{siteInfoCopy}</p>
      </GridCol>
      <GridCol size={{ m: 4 }} className="footer__siteinfo-social">
        <Bar space="xxsmall">
          {siteInfoSocial.map(
            ({ title, href, icon, ...siteInfoSocialOther }) => (
              <BarItem key={title}>
                <Link href={href} type="inverted" {...siteInfoSocialOther}>
                  <Icon name={icon as Icon['name']} size="medium" alt={title} />
                </Link>
              </BarItem>
            )
          )}
          <BarItem>
            <div
              className="fb-like"
              data-href={fbHref}
              data-layout="standard"
              data-action="like"
              data-size="small"
              data-show-faces="true"
              data-share="false"
              data-colorscheme="dark"
              data-width="100"
            />
          </BarItem>
          <BarBreak className="hide-m" />
          <BarItem className="hide-m">
            <p className="text-inverted--700 text-small">{siteInfoCopy}</p>
          </BarItem>
        </Bar>
      </GridCol>
    </Grid>
  </div>
);
