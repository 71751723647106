import accessibleAutocomplete from '@lighting-beetle/accessible-autocomplete';

import InputStatic from '../Input/Input.static';

export default class Autocomplete {
  constructor(element, config) {
    this.element = element;
    this.config = { ...config };

    this.init();

    this.element.DDL_Autocomplete = this;

    return this;
  }

  init() {
    this.instance = accessibleAutocomplete.enhanceSelectElement({
      selectElement: this.element,
      placeholder: this.element.getAttribute('placeholder'),
      autoselect: false,
      defaultValue: undefined,
      menuClassName: `dropdown ${this.getAlignmentClass()}`,
      optionClassName: 'dropdown__item',
      displayMenu: 'overlay',
      dropdownArrow: ({ menuIsVisible }) =>
        `<svg class="icon icon--${
          menuIsVisible ? 'chevron-up' : 'chevron-down'
        } icon--medium" role="presentation" aria-hidden="true">
        <title>${menuIsVisible ? 'chevron-up' : 'chevron-down'}</title>
        <use xlink:href="/sprite.svg#${
          menuIsVisible ? 'chevron-up' : 'chevron-down'
        }"></use>
      </svg>`,
      onInit: ({ inputElement }) => {
        new InputStatic(inputElement); // eslint-disable-line
      },
      tNoResults: () => this.element.dataset.noResultsString,
      ...this.config,
    });
  }

  getAlignmentClass() {
    const align = this.element.getAttribute('data-autocomplete-align');

    return align ? `dropdown--${align}` : '';
  }

  static getInstance(el) {
    return el && el.DDL_Autocomplete ? el.DDL_Autocomplete : null;
  }

  destroy() {
    this.instance = null;
    accessibleAutocomplete.destroy();
  }

  update() {
    this.destroy();
    this.init();
  }

  static templateSection = title =>
    `<div class="autocomplete__option-section h6 no-mrg-bottom">${title}</div>`;

  static templateAction = title =>
    `<div class="autocomplete__option-action text-font-primary text-ellipsis">${title}</div>`;

  static templateArticle = ({ title, subtitle }) => `
    <div class="autocomplete__option-article">
      <div class="autocomplete__option-article-title text-bold text-color-black text-font-primary text-ellipsis">${title}</div>
      <div class="text-strong text-ellipsis">${subtitle}</div>
    </div>
  `;

  static templateContact = ({
    contactUs = 'Nenašli ste, čo ste potrebovali? Napíšte nám.',
  } = {}) => `
    <div class="autocomplete__option-contact link">${contactUs}</div>
  `;
}
