export const TOGGLE_EVT = 'toggle';

export default function toggle(props) {
  const {
    element,
    attribute,
    value,
    action,
    dispatchEvent = true,
    eventDetail,
  } = props;
  let returnValue = null;

  if (!element || !(element instanceof Element)) {
    return returnValue;
  }

  if (action === 'focus') {
    element.focus();
  }

  if (typeof attribute === 'undefined') {
    return returnValue;
  }

  if (attribute === 'class') {
    element.classList.toggle(value);
    return value;
  }

  if (attribute === 'text') {
    const oldText = element.textContent;
    element.textContent = value;
    return oldText;
  }

  returnValue = element.getAttribute(attribute);

  // tento warning sa zobrazuje ak target element neobsahuje atribut, ktory ma byt togleny.
  // plati to primarne ak ma byt target element togleny dvoma roznymi trigermi.
  if (!returnValue) {
    // eslint-disable-next-line no-console
    console.warn(
      `You have to define the default value for '${attribute}' attribute on element on which you want to use toggle listener.`
    );
    return null;
  }

  // 'value === returnValue' riesi nespravne a duplicitne toglenie true / false hodnot.
  if (!value || value === returnValue) {
    element.setAttribute(attribute, !(returnValue === 'true'));
  } else {
    element.setAttribute(attribute, value);
  }

  if (dispatchEvent) {
    element.dispatchEvent(
      new CustomEvent(TOGGLE_EVT, {
        detail: eventDetail,
        bubbles: true,
      })
    );
  }

  return returnValue;
}
