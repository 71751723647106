import autosize from 'autosize';

export default class Textarea {
  element: HTMLElement;
  instance: any;

  constructor(element: HTMLElement) {
    this.element = element;

    this.init();

    // @ts-ignore
    this.element.DDL_Textarea = this;
  }

  init() {
    this.instance = autosize(this.element);
  }

  static getInstance(el: HTMLElement) {
    // @ts-ignore
    return el && el.DDL_Textarea ? el.DDL_Textarea : null;
  }

  destroy() {
    autosize.destroy(this.element);
  }

  update() {
    this.destroy();
    this.init();
  }
}
