import React from 'react';

import { Bar, BarItem, Icon, Button } from '..';

type BoxFeedback = {
  /** Class name */
  className?: string;
  /** Question text */
  children?: React.ReactNode;
  /** Props attached to dislike button */
  dislikeProps?: Button;
  /** Props attached to like button */
  likeProps?: Button;
};

const BoxFeedback = ({
  children = 'Pomohol vám tento článok?',
  className,
  likeProps = { children: 'Áno' },
  dislikeProps = { children: 'Nie' },
}: BoxFeedback) => {
  const { children: likeChildren, ...otherLikeProps } = likeProps;
  const { children: dislikeChildren, ...otherDislikeProps } = dislikeProps;

  return (
    <div className={className}>
      <Bar className="align-items-center">
        <BarItem>{children}</BarItem>
        <BarItem>
          <Button
            type="link-secondary"
            className="no-pad-horizontal"
            size="xs"
            {...otherLikeProps}
          >
            <Icon name="thumbs-up" className="icon--left" size="medium" />
            {likeChildren}
          </Button>
        </BarItem>
        <BarItem>
          <Button
            type="link-secondary"
            className="no-pad-horizontal"
            size="xs"
            {...otherDislikeProps}
          >
            <Icon name="thumbs-down" className="icon--left" size="medium" />
            {dislikeChildren}
          </Button>
        </BarItem>
      </Bar>
    </div>
  );
};

export default BoxFeedback;
