import React from 'react';
import cx from 'classnames';

/*

  TODO:
    - make `medium` the default size

*/

type Disc = {
  /** Size of disc */
  size: 'x-small' | 'small' | 'medium' | 'large';
  /** Disc theme. Default is primary */
  theme?: 'secondary' | 'grey' | 'success' | 'warning' | 'error';
} & JSX.IntrinsicElements['div'];

const Disc = ({ children, className, theme, size, ...other }: Disc) => {
  const classes = cx({
    [`disc`]: true,
    [`disc--${size}`]: size,
    [`disc--${theme}`]: theme,
    [`${className}`]: className,
  });

  return (
    <div className={classes} {...other}>
      {children}
    </div>
  );
};

export default Disc;
