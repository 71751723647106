import React, { isValidElement, cloneElement, ReactElement } from 'react';
import cx from 'classnames';

type DropdownItem = {
  /** Dropdown item href. Passing `node` can be handy for example with `react-router` `<Link />` component. */
  href?: React.ReactNode;
  /** Sets active styles on component */
  isActive?: boolean;
  /** ARIA role of the menu item. See accessibility section for notes. */
  role?: string;
  /** HTML tag of the menu item. Remember, anchors take you elsewhere while buttons do stuff */
  tag?: string;
} & JSX.IntrinsicElements['button'];

const DropdownItem = ({
  className,
  children,
  tag = 'button',
  role = 'menuitem',
  isActive,
  href,
  ...other
}: DropdownItem) => {
  const classes = cx({
    [`dropdown__item`]: true,
    [`is-active`]: isActive,
    [`${className}`]: className,
  });
  let Tag = tag;
  let type: string | undefined;

  if (Tag === 'button') {
    type = 'button';
  }

  if (href) {
    Tag = 'a';
  }

  const props = {
    tabIndex: 0,
    className: classes,
    children,
    role,
    type,
    ...other,
  };

  if (isValidElement(href)) {
    return cloneElement(href as ReactElement<any>, props);
  }

  // @ts-ignore
  return <Tag href={href} {...props} />;
};

export default DropdownItem;
