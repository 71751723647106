import React from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';

import { NavigationList } from '..';

const CLASS_ROOT = 'header-mobile__menu';

const propTypes = {
  /** Object which defines params of each link/item in menu. All other params will drop to `...rest` parameter and apply on the component. */
  items: arrayOf(
    shape({
      /** Item title. */
      title: string.isRequired,
      /** Item link. */
      href: string,
      /** When true, apply different styles to item. */
      isMain: bool,
      /** Sets active state / style on item. */
      isActive: bool,
      /** Icon props object, which shows next to the item title. */
      iconProps: shape({
        /** Icon name. */
        name: string,
        /** Icon size. */
        size: string,
      }),
      /** Object which defines params of each sub - link/item in parent item. All other params will drop to `...rest` parameter and apply on the component. */
      items: arrayOf(
        shape({
          /** Sub-item title. */
          title: string,
          /** Sub-item link. */
          href: string,
          /** Sets active state / style on sub-item. */
          isActive: bool,
        })
      ),
    })
  ),
};

const HeaderMobileMenu = ({ items, ...other }) => (
  <NavigationList
    className={CLASS_ROOT}
    items={items}
    type="inverted"
    {...other}
  />
);

HeaderMobileMenu.propTypes = propTypes;
HeaderMobileMenu.displayName = 'HeaderMobileMenu';

export default HeaderMobileMenu;
