import React, { isValidElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
// @ts-ignore
import * as htmlFormatter from 'html-formatter';
import reactElementToJSXString from 'react-element-to-jsx-string';

export const elementToJsx = (element: React.ReactElement) => {
  if (typeof element === 'string') {
    return element;
  }

  if (Array.isArray(element)) {
    element = <>{element}</>;
  }

  return reactElementToJSXString(element, {
    showDefaultProps: false,
    showFunctions: true,
    functionValue: (fn) => fn.name,
    displayName: (element: React.ReactNode) => {
      if (isValidElement(element)) {
        return element.props.mdxType;
      }
    },
    filterProps: ['mdxType', 'originalType'],
    maxInlineAttributesLineLength: 60,
  });
};

export const elementToHtml = (element: React.ReactElement) => {
  return htmlFormatter.render(renderToStaticMarkup(element));
};
