import React from 'react';

import './style.scss';

type Rectangle = {
  children: React.ReactNode;
};

const Rectangle = ({ children }: Rectangle) => (
  <div className="Rectangle">{children}</div>
);

export default Rectangle;
