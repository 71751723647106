import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';

import Message from '../Message';

type RadioCheck = {
  /** Error state. If vaule is string, it's treated as error message. */
  error?: boolean | React.ReactNode;
  /** Help text. */
  help?: string;
  /** Html id attribute. */
  id: string;
  /** Active state. */
  isActive?: boolean;
  /** Checked state. */
  isChecked?: boolean;
  /** Disabled state. */
  isDisabled?: boolean;
  /** Readonly state. */
  isReadonly?: boolean;
  /** Html name attribute. */
  name?: string;
  /** Value change callback function */
  onChange?: (value: string | boolean | undefined, e: ChangeEvent) => void;
  /** Custom error renderer. Passes props as function parameter. */
  renderError?: (props: RadioCheck) => JSX.Element;
  /** Custom help renderer. Passes props as function parameter. */
  renderHelp?: (props: RadioCheck) => JSX.Element;
  /** Custom label renderer. Passes props as function parameter. */
  renderLabel?: (props: RadioCheck) => JSX.Element;
  /** Custom warning renderer. Passes props as function parameter. */
  renderWarning?: (props: RadioCheck) => JSX.Element;
  /** Input html type */
  type: 'radio' | 'checkbox';
  /** Warning state. If value is string, it's treated as warning message. */
  warning?: boolean | React.ReactNode;
  /** Indeterminate state – used only with checkbox. */
  indeterminate?: boolean;
} & Omit<JSX.IntrinsicElements['input'], 'onChange'>;

const RadioCheck = (props: RadioCheck) => {
  const {
    className,
    children,
    isActive,
    isChecked,
    isDisabled,
    isReadonly,
    error,
    renderError,
    warning,
    renderWarning,
    help,
    renderHelp,
    id,
    renderLabel,
    onChange = (value, e) => {},
    type,
    indeterminate,
    ...other
  } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let checked;
      if (e.target.type === 'checkbox') {
        checked = e.target.checked;
      }

      if (e.target.type === 'radio') {
        checked = e.target.value;
      }

      onChange(checked, e);
    },
    [onChange]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate;
    }
  }, [indeterminate]);

  const classes = cx('radiocheck', type, className);

  const inputClasses = cx({
    [`radiocheck__input`]: true,
    [`is-active`]: isActive,
    [`is-error`]: error,
    [`is-warning`]: warning,
    'is-indeterminate': indeterminate,
  });

  const formControlClasses = cx('form-control', `form-control--${type}`);

  // set name from id if name is not specfied
  let { name } = other;
  name = name && id;

  const returnLabel = (renderLabel && renderLabel(props)) || (
    <label className="radiocheck__label" htmlFor={id} id={`${id}__label`}>
      {children}
    </label>
  );

  const helpMessage =
    (renderHelp && renderHelp(props)) ||
    (help && <Message type="help">{help}</Message>);

  const errorMessage =
    (renderError && renderError(props)) ||
    (error && typeof error === 'string' && (
      <Message type="error">{error}</Message>
    ));

  const warningMessage =
    (renderWarning && renderWarning(props)) ||
    (warning && typeof warning === 'string' && (
      <Message type="warning">{warning}</Message>
    ));

  return (
    <div className={formControlClasses}>
      <div className={classes}>
        <input
          ref={inputRef}
          id={id}
          className={inputClasses}
          name={name}
          type={type}
          role={type}
          tabIndex={0}
          aria-checked="false"
          aria-labelledby={`${id}__label`}
          checked={isChecked}
          disabled={isDisabled}
          readOnly={isReadonly}
          onChange={handleChange}
          {...other}
        />
        <label className="radiocheck__control" htmlFor={id} />
        {returnLabel}
        {helpMessage}
        {warningMessage}
        {errorMessage}
      </div>
    </div>
  );
};

export default RadioCheck;
