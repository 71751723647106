import { RovingTabindex } from '../../utils';

const defaultConfig = {
  tabSelector: '[role="tab"]',
  activeClass: 'is-active',
  disableURLChange: false,
  onChange: () => {},
};

// selector = '[role="tab"]'
export default class Tabs {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    if (this.element.hasAttribute('data-tabs-disable-url-change')) {
      this.config.disableURLChange = true;
    }

    this.tabs = [];
    this.tabPanels = [];
    this.activeTabIndex = null;

    this.rovingTabindex = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleTabFocus = this.handleTabFocus.bind(this);
    Tabs.handleGradientVisibility = Tabs.handleGradientVisibility.bind(this);
    Tabs.handleScroll = Tabs.handleScroll.bind(this);
    this.openTabOnLoad = this.openTabOnLoad.bind(this);

    this.init();

    this.element.DDL_Tabs = this;

    return this;
  }

  init() {
    this.tabs = Array.from(
      this.element.querySelectorAll(this.config.tabSelector)
    );

    this.tabList = this.element.querySelector('[role="tablist"]');

    this.tabs.map((tab, index) => {
      this.tabPanels.push(
        document.getElementById(tab.getAttribute('aria-controls'))
      );
      tab.addEventListener('click', this.handleClick);
      tab.addEventListener('focus', this.handleTabFocus);

      if (this.isActive(tab)) {
        this.activeTabIndex = index;
      }

      return tab;
    });

    if (!this.rovingTabindex) {
      this.rovingTabindex = new RovingTabindex(this.tabs, {
        direction: 'horizontal',
      });
    } else {
      this.rovingTabindex.update(this.tabs);
    }

    this.openTabOnLoad(this.tabList);

    Tabs.handleGradientVisibility(this.tabList);

    window.addEventListener('resize', () =>
      Tabs.handleGradientVisibility(this.tabList)
    );

    this.tabList.addEventListener(
      'scroll',
      (e) => Tabs.handleScroll(e.currentTarget),
      false
    );
  }

  destroy() {
    this.tabs.map((tab) => {
      tab.removeEventListener('click', this.handleClick);
      tab.removeEventListener('focus', this.onFocus);
      return tab;
    });

    this.tabs = [];
    this.tabPanels = [];
    this.rovingTabindex.destroy();
  }

  update() {
    this.destroy();
    this.init();
  }

  static getInstance(el) {
    return el && el.DDL_Tabs ? el.DDL_Tabs : null;
  }

  static openTabOnLoad(e) {
    const { offsetLeft } = e.getElementsByClassName('is-active')[0];
    e.scrollLeft = offsetLeft;
  }

  isActive = (el) => el.classList.contains(this.config.activeClass);

  static handleGradientVisibility(itemsList) {
    const parent = itemsList.parentNode;

    // if tabs are not scrollable
    if (parent.scrollWidth === itemsList.scrollWidth) {
      parent.classList.add('hide-before', 'hide-after');
    } else {
      parent.classList.add('hide-before');

      if (itemsList.scrollLeft !== 0) {
        parent.classList.remove('hide-before');
      }
      if (itemsList.scrollLeft !== itemsList.scrollWidth) {
        parent.classList.remove('hide-after');
      }
    }
  }

  static handleScroll(itemsList) {
    const { scrollLeft, scrollWidth, offsetWidth } = itemsList;
    const offset = 0;

    if (scrollWidth - scrollLeft - offsetWidth === offset) {
      itemsList.parentNode.classList.add('hide-after');
    } else {
      itemsList.parentNode.classList.remove('hide-after');
    }
    if (scrollLeft === offset) {
      itemsList.parentNode.classList.add('hide-before');
    } else {
      itemsList.parentNode.classList.remove('hide-before');
    }
  }

  openTabOnLoad(itemsList) {
    const { hash } = window.location;
    const currentTab = itemsList.querySelector('.tab.is-active');
    const currentPanel = document.querySelector(
      `#${currentTab.getAttribute('aria-controls')}`
    );
    let hashTab = null;
    let hashPanel = null;

    if (hash !== '') {
      hashTab = itemsList.querySelector(
        `[aria-controls="${hash.replace('#', '')}"]`
      );
      hashPanel = document.querySelector(hash);
    }

    if (hash !== '' && hashTab) {
      const { offsetLeft } = hashTab;
      // eslint-disable-next-line no-param-reassign
      itemsList.scrollLeft = offsetLeft;
    } else if (currentTab) {
      const { offsetLeft } = currentTab;
      // eslint-disable-next-line no-param-reassign
      itemsList.scrollLeft = offsetLeft;
    }

    if (hashTab && currentTab && hashTab && hashPanel) {
      this.toggleTab(currentTab, 'off');
      this.toggleTabPanel(currentPanel, 'off');
      this.toggleTab(hashTab, 'on');
      this.toggleTabPanel(hashPanel, 'on');
    }
  }

  handleClick(e) {
    const clickedTab = e.currentTarget;

    if (e.currentTarget.hasAttribute('aria-disabled')) {
      e.preventDefault();
      return;
    }

    this.tabs.forEach((tab, i) => {
      if (tab === clickedTab) {
        this.activeTabIndex = i;
        this.toggleTab(tab, 'on');
      } else {
        this.toggleTab(tab, 'off');
      }
    });

    let activeTabPanel;
    this.tabPanels.forEach((tabPanel) => {
      if (
        tabPanel.getAttribute('id') === clickedTab.getAttribute('aria-controls')
      ) {
        activeTabPanel = tabPanel;

        if (!this.config.disableURLChange) {
          // TODO:
          // 1. use `window.location.hash` assignment instead of `history.replaceState()` function
          // 2. fix scrolling on hash change (`e.preventDefault()` doesn't work)

          // eslint-disable-next-line no-restricted-globals
          history.replaceState(null, null, `#${tabPanel.getAttribute('id')}`);

          // window.location.hash = `#${tabPanel.getAttribute('id')}`;
        }

        this.toggleTabPanel(tabPanel, 'on');
      } else {
        this.toggleTabPanel(tabPanel, 'off');
      }
    });

    this.config.onChange(
      {
        index: this.activeTabIndex,
        tab: clickedTab,
        panel: activeTabPanel,
      },
      e
    );
  }

  toggleTab(el, state) {
    const isActive = el.classList.contains(this.config.activeClass);

    if (state === 'on' && !isActive) {
      el.classList.add(this.config.activeClass);
      el.setAttribute('aria-selected', 'true');
      el.setAttribute('tabindex', '0');
    }

    if (state === 'off' && isActive) {
      el.classList.remove(this.config.activeClass);
      el.setAttribute('aria-selected', 'false');
      el.setAttribute('tabindex', '-1');
    }
  }

  toggleTabPanel(el, state) {
    const isActive = el.classList.contains(this.config.activeClass);

    if (state === 'on' && !isActive) {
      el.classList.add(this.config.activeClass);
    }

    if (state === 'off' && isActive) {
      el.classList.remove(this.config.activeClass);
    }
  }

  activateNthTab(index) {
    this.tabs.map((tab, i) => {
      if (i === index) {
        this.activeTabIndex = i;
        return this.toggleTab(tab, 'on');
      }
      return this.toggleTab(tab, 'off');
    });

    this.tabPanels.map((tabPanel, i) => {
      if (i === index) {
        return this.toggleTabPanel(tabPanel, 'on');
      }
      return this.toggleTabPanel(tabPanel, 'off');
    });
  }

  handleTabFocus() {
    if (!this.rovingTabindex.isActive) {
      this.rovingTabindex.init();
    }
  }
}

export const { handleGradientVisibility, handleScroll } = Tabs;
