import React from 'react';
import {
  arrayOf,
  object,
  oneOfType,
  string,
  shape,
  oneOf,
  func,
  bool,
} from 'prop-types';
import cx from 'classnames';

import '@lighting-beetle/accessible-autocomplete/src/autocomplete.css';

import AutocompleteStatic from './Autocomplete.static';

import { Input } from '..';

const CLASS_ROOT = 'autocomplete';

export default class Autocomplete extends React.Component {
  static displayName = 'Autocomplete';

  static propTypes = {
    /** Dropdown alignment */
    align: oneOf(['left', 'right']),
    /** Module config. For more see [accessible-autocomplete docs](https://github.com/lightingbeetle/accessible-autocomplete) */
    autocompleteConfig: object,
    /** Specify a string to prefill the autocomplete with - uncontrolled */
    defaultValue: string,
    /** Custom autocomplete id */
    id: string,
    /** Disabled state */
    isDisabled: bool,
    /** Text used in the dropdown to indicate that there are no results. */
    noResultsString: string,
    /** Value change callback function. */
    onChange: func,
    /** Pick item in autocomplete dropdown and blur of autocomplete input triggers callback with current value. This callback can be triggered multiple times with same value in case of picking item from dropdown. */
    onConfirm: func,
    /** Array of string options */
    options: arrayOf(
      oneOfType([string, shape({ title: string, value: string })])
    ),
    /** The autocomplete will display a noResultsString when there are no results. */
    showNoOptionsFound: bool,
    /** Specify a string to prefill the autocomplete with - controlled */
    value: string,
  };

  static defaultProps = {
    options: [],
    // eslint-disable-next-line no-unused-vars
    onChange: (newValue, event) => {},
    // eslint-disable-next-line no-unused-vars
    onConfirm: newValue => {},
  };

  static static = AutocompleteStatic;

  constructor(props) {
    super(props);

    this.autocompleteRef = React.createRef();
  }

  componentDidMount() {
    const config = Object.assign(
      {},
      {
        onChange: this.props.onChange,
        onConfirm: this.props.onConfirm,
        defaultValue: this.props.defaultValue,
        value: this.props.value,
        isDisabled: this.props.isDisabled,
        id: this.props.id,
        showNoOptionsFound: this.props.showNoOptionsFound,
      },
      this.props.autocompleteConfig
    );

    this.autocomplete = new AutocompleteStatic(
      this.autocompleteRef.current,
      config
    );
  }

  componentDidUpdate() {
    if (this.autocomplete && this.autocomplete.instance) {
      this.autocomplete.instance.setOptions({
        value: this.props.value,
        isDisabled: this.props.isDisabled,
        id: this.props.id,
        newOptions: this.props.options,
        showNoOptionsFound: this.props.showNoOptionsFound,
      });
    }
  }

  componentWillUnmount() {
    this.autocomplete.destroy();
  }

  render() {
    const {
      className,
      children,
      options,
      autocompleteConfig,
      align,
      onConfirm,
      onChange,
      noResultsString = 'Nenašli sa žiadne výsledky',
      defaultValue,
      value,
      id,
      isDisabled,
      showNoOptionsFound,
      ...other
    } = this.props;

    return (
      <Input
        className={cx(CLASS_ROOT, className)}
        elemRef={this.autocompleteRef}
        tag="select"
        inputType=""
        data-no-results-string={noResultsString}
        id={id}
        {...(align && { 'data-autocomplete-align': align })}
        {...(options.length && { 'data-autocomplete': true })}
        {...other}
      >
        {options && (
          <>
            <option label="Zvoľte si možnosť" />
            {options.map(option => (
              <option
                value={typeof option === 'string' ? option : option.value}
                key={typeof option === 'string' ? option : option.value}
              >
                {typeof option === 'string' ? option : option.title}
              </option>
            ))}
          </>
        )}
      </Input>
    );
  }
}
