import React from 'react';
import cx from 'classnames';

/*

  TODO:
    - make `Grid` polymorphic for `tag` or `as` prop

*/

type Grid = {
  /** Gutters between columns */
  hasGutters?: boolean;
  /** HTML element. */
  tag?: string;
} & JSX.IntrinsicElements['div'];

const Grid = ({
  className,
  tag = 'div',
  hasGutters = true,
  ...other
}: Grid) => {
  const classes = cx({
    [`grid`]: true,
    [`grid--no-gutters`]: !hasGutters,
    [`${className}`]: className,
  });
  const Tag = tag;

  // @ts-ignore
  return <Tag className={classes} {...other} />;
};

export default Grid;
