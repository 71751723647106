import React from 'react';
import cx from 'classnames';

type CellContent = React.ReactNode;

type Column = {
  key: string;
  label: string;
  cellProps?: {};
  headCellProps?: {};
  render?: (content: CellContent, column: Column) => React.ReactNode;
};
type Row = {
  [index: string]: CellContent;
};

type Table = {
  /** Labels for columns in the table. Value of the key in columns match with the key name in data */
  columns: Column[];
  /** Data to print out in the table. */
  data: Row[];
  /** Is set, if the table doesn't have header. */
  headDisabled?: boolean;
  /** Multiple verticaly adjacent tables should look like one table with multiple table heads. `first` indicates first multitable */
  isMulti?: boolean | 'first' | 'last';
} & JSX.IntrinsicElements['div'];

const Table = ({
  className,
  columns,
  data,
  headDisabled = false,
  isMulti,
  ...other
}: Table) => {
  const classes = cx({
    [`table`]: true,
    [`table--responsive`]: true,
    [`table--multi`]: isMulti,
    [`table--multi-${isMulti}`]: typeof isMulti === 'string',
    [`${className}`]: className,
  });

  function noLabel(element: { label: string }) {
    return element.label === '';
  }

  const tableHead =
    headDisabled || columns.every(noLabel) ? null : (
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              {...(column.headCellProps ? column.headCellProps : {})}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
    );

  const table = (
    <div className={classes} {...other}>
      <table>
        {tableHead}
        <tbody>
          {data.map((row, i) => (
            <tr key={i.toString()}>
              {columns.map((column) =>
                column.render ? (
                  column.render(row[column.key], column)
                ) : (
                  <td
                    key={column.key}
                    {...(column.cellProps ? column.cellProps : {})}
                  >
                    {row[column.key]}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return table;
};

export default Table;
