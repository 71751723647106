import React, { forwardRef } from 'react';
import cx from 'classnames';

/*

  TODO:
    - make `Bar` polymorphic for `tag` or `as` prop

*/

type BarBreak = {
  /** HTML tag */
  tag?: string;
};

const BarBreak = forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements['div'] & BarBreak
>(({ className, tag = 'div', ...other }, ref) => {
  const classes = cx({
    [`bar__break`]: true,
    [`${className}`]: className,
  });

  const Tag = tag;

  // @ts-ignore
  return <Tag ref={ref} className={classes} {...other} />;
});

export default BarBreak;
