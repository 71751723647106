import React from 'react';

import Message from './Message';

import './styles/style.scss';

export const ErrorMessage = (props: Message) => (
  <Message type="error" {...props} />
);

export const HelpMessage = (props: Message) => (
  <Message type="help" {...props} />
);

export default Message;
