import React, { forwardRef, cloneElement, isValidElement } from 'react';
import { ReactNode, ReactElement } from 'react';
import cx from 'classnames';

/*
  TODO:
    - Deprecate `ReactNode` in `href`
    - Add polymorphic `as` prop for interop with routing libs
      - `<Link as={RouterLink} to="/">Index</Link>`
    - rename `type` to `variant`
*/

type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

type Link = {
  /** Destination URL 
   *
   * Passing `node` can be handy for example with `react-router` `<Link />` component. */
  href?: string | ReactNode;
  /** Active state */
  isActive?: boolean;
  /** Visual type of link */
  type?: 'grey' | 'inverted' | 'white';
};

const Link = forwardRef<
  HTMLAnchorElement,
  Merge<JSX.IntrinsicElements['a'], Link>
>(({ className, href, type, isActive, ...other }, ref) => {
  const classes = cx({
    [`link`]: true,
    [`link--${type}`]: type,
    [`is-active`]: isActive,
    [`${className}`]: className,
  });

  const props = {
    ref,
    className: classes,
    ...other,
  };

  if (isValidElement(href)) {
    return cloneElement(href as ReactElement<any>, props);
  }

  /* eslint-disable-next-line jsx-a11y/anchor-has-content */
  return <a {...props} href={href as string | undefined} />;
});

export default Link;
