import React, { isValidElement, cloneElement } from 'react';
import cx from 'classnames';

import { useStatic } from '../../utils/hooks';

import CarouselStatic from './Carousel.static';

type Carousel = {
  /** Swiper module config. See docs for more. */
  swiperOptions?: {};
} & JSX.IntrinsicElements['div'];

const Carousel = ({
  children,
  className,
  swiperOptions = {},
  ...other
}: Carousel) => {
  const [ref] = useStatic(CarouselStatic, { swiperOptions });

  const getSlides = () => {
    if (!children) {
      return null;
    }

    return React.Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, { className: 'swiper-slide' });
      }

      return null;
    });
  };

  const classes = cx('swiper-container', 'carousel', className);

  const slides = getSlides();

  return (
    <div data-carousel ref={ref} className={classes} {...other}>
      <div className="swiper-wrapper">{slides}</div>
      <div className="swiper-pagination" />
    </div>
  );
};

export default Carousel;
