import React from 'react';
import Group from './Group';

import './style.scss';

export const CheckboxGroup = (props: Omit<Group, 'role'>) => (
  <Group role="group" {...props} />
);

export const RadioGroup = (props: Omit<Group, 'role'>) => (
  <Group role="radiogroup" {...props} />
);

export default Group;
