import React from 'react';
import cx from 'classnames';

type Skeleton = {
  /** If Skeleton should have animation, default is true. */
  animated?: boolean;
  /** Height of the skeleton, e.g. "200", "250px", "30%", "10rem" */
  height?: string | number;
  /** Width of the skeleton, e.g. "200", "250px", "30%", "10rem" */
  width?: string | number;
  /** Geometric variant of the Skeleton.  */
  variant?: 'circle' | 'rectangle' | 'rounded-rectangle';
} & JSX.IntrinsicElements['span'];

const Skeleton = ({
  animated = true,
  className,
  height,
  width,
  variant = 'rounded-rectangle',
  ...other
}: Skeleton) => {
  return (
    <span
      className={cx({
        [`Skeleton`]: true,
        [`Circle`]: variant === 'circle',
        [`RoundedRectangle`]: variant === 'rounded-rectangle',
        [`animated`]: animated,
        [`${className}`]: className,
      })}
      style={{
        width,
        height,
        maxWidth: width,
      }}
      {...other}
    >
      &zwnj;
    </span>
  );
};

export default Skeleton;
