import Choices from 'choices.js';

type SelectConfig = {
  removeItemButton?: boolean;
  placeholderValue?: string;
  searchPlaceholderValue?: string;
  searchEnabled: boolean;
  searchChoices: boolean;
  shouldSort: boolean;
};

const defaultConfig = {
  searchEnabled: false,
  searchChoices: false,
  shouldSort: false,
};

export default class Select {
  element: HTMLSelectElement;
  config: SelectConfig;
  instance: Choices | null = null;
  dropdownAlign: string;

  constructor(element: HTMLSelectElement, config: Partial<SelectConfig>) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };
    this.dropdownAlign = this.element.getAttribute('data-select-align') ?? '';

    this.init();

    // @ts-ignore
    this.element.DDL_Select = this;
  }

  init() {
    const config = { ...this.config };

    if (
      (this.element.multiple && config.removeItemButton === undefined) ||
      this.element.hasAttribute('data-select-clearable')
    ) {
      config.removeItemButton = true;
    }

    if (this.element.hasAttribute('placeholder')) {
      config.placeholderValue =
        this.element.getAttribute('placeholder') ?? undefined;
    }

    if (this.element.hasAttribute('data-select-searchable')) {
      config.searchPlaceholderValue =
        this.element.getAttribute('placeholder') ?? undefined;
      config.searchEnabled = true;
      config.searchChoices = true;
      config.shouldSort = true;
    }

    this.instance = new Choices(this.element, config);

    // @ts-ignore not a public API
    this.instance.containerOuter.element.classList.add('select');
    // disable input if [disabled] or '.disabled'
    if (this.element.disabled || this.element.classList.contains('disabled')) {
      this.instance.disable();
    }

    if (config.searchEnabled) {
      // @ts-ignore not a public API
      const input = this.instance.dropdown.querySelector('input');

      if (input) {
        input.classList.add('input');
        input.classList.add('input--search');
      }
    }

    this.setClasses();

    if (this.dropdownAlign === 'right') {
      // It's necessary to attach listeners on both events. Otherwise, While dropdown fades in/out, trigger button changes its own width, but dropdown position stays shifted.

      (['showDropdown', 'hideDropdown'] as const).map((event) =>
        this.instance?.passedElement.element.addEventListener(
          event,
          () => this.setDropdownAlignment(),
          false
        )
      );
    }
  }

  static getInstance(el: HTMLElement) {
    // @ts-ignore
    return el && el.DDL_Select ? el.DDL_Select : null;
  }

  setDropdownAlignment() {
    // @ts-ignore not a public API
    const { dropdown, containerInner } = this.instance;
    const { offsetWidth: containerWidth } = containerInner.element;
    const { offsetWidth: dropdownWidth } = dropdown.element;

    dropdown.element.style.left = `-${dropdownWidth - containerWidth}px`;
  }

  setClasses = (newClasses?: string[]) => {
    const classes = newClasses || Array.from(this.element.classList);
    const unwantedClasses = ['choices__input', 'is-hidden'];
    const stateClassNames = ['is-error', 'is-warning'];

    classes.forEach((className) => {
      if (!unwantedClasses.includes(className)) {
        // @ts-ignore not a public API
        this.instance.containerOuter.element.classList.add(className);

        stateClassNames.map((item) =>
          // @ts-ignore not a public API
          this.instance.containerOuter.element.classList.replace(
            item,
            className
          )
        );
      }
    });
  };

  update() {
    this.destroy();
    this.init();
  }

  destroy() {
    this.instance!.destroy();
    this.instance = null;
  }
}
