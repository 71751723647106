import React from 'react';
import cx from 'classnames';

type Embed = {
  /** Video aspect ratio ( default 16:9 ) */
  ratio?: '1by1' | '4by3';
  /** Video source url */
  src: string;
  /** Iframe title attribute */
  title?: string;
} & JSX.IntrinsicElements['iframe'];

const Embed = ({
  children,
  className,
  style,
  src,
  title,
  ratio,
  ...other
}: Embed) => {
  const classes = cx(
    { [`embed`]: true, [`embed--ratio-${ratio}`]: ratio },
    className
  );

  return (
    <div className={classes} style={style}>
      <iframe
        src={src}
        title={title}
        frameBorder="0"
        allowFullScreen
        {...other}
      />
    </div>
  );
};

export default Embed;
