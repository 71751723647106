import React from 'react';
import { bool, func, object, oneOf, string } from 'prop-types';
import cx from 'classnames';

import Icon from '../../Icon';

import DatepickerStatic from './Datepicker.static';

import { Input } from '..';

const CLASS_ROOT = 'datepicker';

const parseDate = (date, format) => {
  if (!date) {
    return;
  }

  if (!(date instanceof Date)) {
    // eslint-disable-next-line no-console
    console.warn(
      'You have passed a date to <Datepicker /> in the wrong format. Please use native Date object - e.g. `new Date()`'
    );
    return;
  }

  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  if (format === 'native') {
    // eslint-disable-next-line consistent-return
    return `${year}-${month}-${day}`;
  }

  if (format === 'pikaday') {
    // eslint-disable-next-line consistent-return
    return `${day}.${month}.${year}`;
  }
};

export default class Datepicker extends React.Component {
  static displayName = 'Datepicker';

  static propTypes = {
    // TODO: refactor `hasDataAttr` to `noInit` for consistency
    /** disable pikaday initialization */
    hasDataAttr: bool,
    /** datepicker label is bound to id */
    id: string,
    /** Disabled state */
    isDisabled: bool,
    /** label for datepicker */
    label: string,
    /** The maximum/latest date that can be selected. Accepted format: native Date object - e.g. `new Date()` */
    maxDate: object,
    /** The minimum/earliest date that can be selected. Accepted format: native Date object - e.g. `new Date()` */
    minDate: object,
    /** callback function for when a input value is changed. */
    onChange: func,
    /** callback function for when the datepicker is hidden */
    onClose: func,
    /** callback function for when the datepicker draws a new month */
    onDraw: func,
    /** callback function for when the picker is initialized.
     * Function parameter is object with these properties:
     *
     * * `element`: DOM element (input) where the picker is attached,
     * * `config`: used configuration,
     * * `instance`: Pikaday instance,
     * */
    onInit: func,
    /** callback function for when the datepicker becomes visible */
    onOpen: func,
    /** callback function for when the datepicker selects a date */
    onSelect: func,
    /** any option for Pikaday can be set on for first render.
     * If you need to update options of initialized picker, use
     * instance from `onInit` callback. Available methods are listed
     * in [Pikaday documentation](https://github.com/dbushell/Pikaday#methods)
     * */
    pikadayOptions: object,
    /** placeholder text */
    placeholder: string,
    /** preferred position of the datepicker relative to the form field */
    position: oneOf(['top left', 'top right', 'bottom left', 'bottom right']),
    /** readonly disable writing into input field - useful for mobile resolutions to hide keyboard */
    readonly: bool,
    /** can be set to false to not reposition datepicker within the viewport */
    reposition: bool,
    /** value for datepicker, it has to be dateString. In the first render it's treated as defaultValue. Accepted format: native Date object - e.g. `new Date()` */
    value: object,
  };

  static defaultProps = {
    position: 'bottom left',
    reposition: true,
    onInit: ({ element, config, instance }) => {}, // eslint-disable-line no-unused-vars
    onChange: (value, e) => {}, // eslint-disable-line no-unused-vars
    onSelect: value => {}, // eslint-disable-line no-unused-vars
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value && !state.isDirty) {
      const format = state.isNative ? 'native' : 'pikaday';

      return {
        value: parseDate(props.value, format),
      };
    }
    return null;
  }

  state = {
    value: '',
    isNative: false,
    // `isDirty` state is used in `getDerivedStateFromProps()` but eslint rule, want it to use it on other places as well.
    // eslint-disable-next-line react/no-unused-state
    isDirty: false,
  };

  componentDidMount() {
    const datepickerConfig = {
      isStatic: false,
      field: this.datepickerRef,
      position: this.props.position,
      reposition: this.props.reposition,
      onSelect: this.handleSelect,
      onOpen: this.props.onOpen,
      onClose: this.props.onClose,
      onDraw: this.props.onDraw,
      isDisabled: this.props.isDisabled,
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
    };

    this.datepicker = new DatepickerStatic(
      this.datepickerRef.inputRef.current,
      {
        ...datepickerConfig,
        ...this.props.pikadayOptions,
      }
    );

    this.props.onInit(this.datepicker);

    if (!this.datepicker.instance) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isNative: true,
      });
    }

    if (
      this.state.isNative &&
      this.props.value &&
      this.props.value !== this.state.value &&
      this.props.value.getFullYear
    ) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        value: parseDate(this.props.value, 'native'),
      });
    }

    if (
      !this.state.isNative &&
      this.props.value &&
      this.props.value !== this.state.value &&
      this.props.value.getFullYear
    ) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        value: parseDate(this.props.value, 'pikaday'),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.datepicker.instance &&
      prevProps.value !== this.props.value &&
      this.props.value.getFullYear
    ) {
      this.datepicker.element.value = parseDate(this.props.value, 'native');
    }

    if (!this.datepicker.instance) {
      return;
    }

    if (prevProps.value !== this.props.value) {
      this.datepicker.instance.setDate(this.props.value, true);
    }

    if (prevProps.minDate !== this.props.minDate) {
      this.datepicker.instance.setMinDate(this.props.minDate);
    }

    if (prevProps.maxDate !== this.props.maxDate) {
      this.datepicker.instance.setMaxDate(this.props.maxDate);
    }
  }

  componentWillUnmount() {
    this.datepicker.destroy();
  }

  handleChange = e => {
    const { value } = e.target;

    this.setState({
      // `isDirty` state is used in `getDerivedStateFromProps()` but eslint rule, want it to use it on other places as well.
      // eslint-disable-next-line react/no-unused-state
      isDirty: true,
    });

    if (!value && this.datepicker.instance) {
      this.datepicker.instance.setDate('', true);
    }

    this.setState({ value });

    this.props.onChange(value, e);
  };

  handleSelect = value => {
    this.props.onSelect(value);
  };

  render() {
    const {
      hasDataAttr = true,
      className,
      children,
      id,
      label,
      placeholder,
      value,
      onInit,
      onChange,
      onSelect,
      onOpen,
      onClose,
      onDraw,
      pikadayOptions,
      position,
      reposition,
      readonly,
      isDisabled,
      minDate,
      maxDate,
      ...other
    } = this.props;

    const classes = cx(CLASS_ROOT, 'form-control', className);

    return (
      <Input
        id={id}
        className={classes}
        placeholder={placeholder}
        data-datepicker={hasDataAttr}
        addonsInside
        label={label}
        rightAddons={<Icon name="calendar" />}
        readOnly={readonly}
        ref={ref => {
          this.datepickerRef = ref;
        }}
        onChange={this.handleChange}
        value={this.state.value && this.state.value.toString()}
        isDisabled={isDisabled}
        min={parseDate(minDate, 'native')}
        max={parseDate(maxDate, 'native')}
        role="textbox"
        {...other}
      />
    );
  }
}
