import React from 'react';

import Bar from './Bar';
import BarItem from './BarItem';

import './styles/style.scss';

export default Bar;

export const BarVertical = (props: Omit<Bar, 'ref'>) => (
  <Bar defaultDirection="vertical" {...props} />
);

export const BarSeparator = (props: Omit<BarItem, 'ref'>) => (
  <BarItem type="separator" {...props} />
);

export { BarItem };
export { default as BarBreak } from './BarBreak';
