import React from 'react';

import Select from './Select';

import './styles/style.scss';

export default Select;

export const MultiSelect = props => <Select type="multiple" {...props} />;
export const TextSelect = props => <Select type="text" {...props} />;
