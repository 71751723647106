import React from 'react';
import cx from 'classnames';

type Label = {
  /** Id for html for attribute. */
  htmlFor?: string;
  /** Disabled state. */
  isDisabled?: boolean;
  /** Error state. */
  isError?: boolean;
  /** Readonly state */
  isReadonly?: boolean;
  /** Required state */
  isRequired?: boolean;
} & JSX.IntrinsicElements["label"];

const Label = ({
  className = '',
  children,
  htmlFor,
  isError = false,
  isDisabled = false,
  isReadonly = false,
  isRequired = false,
  ...other
}: Label) => {
  const classes = cx({
    [`form-label`]: true,
    [`is-error`]: isError,
    [`is-disabled`]: isDisabled,
    [`is-readonly`]: isReadonly,
    [`className`]: className,
  });

  return (
    <div className={classes}>
      <label htmlFor={htmlFor} {...other}>
        {children}
        {isRequired ? <span className="text-color-error">&nbsp;*</span> : null}
      </label>
    </div>
  );
};

export default Label;
