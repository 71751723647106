import React from 'react';
import cx from 'classnames';

import { Bar, BarItem, Container } from '..';

type HeaderDesktop = {
  /** Action area section. */
  actionArea: React.ReactNode;
  /** LogoArea section. */
  logoArea: React.ReactNode;
  /** Menu area section. */
  menuArea: React.ReactNode;
  /** Search area section. */
  searchArea: React.ReactNode;
  /** User area section. */
  userArea: React.ReactNode;
} & JSX.IntrinsicElements['nav'];

const HeaderDesktop = ({
  className,
  logoArea,
  menuArea,
  searchArea,
  userArea,
  actionArea,
  ...other
}: HeaderDesktop) => (
  <nav className={cx('header-desktop', className)} {...other}>
    <Container>
      <Bar className="header-desktop__topbar no-mrg-bottom align-items-middle">
        {logoArea && <BarItem>{logoArea}</BarItem>}
        <BarItem isFilling className={cx('header-desktop__menu')}>
          {menuArea}
        </BarItem>
        {searchArea && <BarItem>{searchArea}</BarItem>}
        {actionArea && <BarItem>{actionArea}</BarItem>}
        {userArea && <BarItem>{userArea}</BarItem>}
      </Bar>
    </Container>
  </nav>
);

export default HeaderDesktop;
