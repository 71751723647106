import React, { forwardRef } from 'react';
import cx from 'classnames';

import Tab from "./Tab";

/*

  TODO:
    - research if there is a reason for `renderTab` prop
      1. there is no extra information coming to `renderTab` prop
      2. possible use case is custom `Tab` markup, but
        1. `Tabs` uses `<ul>`, `Tab` generates `<li>`, so custom `Tab` has to generate `<li>`, or
        2. `<ul>` can be overriden via `renderTabList` prop on `Tabs`
        3. it seems like too much overhead for no added benefit

*/

type TabPanel = {
  /** Tabpanel id */
  id: string;
  /** Active state */
  isActive?: boolean;
  /** Disable the tabpanel and its tab */
  isDisabled?: boolean;
  /** Custom tab renderer. Passes props as function parameter */
  renderTab?: (props: Tab) => JSX.Element;
  /** Tab content */
  tab: React.ReactNode;
} & JSX.IntrinsicElements['div'];

const TabPanel = forwardRef<HTMLDivElement, TabPanel>(
  (
    { children, className, isActive, id, isDisabled, renderTab, tab, ...other },
    ref
  ) => {
    const classes = cx({
      [`tab-panels__panel`]: true,
      [`is-active`]: isActive,
      [`${className}`]: className,
    });

    return (
      <div
        ref={ref}
        id={id}
        className={classes}
        role="tabpanel"
        tabIndex={0}
        {...other}
      >
        {children}
      </div>
    );
  }
);

export default TabPanel;
