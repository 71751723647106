import React from 'react';

import { Container, Bar, BarItem, Link, Button } from '..';

/*

  TODO:
    - let VD create some cookie icon because emoji is different
      on every platform (the one on Windows is really ugly)

*/

type CookieConsent = {
  /** Content of the confirmation button. */
  buttonContent?: string;
  /** Description text for the user. */
  content?: string;
  /** Label for the link to page with cookies. */
  linkContent?: string;
  /** URL to the page with cookie information. */
  linkHref: string;
  /** Callback for the confirmation button. */
  onConfirm: () => void;
};

const CookieConsent = ({
  content = 'Táto stránka používa súbory cookies. Zatvorením tohto oznámenia alebo používaním tejto stránky vyjadrujete súhlas s používaním súborov cookies.',
  linkHref = '#',
  linkContent = 'Viac informácií',
  buttonContent = 'Súhlasím',
  onConfirm = () => {},
}) => (
  <div className="cookie-consent">
    <Container type="narrow">
      <Bar
        direction={{
          s: 'horizontal',
        }}
        space="small"
      >
        <BarItem className="cookie-consent__emoji show-s">
          <span role="img" aria-label="cookies">
            &#127850;
          </span>
        </BarItem>
        <BarItem isFilling>
          {content} <Link href={linkHref}>{linkContent}</Link>
        </BarItem>
        <BarItem>
          <Button size="s" onClick={onConfirm}>
            {buttonContent}
          </Button>
        </BarItem>
      </Bar>
    </Container>
  </div>
);

export default CookieConsent;
