import React from 'react';
import cx from 'classnames';

import { genResponsiveClasses } from '../../utils';
import { ResponsiveMap } from '../../utils';

/*

  TODO:
    - refactor `isVertical` to `variant`/`orientation` of type `ResponsiveMap<'vertical'|'horizontal'>`

*/

type Step = {
  /** Shows step number */
  stepNumber?: number;
  /** If the step is vertical */
  isVertical?: ResponsiveMap<boolean>;
  /** Displays a line pointing to the component */
  hasLineIn?: ResponsiveMap<boolean>;
  /** Displays a line pointing from the component */
  hasLineOut?: ResponsiveMap<boolean>;
} & JSX.IntrinsicElements['div'];

const Step = ({
  stepNumber,
  children,
  isVertical = false,
  hasLineIn = false,
  hasLineOut = false,
  ...other
}: Step) => {
  const classes = cx({
    [`step`]: true,
    ...genResponsiveClasses('step', hasLineIn, 'line-in'),
    ...genResponsiveClasses('step', hasLineOut, 'line-out'),
    ...genResponsiveClasses('step', isVertical, 'vertical'),
  });

  const content = typeof children === 'string' ? <p>{children}</p> : children;

  return (
    <div className={classes} {...other}>
      <div className="step__number h3">{stepNumber}</div>
      <div className="step__content">{content}</div>
    </div>
  );
};

export default Step;
