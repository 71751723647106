import React, { isValidElement, cloneElement } from 'react';
import cx from 'classnames';

type HeaderLogo = {
  /** Link href. Passing `node` can be handy for example with `react-router` `<Link />` component. */
  href?: string | JSX.Element;
  /**  Logo image source. */
  src: string;
} & Omit<JSX.IntrinsicElements['img'], 'src'>;

const HeaderLogo = ({
  className,
  src = '',
  href = 'https://www.dovera.sk',
  ...other
}: HeaderLogo) => {
  const classes = cx('header__logo', className);

  const image = (
    <img
      key="logo"
      src={src}
      alt="Dôvera zdravotná poisťovňa, a.s."
      className={classes}
      role="banner"
      {...other}
    />
  );

  const anchorProps = {
    rel: 'home',
    title: 'Dôvera zdravotná poisťovňa, a.s.',
    children: [image],
  };

  if (typeof href === 'string') {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={href} {...anchorProps} />;
  }

  if (isValidElement(href)) {
    return cloneElement(href, anchorProps);
  }

  return null;
};

export default HeaderLogo;
