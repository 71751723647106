import React from 'react';
import cx from 'classnames';

import { useUniqueId, useStatic } from '../../utils/hooks';
import ToggleStatic from '../../scripts/modules/Toggle';

import NavigationList from './NavigationList';
import { Provider } from './NavigationContext';

import { Icon, Button, Bar, BarItem, BarSeparator } from '..';
import NavigationItem from './NavigationItem';

type Navigation = {
  /** Back button props */
  backLinkProps?: Button;
  /** Title of current selected item. Should match to title of active item. */
  currentTitle?: string;
  /** Back button */
  hasBackLink?: boolean;
  /** Unique id for navigation - needs to be different for every navigation instance */
  id?: string;
  /** Opens navigation list (visible only on mobile resolution) */
  isOpen?: boolean;
  /** Navigation items */
  items?: NavigationItem[];
  /** Is triggered by clicking on any anchor link in navigation list */
  onCloseClick?: () => void;
  /** Custom title renderer. Passes props as function parameter */
  renderTitle?: (props: Navigation) => React.ReactNode;
  /** Navigation title */
  title?: string;
  /** Visual type of navigation */
  type?: 'inverted';
} & JSX.IntrinsicElements['nav'];

const DefaultTitle = ({ title, type }: Navigation) => (
  <div
    className={cx('h6', {
      'text-inverted': type === 'inverted',
    })}
  >
    {title}
  </div>
);

const Navigation = (props: Navigation) => {
  const {
    children,
    className,
    id,
    hasBackLink = true,
    backLinkProps = { children: 'Späť' },
    currentTitle,
    title,
    renderTitle,
    items,
    type,
    isOpen,
    onCloseClick,
    ...other
  } = props;

  const classes = cx('navigation', className);

  const currentId = useUniqueId(id);

  const { children: backLinkChildren, ...otherBacklinkProps } = backLinkProps;

  const Title = renderTitle ?? DefaultTitle;
  // @ts-ignore
  const titleToRender = <Title {...props} />;

  return (
    <Provider value={{ onCloseClick }}>
      <nav
        className={classes}
        {...(title && { 'aria-labelledby': `${currentId}__title` })}
        {...other}
      >
        {hasBackLink && (
          <BackButton
            className="navigation__back no-pad-left mb-small"
            {...(type === 'inverted' && { type: `link` })}
            {...backLinkProps}
          >
            Späť
          </BackButton>
        )}

        <MobileHeader
          id={currentId}
          hasBackLink={hasBackLink}
          title={currentTitle ?? title}
          backLinkProps={otherBacklinkProps}
          onCloseClick={onCloseClick}
        />

        {title && (
          <div id={`${currentId}__title`} className="navigation__title">
            {titleToRender}
          </div>
        )}

        {items && (
          <div
            id={`${currentId}-list`}
            aria-hidden={!isOpen}
            aria-expanded={isOpen}
            className={cx('navigation__list-wrapper', {
              'is-active': isOpen,
            })}
          >
            <ListMobileBar
              title={titleToRender}
              id={currentId}
              onCloseClick={onCloseClick}
            />
            <NavigationList items={items} type={type} />
          </div>
        )}
      </nav>
    </Provider>
  );
};

export default Navigation;

const BackButton = ({ children, ...other }: Button) => (
  <Button type="link-secondary" size="xs" {...other}>
    <Icon
      name="arrow-left"
      size="medium"
      className={cx({ 'icon--left': children })}
    />
    {children && '\u00A0'}
    {children}
  </Button>
);

type MobileHeader = {
  hasBackLink?: boolean;
  backLinkProps?: Button;
  title: React.ReactNode;
  id: string;
  onCloseClick?: () => void;
};

const MobileHeader = ({
  hasBackLink,
  backLinkProps,
  title,
  id,
  onCloseClick,
}: MobileHeader) => {
  const [headerButtonRef] = useStatic(ToggleStatic);

  return (
    <div className={`navigation__mobile-header`}>
      <Bar space="xxsmall" className="no-mrg-bottom">
        {hasBackLink && [
          <BarItem key="back">
            <BackButton equal {...backLinkProps} />
          </BarItem>,
          <BarSeparator key="separator" />,
        ]}
        <BarItem isFilling className="navigation__mobile-header-title">
          <span className="text-small text-color-grey">{title}</span>
        </BarItem>
        <BarItem className={`navigation__mobile-header-open`}>
          <Button
            elemRef={headerButtonRef}
            id={`${id}-menu-button`}
            type="link-secondary"
            size="xs"
            equal
            className="no-mrg-bottom"
            aria-expanded="false"
            onClick={onCloseClick}
            data-toggle={JSON.stringify([
              {
                target: `#${id}-menu-button`,
                attribute: 'aria-expanded',
              },
              { target: `#${id}-list`, attribute: 'aria-hidden' },
              {
                target: `#${id}-list`,
                attribute: 'class',
                value: 'is-active',
              },
            ])}
          >
            <Icon name="chevron-down" size="medium" />
          </Button>
        </BarItem>
      </Bar>
    </div>
  );
};

type ListMobileBar = {
  title: React.ReactNode;
  id: string;
  onCloseClick?: () => void;
};

const ListMobileBar = ({ title, id, onCloseClick }: ListMobileBar) => {
  const [headerButtonMobileRef] = useStatic(ToggleStatic);

  return (
    <div className="navigation__list-mobile-bar">
      <Bar className="mb-small">
        <BarItem isFilling className={`navigation__mobile-popup-title`}>
          {title}
        </BarItem>
        <BarItem className={`navigation__mobile-popup-close`}>
          <Button
            elemRef={headerButtonMobileRef}
            type="link-secondary"
            size="xs"
            equal
            className="no-mrg-bottom"
            onClick={onCloseClick}
            data-toggle={JSON.stringify([
              {
                target: `#${id}-menu-button`,
                attribute: 'aria-expanded',
              },
              { target: `#${id}-list`, attribute: 'aria-hidden' },
              {
                target: `#${id}-list`,
                attribute: 'class',
                value: 'is-active',
              },
            ])}
          >
            <Icon name="close" size="medium" />
          </Button>
        </BarItem>
      </Bar>
    </div>
  );
};
