import React from 'react';
import cx from 'classnames';

import { Plate, Disc } from '..';

type HeaderUser = {
  /** Avatar source link. */
  avatarSrc?: string;
  /** Indicate notification */
  hasNotification?: boolean;
  /** User name initials. */
  initials?: string;
  /** User name. */
  name?: string;
};

const HeaderUser = ({
  avatarSrc,
  initials,
  name,
  hasNotification,
  ...other
}: HeaderUser) => (
  <Plate
    className={cx({
      [`header-user`]: true,
      [`header-user--notification`]: hasNotification,
    })}
    isCondensed

    {...(initials &&
      !avatarSrc && {
        renderAvatar: ({ avatarSize }) => (
          <Disc {...(avatarSize && { size: avatarSize })}>{initials}</Disc>
        ),
      })}
    avatarSrc={avatarSrc}
    title={name}
    renderTitle={({ title }) =>
      name && <span className="text-semibold">{title}</span>
    }
    {...other}
  />
);

export default HeaderUser;
