import React from 'react';
import cx from 'classnames';

type Tab = {
  /** Id of the tabpanel this tab controls. */
  controls: string;
  /** Active state. */
  isActive?: boolean;
  /** Disabled state. */
  isDisabled?: boolean;
  /** Visual type */
  type?: 'link' | 'benefits';
} & Pick<JSX.IntrinsicElements['button'], 'children' | 'className'>;

const Tab = ({
  children,
  className,
  controls,
  isDisabled,
  isActive,
  type = 'link',
}: Tab) => {
  const tabClasses = cx({
    [`tab`]: true,
    [`tab-list__tab`]: true,
    [`tab--${type}`]: type,
    [`is-disabled`]: isDisabled,
    [`is-active`]: isActive,
    [`${className}`]: className,
  });

  const tabindex = isActive ? 0 : -1;

  return (
    <li className="tab-list__item" role="presentation">
      <button
        role="tab"
        className={tabClasses}
        aria-selected={isActive}
        aria-controls={controls}
        tabIndex={tabindex}
        aria-disabled={isDisabled}
        disabled={isDisabled}
      >
        {children}
      </button>
    </li>
  );
};

export default Tab;
