import React from 'react';
import cx from 'classnames';

import ArticleContext from './ArticleContext';

import { PageSection, Grid, GridCol, Container } from '..';

type Article = {
  /** Aside container for displaying additional content to article (e.g. navigation) */
  aside?: React.ReactNode;
} & PageSection;

const Article = ({ children, className = '', aside, ...other }: Article) => {
  const classes = cx('article', className);

  return (
    <ArticleContext.Provider value={{ aside }}>
      <PageSection
        tag="div"
        bg="white"
        space="none"
        className={classes}
        {...other}
      >
        <Container type={!aside ? 'narrow' : undefined}>
          <Grid>
            {aside && (
              <GridCol size={{ l: 3 }} tag="aside" className="article__aside">
                {aside}
              </GridCol>
            )}
            <GridCol
              size={{ l: aside ? 9 : 12 }}
              tag="main"
              className="article__content"
            >
              {children}
            </GridCol>
          </Grid>
        </Container>
      </PageSection>
    </ArticleContext.Provider>
  );
};

export default Article;
