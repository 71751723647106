import React, { forwardRef } from 'react';
import cx from 'classnames';

import { genResponsiveClasses } from '../../utils';
import { ResponsiveMap } from '../../utils';

/*

  TODO:
    - refactor `BarDirection`, `BarDirectionAndSpace` logic
    - remove `defaultDirection` prop
    - make `Bar` polymorphic for `tag` or `as` prop
*/

type Direction = 'horizontal' | 'vertical';
type Space = 'small' | 'xxsmall';
type Alignment = 'top' | 'middle' | 'bottom';

type Bar = {
  /** Flex vertical alignment */
  align?: Alignment;
  /** Default direction of bar */
  defaultDirection?: Direction;
  /** Bar layout direction. Direction can take enum value or media object. */
  direction?: ResponsiveMap<Direction>;
  /** Space between items (vertical and horizontal - depends on direction). Space can take enum value or media object. */
  space?: ResponsiveMap<Space>;
  /** HTML tag */
  tag?: string;
} & JSX.IntrinsicElements['div'];

const Bar = forwardRef<HTMLDivElement, Bar>(
  (
    {
      className,
      align,
      defaultDirection = 'horizontal',
      direction,
      space,
      tag = 'div',
      ...other
    },
    ref
  ) => {
    // assign default direction if needed
    let BarDirection = direction || defaultDirection;

    // cast BarDirection to object with default(xs) property
    // so it can be merged afterwards with space object
    if (typeof BarDirection === 'string') {
      BarDirection = { xs: BarDirection };
    }

    // merge defaultDirection with passed direction
    BarDirection = { ...{ xs: defaultDirection }, ...BarDirection };

    // cast space to object if needed
    const BarSpace =
      typeof space === 'string'
        ? Object.keys(BarDirection).reduce((acc, breakpoint) => {
            // @ts-ignore
            acc[breakpoint] = space;
            return acc;
          }, {})
        : space || {};

    // merge space with direction or
    // defaultDirection if direction is not specified for that space
    const BarSpaceWithDirection = Object.keys(BarSpace).reduce(
      (acc, breakpoint) => {
        const breakpointDirection =
          // @ts-ignore
          BarDirection[breakpoint] || defaultDirection;

        // @ts-ignore
        acc[breakpoint] = `${breakpointDirection}-${BarSpace[breakpoint]}`;
        return acc;
      },
      {}
    );

    // finally merge direction and space togather
    const BarDirectionAndSpace = { ...BarDirection, ...BarSpaceWithDirection };

    const classes = cx({
      [`bar`]: true,
      ...genResponsiveClasses('bar', BarDirectionAndSpace),
      [`align-items-${align}`]: align,
      [`${className}`]: className,
    });

    const Tag = tag;

    // @ts-ignore
    return <Tag ref={ref} className={classes} {...other} />;
  }
);

export default Bar;
