import Pikaday from 'pikaday';

import { breakpoints } from '../../../utils/tokens';

const defaultConfig = {
  setDefaultDate: true,
  isStatic: true,
  format: 'DD.MM.YYYY',
  firstDay: 1,
  showDaysInNextAndPreviousMonths: true,
  i18n: {
    previousMonth: '',
    nextMonth: '',
    months: [
      'Január',
      'Február',
      'Marec',
      'Apríl',
      'Máj',
      'Jún',
      'Júl',
      'August',
      'September',
      'Október',
      'November',
      'December',
    ],
    weekdays: [
      'Nedeľa',
      'Pondelok',
      'Utorok',
      'Streda',
      'Štvrtok',
      'Piatok',
      'Sobota',
    ],
    weekdaysShort: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
  },
};

export default class Datepicker {
  constructor(element, config) {
    this.element = element;

    this.config = { ...defaultConfig, ...config };

    this.setCorrectDatepicker = this.setCorrectDatepicker.bind(this);

    this.init();

    this.element.DDL_Datepicker = this;

    return this;
  }

  init() {
    window.addEventListener('resize', this.setCorrectDatepicker);

    this.setCorrectDatepicker();
  }

  destroy() {
    if (this.instance) {
      this.instance.destroy();
      this.instance = null;
    }
  }

  update() {
    this.destroy();
    this.init();
  }

  static getInstance(el) {
    return el && el.DDL_Datepicker ? el.DDL_Datepicker : null;
  }

  setCorrectDatepicker() {
    const isBiggerThanMediumBreakpoint = window.innerWidth > breakpoints.m;

    // Native datepicker
    if (!isBiggerThanMediumBreakpoint) {
      if (this.instance) {
        this.destroy();
      }

      this.parseDate('native');
      this.element.type = 'date';
    }

    // Pikaday datepicker
    if (isBiggerThanMediumBreakpoint) {
      this.element.type = 'text';
      this.parseDate('pikaday');

      if (this.instance) {
        return;
      }

      this.config.field = this.element;
      this.config.trigger = this.config.isDisabled
        ? null
        : this.element.closest('.input-addons');

      this.instance = new Pikaday(this.config);
    }
  }

  parseDate(dateFormat) {
    if (!this.element.value) {
      return;
    }

    const dateToParseContainsDot = this.element.value.includes('.');
    const dateToParseContainsDash = this.element.value.includes('-');

    if (dateFormat === 'pikaday' && dateToParseContainsDash) {
      const [year, month, day] = String(this.element.value).split('-');

      this.element.value = `${day}.${month}.${year}`;
    }

    if (dateFormat === 'native' && dateToParseContainsDot) {
      const [day, month, year] = String(this.element.value).split('.');

      this.element.value = `${year}-${month}-${day}`;
    }
  }
}
