import React from 'react';
import cx from 'classnames';

import { ButtonSecondary, Icon, Tooltip } from '..';

type ModalHeader = {
  children: React.ReactNode;
  id: string;
};

const ModalHeader = ({ children, id }: ModalHeader) => (
  <div className="modal__header">
    <h2 id={id} tabIndex={-1} className={cx('h3', 'modal__title')}>
      {children}
    </h2>
    <Tooltip dialog="Zatvoriť" isBlockElement>
      <ButtonSecondary
        data-a11y-dialog-hide
        equal
        size="xs"
        shape="round"
        tabIndex={-1}
        className="modal__close-button"
      >
        <Icon name="close" size="small" />
      </ButtonSecondary>
    </Tooltip>
  </div>
);

export default ModalHeader;
